export default {
  linkedActivitiesByContractItemId(state) {
    return state.linkedActivitiesByContractItemId;
  },
  isEditingContractItems(state) {
    return state.isEditingContractItems;
  },
  currentContract(state) {
    return state.currentContract
  }
};
