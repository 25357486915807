export default {
  activeTab: 'file',
  dashboardWeightId: undefined,
  hasReachedCategoryLimit: false,
  configuredErp: undefined,
  budgetForm: {
    name: undefined,
    code: undefined,
    start: undefined,
    description: undefined,
    materialCost: undefined,
    totalCost: undefined,
    laborCost: undefined,
    excelKey: undefined,
  },
  tmpFile: undefined,
  siengeForm: {
    name: undefined,
    siengeProject: undefined,
    constructionUnit: undefined,
  },
  megaForm: {
    name: undefined,
    externalBudgetId: undefined,
    externalProjectId: undefined,
  },
  totvsForm: {
    name: undefined,
    externalCompanyId: undefined,
    externalProjectId: undefined,
  },
  importErrors: undefined,
  rowsErrors: [],
  budgetReport: {
    id: undefined,
    name: undefined,
    isUpdated: false,
  }
};
