import { apolloClient } from '@/vue-apollo';
import Vue from 'vue';
import ProjectCriticalPathQuery from '@/graphql/ProjectCriticalPath.gql';

export default {
  index: (projectId, companyId) => Vue.axios.get('projects', { params: { companyId: companyId } }),
  create: project => Vue.axios.post('projects', project),
  update: project => Vue.axios.put(`projects/${project.id}`, project),
  destroy: project => Vue.axios.delete(`projects/${project.id}`),
  archive: project => Vue.axios.put(`projects/${project.id}/archive`),
  unarchive: project => Vue.axios.put(`projects/${project.id}/unarchive`),
  criticalPath: async (projectId, activitiesIdsToIgnore) => {
    return await apolloClient
      .query({
        query: ProjectCriticalPathQuery,
        variables: { projectId, activitiesIdsToIgnore },
        fetchPolicy: 'no-cache',
      })
      .then(res => res.data.me.project.criticalPath);
  },
};
