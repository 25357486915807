export default {
  setProjectId(state, projectId) {
    state.projectId = projectId
  },
  clearProjectRelatedFields(state) {
    state.floorIds = []
    state.workPackageIds = []
  },
  applyForm(state, form) {
    state.startAt = form.datesRange.startAt;
    state.endAt = form.datesRange.endAt;
    state.floorIds = form.floorIds;
    state.workPackageIds = form.workPackageIds;
  },
  setPrintMode(state) {
    state.printMode = !state.printMode;
  },
};
