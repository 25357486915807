import state from './state';
import mutations from './mutations';

export { state };
export { mutations };

const namespaced = true;

export default {
  namespaced,
  state,
  mutations,
};
