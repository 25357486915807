export default {
  setProjectIds(state, projectIds) {
    state.projectIds = projectIds;
  },
  clearProjectRelatedFields(state) {
    state.userIds = [];
    state.labelIds = [];
  },
  applyForm(state, form) {
    state.startAt = form.datesRange.startAt;
    state.endAt = form.datesRange.endAt;
    state.userIds = form.userIds;
    state.labelIds = form.labelIds;
  },
  setPrintMode(state) {
    state.printMode = !state.printMode;
  },
};
