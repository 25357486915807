export default {
  clearState(state) {
    state.deadlineMeasurementTaskId = undefined;
    state.searchBy = undefined;
    state.groupBy = 'service';
  },
  setDeadlineMeasurementTaskId(state, deadlineMeasurementTaskId) {
    state.deadlineMeasurementTaskId = deadlineMeasurementTaskId;
  },
  setMeasuredIn(state, measuredIn) {
    state.measuredIn = measuredIn;
  },
  setSearchBy(state, searchBy) {
    state.searchBy = searchBy;
  },
  setGroupBy(state, groupBy) {
    state.groupBy = groupBy;
  },
  setPhysicalFinancialOrPhysical(state, physicalFinancialOrPhysical) {
    state.physicalFinancialOrPhysical = physicalFinancialOrPhysical;
  }
};
