import * as msal from '@azure/msal-browser';

export default class AzureAuthService {
  constructor(tenant = 'organizations') {
    const msalConfig = {
      auth: {
        clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID,
        redirectUri: import.meta.env.VITE_AZURE_AD_REDIRECT_URI,
        authority: `https://login.microsoftonline.com/${tenant}`,
      },
      cache: {
        cacheLocation: msal.BrowserCacheLocation.LocalStorage,
      },
      system: {
        allowRedirectInIframe: true,
      },
    };

    this.client = new msal.PublicClientApplication(msalConfig);
    this.requestParams = { scopes: ['User.Read', 'offline_access'] };
  }

  async initializeClient() {
    await this.client.initialize();
  }

  async login({ callback, email } = {}) {
    if (email) this.requestParams = { ...this.requestParams, loginHint: email };
    if (callback) this.callback = callback;

    const activeAccounts = this.client.getAllAccounts();

    if (activeAccounts) {
      this.accountSelectionFlow();
    }
  }

  accountSelectionFlow() {
    this.loginPopup().then(r => console.info('Login redirect successful', r));
  }

  async loginPopup(callback) {
    if (callback) this.callback = callback;
    try {
      await this.client
        .loginPopup({ ...this.requestParams, prompt: 'select_account' })
        .then(response => {
          this.client.setActiveAccount(response.account);
          this.callback(response);
        });
    } catch (err) {
      console.error('Login Popup Flow was cancelled or returned an error', err);
      throw err;
    }
  }

  async silentFlow(msalUsername) {
    const currentAccount = this.client.getAccountByUsername(msalUsername);
    const silentRequest = {
      scopes: ['User.Read', 'offline_access'],
      account: currentAccount,
      forceRefresh: false,
    };

    return await this.client.acquireTokenSilent(silentRequest).catch(async error => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return await this.client.acquireTokenPopup(this.requestParams).catch(error => {
          console.error('Login Redirect Flow was cancelled or returned an error', error);
        });
      }
    });
  }

  logout() {
    this.client
      .logoutRedirect({
        onRedirectNavigate: url => {
          return false;
        },
      })
      .then(r => console.info('Logout successful'));
  }
}
