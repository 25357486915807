import dateformat from 'dateformat';
import { clone } from 'lodash';

import i18n from '@/i18n.js';

Date.reloadI18n = function() {
  let dateFormatLabels = {
    dayNames: [
      i18n.t('dates.days.sun'),
      i18n.t('dates.days.mon'),
      i18n.t('dates.days.tue'),
      i18n.t('dates.days.wed'),
      i18n.t('dates.days.thur'),
      i18n.t('dates.days.fri'),
      i18n.t('dates.days.sat'),
      i18n.t('dates.days.sunday'),
      i18n.t('dates.days.monday'),
      i18n.t('dates.days.tuesday'),
      i18n.t('dates.days.wednesday'),
      i18n.t('dates.days.thursday'),
      i18n.t('dates.days.friday'),
      i18n.t('dates.days.saturday'),
    ],
    monthNames: [
      i18n.t('dates.months.jan'),
      i18n.t('dates.months.feb'),
      i18n.t('dates.months.mar'),
      i18n.t('dates.months.apr'),
      i18n.t('dates.months.mayMMM'),
      i18n.t('dates.months.juneMMM'),
      i18n.t('dates.months.julyMMM'),
      i18n.t('dates.months.aug'),
      i18n.t('dates.months.sept'),
      i18n.t('dates.months.oct'),
      i18n.t('dates.months.nov'),
      i18n.t('dates.months.dec'),
      i18n.t('dates.months.january'),
      i18n.t('dates.months.february'),
      i18n.t('dates.months.march'),
      i18n.t('dates.months.april'),
      i18n.t('dates.months.may'),
      i18n.t('dates.months.june'),
      i18n.t('dates.months.july'),
      i18n.t('dates.months.august'),
      i18n.t('dates.months.september'),
      i18n.t('dates.months.october'),
      i18n.t('dates.months.november'),
      i18n.t('dates.months.december'),
    ],
    timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
  };
  dateformat.i18n = dateFormatLabels;
}

const MS_PER_DAY = 24 * 60 * 60 * 1000;

Date.withoutTime = value => {
  let date = value instanceof Date ? clone(value) : new Date();
  if (typeof value == 'string') {
    date = new Date(Date.UTC(...splitDateString(value)));
  } else if (typeof value == 'number') {
    date = new Date(value);
  }
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

Date.localDateFromString = value => {
  return new Date(...splitDateString(value));
};

Date.toBrazilDateFormat = date => {
  let dateStringParts = date.split('/');

  let dateObject = new Date(+dateStringParts[2], dateStringParts[1] - 1, +dateStringParts[0]);
  return new Date(dateObject.toString());
};

Date.getUTCMonths = function(isLong = true) {
  if (isLong) return dateformat.i18n.monthNames.slice(12, 24);
  return dateformat.i18n.monthNames.slice(0, 12);
};

Date.getMonths = function(isLong = true) {
  if (isLong) return dateformat.i18n.monthNames.slice(12, 24);
  return dateformat.i18n.monthNames.slice(0, 12);
};

Date.getWeekDays = function(isLong = true) {
  if (isLong) return dateformat.i18n.dayNames.slice(7, 14);
  return dateformat.i18n.dayNames.slice(0, 7);
};

Date.getWeekDays = function(isLong = true) {
  if (isLong) return dateformat.i18n.dayNames.slice(7, 14);
  return dateformat.i18n.dayNames.slice(0, 7);
};

Date.getUTCDatesOfMonth = function(year, month) {
  let date = new Date(year, month, 1);
  let days = [];
  while (date.getUTCMonth() === month) {
    days.push(new Date(date));
    date = date.addDays(1);
  }
  return days;
};

Date.monthAndYearFormat = (date, isLong = true) => {
  const dateFormatted = new Date(date);
  const month = Date.getMonths(isLong)[dateFormatted.getMonth()];
  const year = dateFormatted.getFullYear();

  return `${month}/${year}`;
};

Date.prototype.current = function() {
  return new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate());
};

Date.prototype.startOfWeek = function() {
  let diff = this.getUTCDay() - 1;

  if (diff < 0) {
    diff += 7;
  }

  return new Date(this.addDays(diff * -1));
};

Date.prototype.addDays = function(days) {
  return new Date(this.valueOf() + 86400000 * days);
};

Date.prototype.subDays = function(days) {
  return this.addDays(-1 * days);
};

Date.prototype.addWeeks = function(weeks) {
  if (!weeks) return new Date(this.valueOf());
  return this.addDays(parseInt(7 * weeks));
};

Date.prototype.subWeeks = function(weeks) {
  return this.addWeeks(parseInt(-1 * weeks));
};

Date.prototype.addMonths = function(months) {
  let date = new Date(this.valueOf());
  date.setMonth(this.getUTCMonth() + months);
  return date;
};

Date.prototype.subMonths = function(months) {
  return this.addMonths(-1 * months);
};

Date.prototype.dayDiff = function(date) {
  const currentDate = Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate());
  const otherDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return Math.floor((currentDate - otherDate) / MS_PER_DAY);
};

Date.prototype.weekDiff = function(date) {
  return (new Date(this.valueOf()) - date) / (7 * MS_PER_DAY);
};

Date.prototype.monthDiff = function(date) {
  return Math.abs(Math.round((date.getTime() - this.getTime()) / (MS_PER_DAY * 7 * 4)));
};

Date.prototype.getMonday = function() {
  let date = new Date(this.valueOf());
  let day = date.getUTCDay(),
    diff = date.getUTCDate() - day + (day == 0 ? -6 : 1);
  return new Date(date.setUTCDate(diff));
};

Date.prototype.getSunday = function() {
  let date = new Date(this.valueOf());
  let day = date.getUTCDay(),
    diff = date.getUTCDate() - day + (day == 0 ? 0 : 7);
  return new Date(date.setUTCDate(diff));
};

Date.prototype.startOfNextMonth = function() {
  return new Date(Date.UTC(this.getUTCFullYear(), this.getUTCMonth() + 1, 1));
};

Date.prototype.startOfMonth = function() {
  return Date.withoutTime(Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), 1));
}

Date.prototype.endOfMonth = function() {
  return Date.withoutTime(Date.UTC(this.getUTCFullYear(), this.getUTCMonth() + 1, 0));
};

Date.prototype.format = function(format, utc = true) {
  return dateformat(new Date(this.valueOf()), format, utc);
};

Date.prototype.shortFormat = function() {
  let day = appendRightZero(this.getUTCDate());
  let month = appendRightZero(this.getUTCMonth() + 1);
  return i18n.locale == 'pt-BR' ? `${day}/${month}` : `${month}/${day}`;
};

Date.prototype.weekDayFormat = function() {
  const weekDay = Date.getWeekDays()[this.getDay()];
  const monthDay = appendRightZero(this.getDate());
  const month = Date.getMonths()[this.getMonth()];

  return i18n.t('dates.weekDayFormat', { weekDay, monthDay, month });
}

Date.prototype.getWeekNumber = function() {
  var d = new Date(Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

Date.prototype.withoutTime = function() {
  return this.beginningOfDay();
};

Date.prototype.isBetween = function(dateOne, dateTwo) {
  return this.valueOf() >= dateOne.valueOf() && this.valueOf() <= dateTwo.valueOf();
};

Date.prototype.beginningOfDay = function() {
  return new Date(
    Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate(), 0, 0, 0, 0),
  );
};

Date.prototype.endOfDay = function() {
  return new Date(
    Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate(), 23, 59, 59, 999),
  );
};

Date.prototype.round = function() {
  let date = new Date(this.valueOf());
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

Date.prototype.fixDateInputLocale = function() {
  return new Date(
    Date.UTC(
      this.getFullYear(),
      this.getMonth(),
      this.getDate(),
      this.getHours(),
      this.getMinutes(),
      this.getSeconds(),
    ),
  );
};

Date.prototype.currentDateTime = function() {
  return new Date().toLocaleString('pt-BR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})
}

function appendRightZero(value) {
  return value < 10 ? `0${value}` : `${value}`;
}
function splitDateString(date) {
  let [year, month, day] = date.split('-').map(value => parseInt(value));
  return [year, month - 1 || 0, day || 1];
}
