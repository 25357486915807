<template lang="pug">
  div.fixed-container(:class="{ open: isOpen }" v-click-outside="handleOutsideClick")
    v-card.rounded(width="250px")
      v-card-text.pa-2.d-flex.flex-column.align-start.justify-center
        v-list-item.w-100(@click="openIntercom" dense)
          v-icon.mr-2 mdi-file-document-outline
          span {{ i18n.t("support.firstItem") }}
        v-list-item.w-100(@click="openFreshdesk" dense)
          v-icon.mr-2 mdi-face-agent
          span {{ i18n.t("support.secondItem") }}
    button.vertical-text(@click="toggle")
      v-icon(color="white").mb-1 {{ isOpen ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
      span {{ i18n.t("support.title") }}
</template>

<script setup>
import { ref } from 'vue';
import i18n from '@/i18n.js';

const isOpen = ref(false)

function openIntercom() {
  window.fcWidget.close();
  window.Intercom('show')
}

function openFreshdesk() {
  window.fcWidget.open()
  window.Intercom('hide')
}

function handleOutsideClick() {
  isOpen.value = false
  window.fcWidget.close()
  window.Intercom('hide')
}

function toggle() {
  isOpen.value = !isOpen.value
}
</script>

<style scoped>
.fixed-container {
  position: fixed;
  right: -250px;
  top: 75%;
  transform: translateY(-50%);
  transition: right 0.3s ease;
  z-index: 1000;
}

.vertical-text {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%) rotate(180deg);
  background-color: #4b38e6;
  color: white;
  border: none;
  padding: 10px 2.5px;
  cursor: pointer;
  writing-mode: vertical-rl;
  border-radius: 0 6px 6px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: inherit;
}
.w-100 {
  width: 100%;
}

.fixed-container.open {
  right: 0;
}

.fixed-container.open .vertical-text {
  left: -30px;
}
</style>
