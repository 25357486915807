import store from '@/store';
import {isEmpty} from 'lodash';
import {FeatureError} from "@/middlewares/errors/featureError";

export default async function pricing({from, to, next}) {
  if (!to.meta.featureKey) return next();

  const featureKey = to.meta.featureKey;

  const isFeatureAuthorized = await featureAuthorization(featureKey);

  if (!isFeatureAuthorized) return next(new FeatureError);

  return next();
}

async function featureAuthorization(featureKey) {
  let features = store.getters['pricing/allFeaturesKeys'];
  features = isEmpty(features) ? await store.dispatch('pricing/loadFeatures') : features;

  if (!Array.isArray(featureKey)) featureKey = Array.of(featureKey)
  return featureKey.some(key => features.includes(key))
}
