import Vue from 'vue';
import keyForItem from './keyForItem';

const setItem = (state, item) => {
  Vue.set(
    state.items,
    keyForItem(item.id),
    state.itemParseFunction ? state.itemParseFunction(item) : item,
  );
  if (!state.ids.includes(item.id)) state.ids.push(item.id);
};

const removeItem = (state, item) => {
  Vue.delete(state.ids, state.ids.indexOf(item.id));
  Vue.delete(state.items, keyForItem(item.id));
};

export default {
  clearItems: state => {
    state.items = {};
    state.ids = [];
  },
  setItemParseFunction: (state, itemParseFunction) => {
    state.itemParseFunction = itemParseFunction;
  },
  setItem: setItem,
  removeItem: removeItem,
  setItems: (state, items) => {
    items.forEach(item => setItem(state, item));
  },
  removeItems: (state, items) => {
    items.forEach(item => removeItem(state, item));
  },
  setLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setLoaded: (state, isLoaded) => (state.isLoaded = isLoaded),
};
