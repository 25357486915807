import Vue from 'vue';

export default {
  index: (projectId, companyId) => Vue.axios.get('users', { params: { companyId: companyId } }),
  show: id => Vue.axios.get(`users/${id}`),
  validateSession: id => Vue.axios.get(`users/validate`),
  getSsoProvider: (email) => Vue.axios.get(`login/login_provider`, { params: { email: email } }),
  create: (token, name, job, department, phone, email, password) =>
    Vue.axios.post('users', { token, name, job, department, phone, email, password }),
  update: (userId, form) => Vue.axios.put(`users/${userId}`, form),
};
