export default {
  setLinkedActivitiesByContractItemId(state, { id, activities }) {
    state.linkedActivitiesByContractItemId[id] = activities;
  },
  clearLinkedActivitiesByContractItemId(state) {
    state.linkedActivitiesByContractItemId = {};
  },
  setIsEditingContractItems(state, value) {
    state.isEditingContractItems = value;
  },
  setCurrentContract(state, value) {
    state.currentContract = value;
  },
  clearCurrentContract(state) {
    state.currentContract = undefined;
  },
};
