export default {
  setElementsTab(state, tab) {
    state.elementsTab = tab;
  },
  setBasedReport(state, report) {
    state.basedReport = report;
  },
  setLoadingBasedReport(state, isLoading) {
    state.loadingBasedReport = isLoading;
  },
  setReport(state, report) {
    state.report = report;
  },
  setFocusedElement(state, element) {
    state.focusedElement = element;
  },
  addPendingPageNumber(state, number) {
    state.pendingPageNumbers[number] = true;
  },
  removePendingPageNumber(state, number) {
    delete state.pendingPageNumbers[number];
  },
  clearPendingPageNumber(state) {
    state.pendingPageNumbers = {};
  },
};
