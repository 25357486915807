import i18n from '@/i18n.js';

const formatPercentage = (value, withSign = false) => {
  let twoDecimalFormatted = value ? value.toFixed(2) : 0;
  return withSign ? `${twoDecimalFormatted}%` : twoDecimalFormatted;
};

const moneyToCurrencyLocaleType = (value, withSign = true) => {
  if (value === undefined) return '';

  return withSign
    ? value.toLocaleString((i18n.t('labels.currencyLocale')), {
        style: 'currency',
        currency: (i18n.t('labels.currencyType')),
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : value.toLocaleString((i18n.t('labels.currencyLocale')), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export { moneyToCurrencyLocaleType, formatPercentage };

export default {
  moneyToCurrencyLocaleType,
  formatPercentage,
};
