'use strict';
import { cloneDeep } from 'lodash';

class Graph {
  constructor(adjacency_list = {}) {
    this.edges = cloneDeep(adjacency_list);
    this.vertices = Object.keys(cloneDeep(adjacency_list)).reduce((obj, vertex) => {
      obj[vertex] = true;
      return obj;
    }, {});
  }

  addVertex(vertex) {
    if (!this.hasVertex(vertex)) {
      this.vertices[vertex] = true;
      this.edges[vertex] = [];
    }
  }

  removeVertex(vertex) {
    if (this.hasVertex(vertex)) {
      delete this.vertices[vertex];
    }
    while (this.edges[vertex] && this.edges[vertex].length) {
      let adjacentVertex = this.edges[vertex].pop();
      this.removeEdge(adjacentVertex, vertex);
    }
  }

  addEdge(vertex1, vertex2) {
    let vertexEdge = this.edges[vertex1];
    if (vertexEdge) {
      vertexEdge.push(vertex2);
    }
  }

  removeEdge(vertex1, vertex2) {
    let index = this.edges[vertex1] ? this.edges[vertex1].indexOf(vertex2) : -1;
    if (~index) {
      this.edges[vertex1].splice(index, 1);
    }
  }

  removeEdgesTo(vertex) {
    Object.keys(this.edges).forEach(vertex1 => this.removeEdge(vertex1, vertex));
  }

  hasVertex(vertex) {
    return this.vertices.hasOwnProperty(vertex);
  }

  hasCycle() {
    return !!this.getCycle();
  }

  getCycle() {
    let visited = Object.keys(this.vertices).reduce((obj, vertex) => {
      obj[vertex] = false;
      return obj;
    }, {});
    let recursionStack = { ...visited };
    let cyclicalEdge = undefined;
    for (let vertex in this.vertices) {
      cyclicalEdge = this.getCyclicalEdge(vertex, visited, recursionStack);
      if (cyclicalEdge) {
        return cyclicalEdge;
      }
    }

    return undefined;
  }

  getCyclicalEdge(vertex, visited = {}, recursionStack = {}) {
    if (!visited[vertex]) {
      visited[vertex] = true;
      recursionStack[vertex] = true;
      let cyclicalEdge = undefined;
      for (let edge of this.edges[vertex]) {
        if (visited[edge] && recursionStack[edge]) {
          return { vertex: vertex, edge: edge };
        } else {
          cyclicalEdge = this.getCyclicalEdge(edge, visited, recursionStack);
          if (cyclicalEdge) {
            return cyclicalEdge.edge ? cyclicalEdge : { vertex: edge, edge: cyclicalEdge };
          }
        }
      }
    }

    recursionStack[vertex] = false;
    return undefined;
  }

  hasCycleOnVertex(vertex) {
    return !!this.getCyclicalEdge(vertex);
  }

  size() {
    return Object.keys(this.vertices).length;
  }

  clone() {
    let clone = new Graph();
    clone.vertices = cloneDeep(this.vertices);
    clone.edges = cloneDeep(this.edges);
    return clone;
  }

  clear() {
    this.vertices = {};
    this.edges = {};
  }
}

export default Graph;
