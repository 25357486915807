const mapInspectionToVerificationForms = ({
  itemId,
  eapType,
  checklistId,
  partnerModelId,
  partnerLocalId,
  qualityVerificationForms,
}) =>
  qualityVerificationForms.map(verificationForm => ({
    itemId,
    eapType,
    checklistId,
    partnerModelId,
    partnerLocalId,
    ...verificationForm,
  }));

const isAnyQualityLinkingStepSelectorChecked = (state) => {
  return state.qualityLinkingSteps.some(({ isAnySelectorChecked }) => isAnySelectorChecked);
}

export default {
  verificationForms: state => state?.inspections?.flatMap(mapInspectionToVerificationForms) || [],
  projects: state => state?.qualityConfig?.projects?.map(({ id }) => id),
  partner: state => state?.qualityConfig?.partner,
  hasQualityConfig: state => state.hasQualityConfig,
  isAnyQualityLinkingStepSelectorChecked: state => isAnyQualityLinkingStepSelectorChecked(state)
};
