export default class Dependency {
  constructor({ referenceActivityId, floatingActivityId, precedenceAttribute, baseAttribute, timeOperation, delay }) {
    this.referenceActivityId = referenceActivityId;
    this.floatingActivityId = floatingActivityId;
    this.precedenceAttribute = precedenceAttribute;
    this.baseAttribute = baseAttribute;
    this.timeOperation = timeOperation;
    this.delay = delay;
  }

  isPrecedenceAttributeStart() {
    return this.precedenceAttribute === 'start';
  }

  isPrecedenceAttributeEnd() {
    return this.precedenceAttribute === 'end';
  }

  isBaseAttributeStart() {
    return this.baseAttribute === "start_at";
  }

  isBaseAttributeEnd() {
    return this.baseAttribute === "end_at";
  }
}
