import { uniq } from 'lodash';

class WebsocketOrchestrator {
  constructor() {
    this.scopes = {};
  }

  setConsumer(consumer) {
    this.consumer = consumer;
  }

  subscribe(scopeName, resources, onReceive) {
    if (!(this.consumer && scopeName && resources && onReceive)) return false;

    let scope = this.scopes[scopeName] || this.addScope(scopeName);
    this.toArray(resources).forEach(resource => {
      scope.resources[resource] = uniq([...(scope.resources[resource] || []), onReceive]);
    });
  }

  unsubscribe(scopeName, resources, onReceive) {
    if (!(scopeName && resources && onReceive)) return false;

    let scope = this.scopes[scopeName];
    this.toArray(resources).forEach(resource => {
      scope.resources[resource] = (scope.resources[resource] || []).filter(fn => fn != onReceive);
      if (scope.resources[resource].length == 0) delete scope.resources[resource];
    });
  }

  addScope(scopeName) {
    let scope = { name: scopeName, resources: {} };
    scope.cable = this.consumer.subscriptions.create(
      { channel: 'GenericChannel', scope: scopeName },
      {
        received: ({ resource, data }) => {
          return (scope.resources[resource] || []).map(onReceive =>
            onReceive.length == 1 ? onReceive(data) : onReceive(),
          );
        },
      },
    );
    this.scopes[scopeName] = scope;
    return scope;
  }

  toArray(value) {
    return Array.isArray(value) ? value : [value];
  }
}

export default WebsocketOrchestrator;
