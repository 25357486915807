export default {
  clearItem: state => (state.item = undefined),
  removeItem: state => (state.item = undefined),
  setItemParseFunction: (state, itemParseFunction) => {
    state.itemParseFunction = itemParseFunction;
  },
  setItem: (state, item) => {
    state.item = state.itemParseFunction ? state.itemParseFunction(item) : item;
  },
  setLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setLoaded: (state, isLoaded) => (state.isLoaded = isLoaded),
};
