import crel from "crel";
import fastdom from "fastdom";
import FastdomPromised from "fastdom/extensions/fastdom-promised";
import Colorizer from "@/models/Colorizer";
import i18n from "@/i18n.js";
import { formatPercentage } from "@/models/Formatters";
import { keyBy } from "lodash";
import "@mdi/font/css/materialdesignicons.css";

crel.attrMap["on"] = (element, value) => {
  for (let eventName in value) {
    element.addEventListener(eventName, value[eventName]);
  }
};

class ActivitiesDom {
  constructor() {
    this.elements = new Map();
    this.elementsByActivityId = new Map();
    this.nbWeeks = 0;
    this.minDte = Date.withoutTime();
    this.dayWidth = 0.142857143;
    this.listeners = {};
    this.lastFloor = undefined;
  }

  setCalendar(calendar) {
    this.calendar = calendar;
  }

  getElementsByActivityId(activityId) {
    return this.elementsByActivityId.get(parseInt(activityId));
  }

  getFirstElementByActivityId(activityId) {
    return this.getElementsByActivityId(activityId)[0];
  }

  getLastElementByActivityId(activityId) {
    const elements = this.getElementsByActivityId(activityId);

    return elements[elements.length - 1];
  }

  getElementById(elementId) {
    return this.elements.get(elementId);
  }

  getParentElement(activityId) {
    return this.getFirstElementByActivityId(activityId)?.parent;
  }

  getActivityByElementId(id) {
    return this.getElementById(id)?.activity;
  }

  setMinDate(minDate) {
    this.minDate = minDate;
  }

  setNbWeeks(nbWeeks) {
    this.nbWeeks = nbWeeks;
  }

  setListeners(listeners) {
    this.listeners = listeners;
  }

  setItems(activitiesDom, callback) {
    fastdom.extend(FastdomPromised).mutate(_ => {
      this.removeItemsFromDom(activitiesDom);
      (activitiesDom || []).forEach(activityDom => this.setItem(activityDom));
    }).then(_ => {
      if (callback) callback();
    });
  }

  setLastPartSplittedActivities(activitiesParts) {
    this.lastPartSplittedActivities = activitiesParts;
  }

  removeItems(activities) {
    (activities || []).forEach(activity => this.removeItem(activity));
  }

  async setItem(activity) {
    fastdom.mutate(_ => {
      if (!activity) return;
      if (!this.elements.get(activity.id)) this.createElement(activity);

      let element = this.elements.get(activity.id);
      let positionUpdated = this.updatePosition(element, activity);
      let serviceUpdated = this.updateService(element, activity);
      let partUpdated = this.updatePart(element, activity);

      if (positionUpdated || serviceUpdated || partUpdated) element.tooltip.updated = false;

      element.activity = activity;
    });
  }

  removeItem(activity) {
    if (!activity || !this.elementsByActivityId.has(activity.id)) return;

    const elements = this.elementsByActivityId.get(activity.id);
    for (const element of elements) {
      let activityEl = element.parent;
      activityEl.parentNode.removeChild(activityEl);
      this.elements.delete(element.activity.id);
    }

    this.elementsByActivityId.delete(activity.id);
  }

  removeItemsFromDom(activitiesDom) {
    (activitiesDom || []).forEach(activityDom => this.removeItemFromDom(activityDom));
  }

  removeItemFromDom(activityDom) {
    const activityId = activityDom.getActivityId();
    if (!this.elementsByActivityId.has(activityId)) return;

    const elements = this.elementsByActivityId.get(activityId);
    for (const element of elements) {
      let activityEl = element.parent;
      activityEl.parentNode.removeChild(activityEl);
      this.elements.delete(element.activity.id);
    }

    this.elementsByActivityId.delete(activityId);
  }

  async onServiceUpdate(service) {
    let activity;
    this.elements.forEach(element => {
      if (element.activity.service.id == service.id) {
        activity = Object.assign({}, element.activity, { service: service });
        this.updateService(element, activity);
        element.activity = activity;
        element.tooltip.updated = false;
      }
    });
  }

  clearItems() {
    this.elements.clear();
  }

  buildTooltipFinishedTitle() {
    let title = document.createElement("h3");
    title.innerText = i18n.t("labels.finishedActivity");
    title.style = "margin-bottom: 3px; border-bottom: 1px solid #fff; font-style: italic;";
    return title;
  }

  async updateTooltip(activityId) {
    let element = this.elements.get(activityId);
    let activity = element.activity;
    let tooltip = element.tooltip;
    if (!tooltip.updated) {
      const finishedTextLength = i18n.t("labels.finishedActivity").length;
      const hasFinishedText = tooltip.parent.textContent.slice(0, finishedTextLength) == i18n.t("labels.finishedActivity");
      if (activity.totalPercentageCompleted === 100 && !hasFinishedText) {
        tooltip.parent.prepend(this.buildTooltipFinishedTitle());
      }

      tooltip.serviceName.innerText = activity.service.name;
      tooltip.startAt.innerText = activity.startAt.shortFormat();
      tooltip.endAt.innerText = activity.endAt.shortFormat();
      tooltip.workDuration.innerText = this.formatDuration(activity.workDuration);
      tooltip.percentageCompleted.innerText = formatPercentage(activity.totalPercentageCompleted, true);
      tooltip.part.style.display = activity.part ? "block" : "none";
      tooltip.part.innerText = `${i18n.t("general.split.part")} ${activity.part}`; // old tooltip
      tooltip.currentPart.style.display = activity.hasParts() ? "block" : "none";
      tooltip.currentPart.innerText = `${i18n.t("general.split.part")} ${activity.currentPart}`;
      tooltip.updated = true;
    }
  }

  handlePercentageCompletedLock(activity) {
    const completed = activity.totalPercentageCompleted && activity.totalPercentageCompleted == 100;
    const activityElement = this.elements.get(activity.id);
    const method = completed ? "add" : "remove";
    activityElement.parent.classList[method]("disable-drag");
    activityElement.tooltip.parent.classList[method]("completed");
  }

  async updatePosition(element, activity, forcedUpdate) {
    let sameStart =
      element.activity && element.activity.startAt.valueOf() == activity.startAt.valueOf();
    let sameEnd = element.activity && element.activity.endAt.valueOf() == activity.endAt.valueOf();
    let sameLine = element.activity && element.activity.line == activity.line;
    if (!forcedUpdate && sameStart && sameEnd && sameLine) return false;

    fastdom.mutate(_ => {
      element.position = this.getPosition(activity);
      element.parent.style.cssText = `top: ${element.position.top}; left: ${element.position.left}; width: ${element.position.width};`;
    });
    return true;
  }

  async updateService(element, activity) {
    let sameName = element.activity && element.activity.service.name == activity.service.name;
    let sameColor = element.activity && element.activity.service.color == activity.service.color;
    let samePercentage =
      element.activity && element.activity.percentageCompleted == activity.percentageCompleted;
    let sameStartAndEndAt =
      element.activity &&
      element.activity.startAt == activity.startAt &&
      element.activity.endAt == activity.endAt;
    if (sameName && sameColor && samePercentage && sameStartAndEndAt) return false;
    if (!samePercentage) this.handlePercentageCompletedLock(activity);

    let serviceColorEL = element.service.color;
    let serviceNameEL = element.service.name;
    serviceColorEL.style.setProperty(
      "background",
      this.getServiceBackground(activity),
      "important",
    );
    serviceNameEL.innerText = activity.service.name;
    return true;
  }

  async updatePart(element, activity) {
    let isSamePart = element.activity && element.activity.part == activity.part;
    if (isSamePart) return false;
    let classList = element.service.name.classList;
    activity.part ? classList.add("has-part") : classList.remove("has-part");
  }

  async updateAllPositions() {
    this.elements.forEach(element => {
      this.updatePosition(element, element.activity, true);
    });
  }

  async highlightActivities(activityIds, color) {
    const hiddenServicesId = keyBy(activityIds || [], id => id);

    this.elements.forEach(element => {
      const border = !hiddenServicesId[element.activity.getActivityId()] ? "hidden" : "solid";
      element.service.color.style.setProperty("border", `2px ${border} ${color}`, "important");
    });
  }

  async applyFilter(activitiesFilter, servicesFilter) {
    const activitiesIdSet = new Set(activitiesFilter);
    const servicesIdSet = new Set(servicesFilter);
    this.elements.forEach(element => {
      const activityId = element.activity.getActivityId().toString();
      const serviceId = element.activity.service.id

      element.parent.style.display = activitiesIdSet.has(activityId) || servicesIdSet.has(serviceId) ? "none" : "block";
    });
  }

  createElement(activity) {
    let activityElement = this.buildActivityBlock(activity);
    let mappedElement = {
      parent: activityElement,
      tooltip: {
        parent: activityElement.querySelector(".activity-tooltip"),
        serviceName: activityElement.querySelector(".tooltip-service-name"),
        startAt: activityElement.querySelector(".start-at"),
        endAt: activityElement.querySelector(".end-at"),
        workDuration: activityElement.querySelector(".work-duration"),
        percentageCompleted: activityElement.querySelector(".percentage-completed"),
        part: activityElement.querySelector(".part"),
        currentPart: activityElement.querySelector(".current-part"),
      },
      service: {
        name: activityElement.querySelector(".service-name"),
        color: activityElement.querySelector(".service-color"),
      },
    };

    let floorEl = document.getElementById(`floor-${activity.floor.id}`);
    floorEl.appendChild(mappedElement.parent);

    this.elements.set(activity.id, mappedElement);

    const elementsByActivityId = this.elementsByActivityId.get(activity.getActivityId());
    if (elementsByActivityId) {
      elementsByActivityId.push(mappedElement);
    } else {
      this.elementsByActivityId.set(activity.getActivityId(), [mappedElement]);
    }
  }

  buildActivityBlock(activity) {
    return crel(
      "div",
      {
        id: activity.id,
        "data-cy": "block-activity-id",
        class: "activity-slot slot no-select",
        on: {
          contextmenu: event => {
            this.listeners.contextmenu(event, activity.getActivityId());
          },
          mouseover: event => {
            this.listeners.mouseover(event, activity.getActivityId());
          },
          click: event => {
            if (event.button === 1 || event.ctrlKey) return; // skip mouse middle button or ctrl + click

            this.listeners.click(event, activity.getActivityId());
          },
          mouseleave: event => {
            this.listeners.mouseleave(event, activity.getActivityId());
          },
          dblclick: event => {
            this.listeners.dblclick(event, activity.getActivityId());
          },
          mousemove: event => {
            let rect = event.currentTarget.getBoundingClientRect();
            let tooltip = event.currentTarget.querySelector(".tooltip");
            tooltip.style.left = `${event.clientX - rect.left - tooltip.offsetWidth / 2}px`;
            this.resetTooltipPosition(tooltip);
            this.updateTooltipPosition(tooltip, activity);
            this.updateTooltip(activity.id);
          },
        },
      },
      this.buildTooltip(),
      this.buildServiceBlock(activity),
    );
  }

  activityCurrentLine(activity) {
    let element = this.elements.get(activity.id);
    if (element && element.activity) return element.activity.line;
    return activity.line;
  }

  activityCurrentPercentageCompleted(activity) {
    let element = this.elements.get(activity.id);
    if (element && element.activity) return element.activity.totalPercentageCompleted;
    return activity.totalPercentageCompleted;
  }

  resetTooltipPosition(tooltip) {
    tooltip.classList.remove("tooltip-reverse");
    tooltip.classList.remove("tooltip-reverse-complete");

    tooltip.classList.remove("tooltip-reverse-split-complete");
    tooltip.classList.remove("tooltip-reverse-split-last");

    if (this.lastFloor) this.lastFloor.style.zIndex = "0";
  }

  updateTooltipPosition(tooltip, activity) {
    if (!this.isTooltipPositionCloseBorderTop(activity)) return;

    let percentageCompleted = this.activityCurrentPercentageCompleted(activity);

    this.lastFloor = this.getFloorOnTop(activity);
    if (this.lastFloor) this.lastFloor.style.zIndex = "1";

    if (activity.part) {
      if (percentageCompleted === 100) {
        tooltip.classList.add("tooltip-reverse-split-complete");
      } else {
        tooltip.classList.add("tooltip-reverse-split-last");
      }
    } else {
      if (percentageCompleted === 100) {
        tooltip.classList.add("tooltip-reverse-complete");
      } else {
        tooltip.classList.add("tooltip-reverse");
      }
    }
  }

  isTooltipPositionCloseBorderTop(activity) {
    let floorOnTop = this.getFloorOnTop(activity);
    let floorCounter = this.getFloorCount(floorOnTop);
    let mostCurrentLine = this.activityCurrentLine(activity);

    if (!floorOnTop) return false;
    if (floorCounter == undefined) return false;
    if (parseInt(floorCounter) + mostCurrentLine >= 4) return false;

    return true;
  }

  getFloorOnTop(activity) {
    return document.getElementById(`floor-${activity.floor.id}`);
  }

  getFloorCount(floorOnTop) {
    return floorOnTop.getAttribute("count-handler");
  }

  buildTooltip(activity) {
    let classNames = "mr-1.ml-1 caption font-weight-light";
    if (!this.isLastPart(activity) && activity.percentageCompleted !== 100) {
      classNames = "mdi mdi-alert-circle-outline mr-4" + classNames;
    }

    return crel(
      "div",
      { class: "text-center white--text tooltip activity-tooltip" },
      crel("div", crel("strong", { class: "tooltip-service-name caption font-weight-bold" })),
      crel("div", crel("span", { class: "part caption font-weight-light" })),
      crel("div", crel("span", { class: "current-part caption font-weight-light" })),
      crel(
        "div",
        crel("span", { class: "start-at caption font-weight-regular" }),
        crel("span", { class: "mr-1.ml-1 font-weight-light" }, " > "),
        crel("span", { class: "end-at caption font-weight-regular" }),
        crel("span", { class: "mr-1.ml-1 font-weight-light" }, " | "),
        crel("span", { class: "work-duration caption font-weight-regular" }),
      ),
      crel(
        "div",
        crel(
          "span",
          { class: classNames },
          ` ${i18n.t("labels.realized")}: `,
        ),
        crel("span", { class: "percentage-completed font-weight-light" }),
      ),
    );
  }


  buildServiceBlock(activity) {
    let serviceBlock = crel(
      "div",
      { id: "slot-name", class: "service-color no-select" },
      crel("span", { class: "service-name" }),
    );
    this.addDraggableBehavior(
      serviceBlock,
      {
        ondragstart: this.listeners.dragstart,
        ondragstop: this.listeners.dragstop,
        ondrag: this.listeners.drag,
      },
      activity.id,
    );
    return serviceBlock;
  }

  truncate2decimals(num) {
    return Number(num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }

  getServiceBackground(activity) {
    const opacityColor = Colorizer.hexToRGB(activity.service.color, 0.3);
    const businessDaysProgress = this.calendar.addBusinessDays(
      activity.startAt,
      (activity.workDuration * this.truncate2decimals(activity.percentageCompleted)) / 100,
      true,
    );

    const activityTotalCalendarDays = activity.endAt - activity.startAt;
    const totalDaysToPercentage = businessDaysProgress - activity.startAt;
    const percentage = (100 * totalDaysToPercentage) / activityTotalCalendarDays;
    return `linear-gradient(90deg, ${opacityColor} ${percentage}%, ${activity.service.color} ${percentage}%)`;
  }

  getPosition(activity) {
    let ui = {
      start: activity.startAt.weekDiff(this.minDate),
      end: activity.endAt.weekDiff(this.minDate),
    };
    return {
      left: (ui.start / this.nbWeeks) * 100 + "%",
      width: ((ui.end - ui.start) / this.nbWeeks) * 100 + "%",
      top: `${(activity.line - 1) * 25 + 5}px`,
    };
  }

  addDraggableBehavior(el, listeners, activityId) {
    el.addEventListener("mousedown", function(event) {
      event.preventDefault();
      let x = 0;
      let y = 0;

      if (!event) return true;
      if (event.which && event.which != 1) return true;
      if (event.button && event.button != 0) return true;

      x = event.pageX;

      document.addEventListener("mousemove", mousemove);
      document.addEventListener("mouseup", mouseup);

      if (listeners.ondragstart) {
        listeners.ondragstart(event, activityId);
      }

      function mousemove(event) {
        if (event.ctrlKey) return;

        const delta = {
          x: event.pageX - x,
          y: event.y,
        };

        if (listeners.ondrag) {
          listeners.ondrag(event, delta, activityId);
        }
      }

      function mouseup(event) {
        document.removeEventListener("mousemove", mousemove);
        document.removeEventListener("mouseup", mouseup);

        if (listeners.ondragstop) {
          listeners.ondragstop(event, activityId);
        }
      }
    });
  }

  formatDuration(duration) {
    let fixed = Number(duration.toFixed(2));
    let formatted = fixed % 1 == 0 ? parseInt(fixed) : fixed;
    return `${formatted} ${" " + i18n.tc("general.day", duration)}`;
  }

  isLastPart(activity) {
    if (!activity?.part) return true;

    return this.lastPartSplittedActivities[[activity.floor.id, activity.service.id]] === activity.part;
  }
}

export default ActivitiesDom;
