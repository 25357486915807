export default {
  setActiveTab: (state, tabName) => {
    state.activeTab = tabName;
  },
  setName: (state, name) => {
    state.budgetForm.name = name;
    state.siengeForm.name = name;
    state.totvsForm.name = name;
    state.megaForm.name = name;
  },
  setDashboardWeightId: (state, dashboardWeightId) => (state.dashboardWeightId = dashboardWeightId),
  setConfiguredErp: (state, configuredErp) => (state.configuredErp = configuredErp),
  setBudgetForm: (state, budgetForm) => (state.budgetForm = budgetForm),
  setSiengeForm: (state, siengeForm) => (state.siengeForm = siengeForm),
  setMegaForm: (state, megaForm) => (state.megaForm = {...megaForm}),
  setTotvsForm: (state, totvsForm) => (state.totvsForm = {...totvsForm}),
  setImportErrors: (state, importErrors) => (state.importErrors = importErrors),
  setRowsErrors: (state, rowsErrors) => (state.rowsErrors = rowsErrors),
  setTmpFile: (state, tmpFile) => (state.tmpFile = tmpFile),
  resetForms: state => {
    state.budgetForm = {
      name: undefined,
      code: undefined,
      start: undefined,
      description: undefined,
      materialCost: undefined,
      totalCost: undefined,
      laborCost: undefined,
      excelKey: undefined,
    };
    state.siengeForm = {
      name: undefined,
      siengeProject: undefined,
      constructionUnit: undefined,
    };
    state.megaForm = {
      name: undefined,
      externalProjectId: undefined,
      externalBudgetId: undefined,
    };
  },
  clearState: state => {
    state.budgetReport.id = undefined;
    state.budgetReport.name = undefined;
  },
  setBudgetReportData: (state, budgetReportData) => {
    state.budgetReport.id = budgetReportData.id;
    state.budgetReport.name = budgetReportData.name;
  },
  setBudgetReportUpdateStatus: (state, updateStatus) => (state.budgetReport.isUpdated = updateStatus),
  setBudgetCategoryLimit: (state, hasReachedCategoryLimit) => (state.hasReachedCategoryLimit = hasReachedCategoryLimit),
};
