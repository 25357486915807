import Graph from '../../../models/Graph';
import { clone, sortBy, filter, groupBy, last } from 'lodash';
import DependentDateCalculator from '@/models/DependentDateCalculator';
import GetHiddenActivities from '@/models/GetHiddenActivities.js';

class ActivitiesManager {
  static FLOORS_ITEMS = 'floors/items';

  constructor(store, i18n) {
    this.store = store;
    this.changedActivities = {};
    this.hasDatesChanged = false;
    this.changedLine = false;
    this.selectedActivities = new Set();
    this.floorsByReplication = {};
    this.activitiesByService = {};
    this.updatedActivities = [];
    this.lastPartSplittedActivities = {};
    this.i18n = i18n;
  }

  prepareGraph() {
    this.activitiesGraph = new Graph();
    let activities = this.getActivities() || [];
    if (activities.length) {
      this.prepareFloorsByReplication();
      this.prepareActivitiesByService();
      this.setItems(activities, false);
      this.setupWatchers();
      this.prepareLastPartSplittedActivities(); // old split
      this.validateAllDependencies();
    }
  }

  prepareFloorsByReplication(floors = undefined) {
    this.floorsByReplication = groupBy(
      floors || this.store.getters[ActivitiesManager.FLOORS_ITEMS],
      'replicationGroupId',
    );
  }

  // prepare last part on old split, does not include the new split
  prepareLastPartSplittedActivities() {
    this.lastPartSplittedActivities = {}
    this.getActivities().forEach(activity => {

      if(!activity.part) return;
      let floorIdServiceId = [activity.floorId, activity.serviceId]

      this.lastPartSplittedActivities[floorIdServiceId];
      if(!this.lastPartSplittedActivities[floorIdServiceId] || this.lastPartSplittedActivities[floorIdServiceId] < activity.part) {
        this.lastPartSplittedActivities[floorIdServiceId] = activity.part;
      }
    })
  }

  prepareActivitiesByService(activities) {
    this.activitiesByService = groupBy(activities || this.getActivities(), 'serviceId');
  }

  setupWatchers() {
    this.store.watch(
      (_, getters) => getters[ActivitiesManager.FLOORS_ITEMS],
      (floors, _) => {
        this.prepareFloorsByReplication(floors);
      },
    );
    this.store.watch(
      (_, getters) => getters['activities/items'],
      (activities, oldActivities) => {
        if (activities.length != oldActivities.length) {
          this.prepareActivitiesByService(activities);
        }
      },
    );
  }

  setCalendar(calendar) {
    this.calendar = calendar;
    this.dependentDateCalculator = new DependentDateCalculator(calendar, this.store.getters, this.i18n);
  }

  setItems(activities, removeEdges = true) {
    if (!this.activitiesGraph) this.prepareGraph();

    activities.forEach(activity => this.activitiesGraph.addVertex(activity.id));
    activities.forEach(activity => this.addDependenciesEdge(activity, removeEdges));
    this.store.commit('schedule/setDependencyCycle', this.activitiesGraph.getCycle());
  }

  addDependenciesEdge(activity, removeEdges = true) {
    if (removeEdges) this.activitiesGraph.removeEdgesTo(activity.id);
    (activity.dependencies || []).forEach(dependency => {
      this.activitiesGraph.addEdge(dependency.referenceActivityId, activity.id);
    });
  }

  removeItems(activities) {
    activities.forEach(activity => {
      this.activitiesGraph.removeEdgesTo(activity.id);
      this.activitiesGraph.edges[activity.id].map(dependentId =>
        this.activitiesGraph.removeEdge(activity.id, dependentId),
      );
      this.activitiesGraph.removeVertex(activity.id);
    });
  }

  getHiddenActivitiesIds() {
    return new GetHiddenActivities(this.getActivities(), this.filterStateByType()).perform().map(activity => activity.id);
  }

  filterStateByType() {
    return {
      hiddenFloorsIds: this.store.getters['schedule/hiddenFloorsId'],
      hiddenServicesId: this.store.getters['schedule/hiddenServicesId'],
      hiddenActivitiesIdsByResponsiblesFilter: this.store.getters['schedule/hiddenActivitiesIdsByResponsiblesFilter'],
      scheduleStart: this.store.getters['schedule/start'],
      scheduleEnd: this.store.getters['schedule/end'],
    }
  }

  getActivities() {
    return this.store.getters['activities/items'];
  }

  getActivityOf(id) {
    return this.store.getters['activities/item'](id);
  }

  hasCycle() {
    return !!this.store.getters['schedule/dependencyCycle'];
  }

  getFloorsWithReplicationGroupOf(id) {
    return filter(this.store.getters[ActivitiesManager.FLOORS_ITEMS], { replicationGroupId: id });
  }

  getSimilarActivitiesOf(activity) {
    const similarActivities = this.activitiesByService[activity.serviceId];
    return sortBy(similarActivities, activity => activity.startAt);
  }

  getDependentsOf(activity) {
    return this.activitiesGraph.edges[activity.id].map(dependentId =>
      this.getActivityOf(dependentId),
    );
  }

  getDependenciesOf(activity) {
    return activity.dependencies.map(dependency =>
      this.getActivityOf(dependency.referenceActivityId),
    );
  }

  changeLine(activity, line) {
    activity.line = line;
    this.changedLine = true;
    this.store.commit('activities/setItems', [activity]);
  }

  onlyChangedLine() {
    return (!this.hasDatesChanged && this.changedLine);
  }

  clearHasDatesChanged() {
    this.hasDatesChanged = false;
    this.changedLine = false;
  }

  mergePartsIfNeeded(activity) {
    if(!activity.hasParts()) return;

    const lastPart = activity.getParts()[activity.getParts().length-1];
    const newStartAt = this.calendar.subtractBusinessDays(lastPart.startAt, 2, true);
    if (!this.isStartAtBeforePreviousPart(activity, newStartAt)) return;

    this._mergeLastPart(activity);
    this.updatedActivities.push(activity);
    this.commitActivityNormalUpdateChanges();
  }

  _mergeLastPart(activity) {
    const [secondLastPart, lastPart] = activity.popParts(2); // remove the two last parts
    const newDuration = secondLastPart.duration + lastPart.duration;

    const mergedPart = {
      startAt: secondLastPart.startAt,
      endAt: this.calendar.addBusinessDays(secondLastPart.startAt, newDuration, true),
      duration: newDuration
    }

    activity.endAt = mergedPart.endAt;
    if (activity.hasParts()) activity.addPart(mergedPart);
  }

  activityDatesChanged(activity, attributes, ignoreCompletedVerification = false) {
    if (this.isStartAtBeforePreviousPart(activity, attributes.startAt)) return;

    this.normalUpdate(activity, attributes, ignoreCompletedVerification);
    this.commitActivityNormalUpdateChanges();
  }

  isStartAtBeforePreviousPart(activity, newStartAt) {
      if (!activity.hasParts()) return false;

      const partsLength = activity.getPartsLength();
      const activityPreviousPart = activity.getParts()[partsLength - 2]

      return activityPreviousPart.endAt > newStartAt;
  }

  normalUpdate(activity, attributes, ignoreCompletedVerification = false) {
    if (!this.isActive(activity) && !ignoreCompletedVerification) return;

    this.hasDatesChanged = true;
    this.updatedActivities.push(this.updateActivity(activity, attributes));

    const beforeUpdateDates = { startAt: activity.startAt, endAt: activity.endAt };
    this.activitiesGraph.edges[activity.id].forEach(dependentId => {
      const dependent = this.getActivityOf(dependentId);
      const dates = this.calculateDates(dependent, beforeUpdateDates.startAt, attributes.startAt);
      const startChanged = dates.startAt.valueOf() != dependent.startAt.valueOf();
      const endChanged = dates.endAt.valueOf() != dependent.endAt.valueOf();

      if ((startChanged || endChanged) && dependent.isNotCompleted() && !dependent.hasParts()) {
        this.normalUpdate(dependent, dates, ignoreCompletedVerification);
      }
    });
  }

  isActive(activity){
    return !activity.isCompleted() && this.isLastPart(activity);
  }

  // check if is last part on old split, does not include the new split
  isLastPart(activity) {
    if(!activity?.part) return true;

    return this.lastPartSplittedActivities[[activity.floorId, activity.serviceId]] === activity.part;
  }

  commitActivityNormalUpdateChanges(){
    this.store.commit('activities/setItems', this.updatedActivities);
    this.updatedActivities = [];
  }

  calculateDateLock(activity, start) {
    if (!activity.dependencies || activity.hasParts()) return { start: false, end: false };

    const { minStart, maxStart } = this.calculateDependenciesDate(activity);
    return {
      start: minStart && start < minStart,
      end: maxStart && start > maxStart,
    };
  }

  calculateDependenciesDate(activity) {
    let minStart = this.dependentDateCalculator.getMinStart(
      activity.dependencies,
      activity.workDuration,
    );
    let maxStart = this.dependentDateCalculator.getMaxStart(
      activity.dependencies,
      activity.workDuration,
    );

    return { minStart, maxStart };
  }

  calculateDates(dependent, beforeStartAt, afterStartAt) {
    const { minStart, maxStart } = this.calculateDependenciesDate(dependent);
    const diff = afterStartAt < beforeStartAt ? this.calendar.businessDaysBetween(afterStartAt, beforeStartAt) : 0;

    const max = Math.max(
      maxStart || 0,
      minStart || 0,
      this.gapsDate(dependent, diff),
    );

    const startAt = new Date(max > 0 ? max : dependent.startAt);
    return {
      startAt: startAt,
      endAt: this.calendar.addBusinessDays(startAt, dependent.workDuration, true),
    };
  }

  gapsDate(dependent, diff){
    if(this.newGapsBehaviorEnabled()){
      return (this.isGapsAllowed() ? this.calendar.subtractBusinessDays(dependent.startAt, diff + 1, true) : 0)
    }else{
      return (this.isSpacingAllowed(dependent) ? dependent.startAt : 0)
    }
  }

  newGapsBehaviorEnabled(){
    return this.store.getters['releasedRollouts'].includes("schedule:new_allow_gaps_flag_behavior");
  }

  isSpacingAllowed(dependent) { // remove with `schedule:new_allow_gaps_flag_behavior` rollout
    let includeService = dependent.dependencies
      .map(d => (this.store.getters['activities/item'](d.referenceActivityId) || {}).serviceId)
      .includes(dependent.serviceId);
    return !includeService && this.isGapsAllowed();
  }

  isGapsAllowed(){
    return this.store.getters['schedule/isGapsAllowed'];
  }

  updateActivity(activity, attributes = {}) {
    activity.update(attributes);

    if (attributes.dependencies != undefined) {
      this.setItems([activity]);
    }
    this.changedActivities[activity.id] = activity;
    return activity;
  }

  clearChangedActivities() {
    let changedActivities = clone(this.changedActivities);
    this.changedActivities = {};
    return Object.values(changedActivities);
  }

  addChangedActivities(activities) {
    activities.forEach(activity => {
      this.changedActivities[activity.id] = activity;
    });
  }

  hasChangedActivities() {
    return Object.values(this.changedActivities).length > 0;
  }

  validateAllDependencies() {
    for (const activity of this.getActivities()) {
      if (activity.isCompleted()) continue;

      for (const dependency of activity.dependencies) {
        if (this.getActivityOf(dependency.referenceActivityId)) continue;

        const activityId = dependency.floatingActivityId;
        throw new Error(this.i18n.t('components.schedule.invalidDependencyError', { activityId }));
      }
    }
  }

  // isLastPart if for old split
  getDelayedUnstartedActivities() {
    return this.getActivities().filter(activity => this.isLastPart(activity) && !activity.hasParts() && activity.isDelayed() && activity.isUnstarted());
  }

  getAheadCompletedActivities() {
    return this.getActivities().filter(activity => !activity.hasParts() && activity.isAhead() && activity.isCompleted());
  }

  getAheadIncompletedActivities() {
    return this.getActivities().filter(activity => !activity.hasParts() && activity.isAhead() && activity.isNotCompleted());
  }
}

export default ActivitiesManager;
