import { thisWeek } from '@/models/DateRange.js';
let week = thisWeek();

export default {
  projectId: null,
  startAt: week.startAt,
  endAt: week.endAt,
  floorIds: [],
  workPackageIds: [],
  responsibleNames: [],
  withoutResponsible: false,
  startOfWeek: 0,
  floorFormField: [],
  printMode: false
};
