import Vue from 'vue';
import axios from 'axios';
import applyConverters from 'axios-case-converter';
import RequestInterceptor from './RequestInterceptor';
import ResponseInterceptor from './ResponseInterceptor';

const config = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 120000,
  headers: {
    Accept: 'application/json',
    'X-Api-Key': import.meta.env.VITE_API_GATEWAY_KEY,
  },
};

/* eslint-disable */

const _axios = applyConverters(axios.create(config));
_axios.CancelToken = axios.CancelToken;
_axios.interceptors.request.use(RequestInterceptor.success, RequestInterceptor.error);
_axios.interceptors.response.use(ResponseInterceptor.success, ResponseInterceptor.error);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

/* eslint-enable */
Vue.use(Plugin);
export default Plugin;
