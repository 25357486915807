import i18n from '@/i18n.js';

class ErrorResponse {
  constructor(response) {
    if(response === undefined) return;

    this.status = response.status;
    this.data = response.data;
    this.isDeprecated = this.data.version == 'deprecated';
  }

  getMessage() {
    if (this.isConnectionFailed()) {
      return i18n.t('general.errors.connectionFailure');
    }

    if (this.isServerError()) {
      return i18n.t('general.errors.unexpectedError');
    }

    if (this.isDeprecated) {
      return Array.isArray(this.data.errors) ? this.data.errors[0].message : this.data;
    }

    if (typeof this.data === 'string') return this.data;

    return this.data.error && this.data.error.message ? this.data.error.message : this.data.error;
  }

  isServerError() {
    return this.status?.toString()?.startsWith("5");
  }

  isConnectionFailed(){
    return this.status === undefined;
  }

  isUnauthorized() {
    return this.status === 401;
  }

  isServiceUnavailable() {
    return this.status === 503;
  }

  isForbidden() {
    return this.status === 403;
  }
}

export default ErrorResponse;
