export default async function validateRouteAuthorization({to, from, next, router}) {
  const middleware = to.matched.flatMap(route => route.meta.middleware || []);

  const context = {
    from,
    next,
    router,
    to,
  };

  const nextMiddleware = nextFactory(context, middleware, 1);

  return await middleware[0]({...context, next: nextMiddleware});
}

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return async (...parameters) => {
    context.next(...parameters);

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    await subsequentMiddleware({...context, next: nextMiddleware});
  };
}
