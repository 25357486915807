export default {
  filterForm: state => {
    return {
      projectIds: state.projectIds,
      datesRange: {
        startAt: state.startAt,
        endAt: state.endAt,
      },
      userIds: state.userIds,
      labelIds: state.labelIds
    };
  },
  printMode: state => state.printMode,
};
