import Vue from 'vue';

Vue.directive('handle-drag', {
  bind: function(el, binding) {
    let x = 0;
    let y = 0;

    el.addEventListener('mousedown', function(event) {
      event.preventDefault();

      if (!event) return true;
      if (event.which && event.which != 1) return true;
      if (event.button && event.button != 0) return true;

      x = event.pageX;

      document.addEventListener('mousemove', mousemove);
      document.addEventListener('mouseup', mouseup);

      if (binding.value.ondragstart) {
        binding.value.ondragstart(event);
      }
    });

    function mousemove(event) {
      const delta = { x: event.pageX - x, y: event.y - y };
      if (binding.value.ondrag) {
        binding.value.ondrag(event, delta);
      }
    }

    function mouseup(event) {
      document.removeEventListener('mousemove', mousemove);
      document.removeEventListener('mouseup', mouseup);

      if (binding.value.ondragstop) {
        binding.value.ondragstop(event);
      }
    }
  },
});
