import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { safeStringfy } from '@/models/Utils';

Sentry.init({
  dsn: 'https://d8a16b65d0b84f3b82115968b5fda694@o377155.ingest.sentry.io/5238096',
  environment: import.meta.env.MODE,
  whitelistUrls: ['app.prevision.com.br', 'lyx.prevision.com.br'],
  integrations: [new VueIntegration({ Vue, attachProps: false })],
});

function formatComponentName(vm) {
  if (vm.$root === vm) {
    return 'root instance';
  }
  let name = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;
  return (
    (name ? 'component <' + name + '>' : 'anonymous component') +
    (vm._isVue && vm.$options.__file ? ' at ' + vm.$options.__file : '')
  );
}

Vue.config.errorHandler = function VueErrorHandler(error, vm, info) {
  let metaData = {};
  try {
    console.error(error);

    metaData.componentName = formatComponentName(vm);
    if (typeof info !== 'undefined') {
      metaData.lifecycleHook = info;
    }
    metaData.propsData = vm && vm.$options && vm.$options.propsData;
    metaData.propsDataStringfy = safeStringfy(metaData.propsData);
    metaData.$storeGetters = vm.$store.getters;
    metaData.$storeGettersStringfy = safeStringfy(metaData.$storeGetters);
  } finally {
    Sentry.withScope(scope => {
      if (!metaData.$storeGetters['releasedRollouts'].includes('logging:sentry_web')) return;

      scope.setExtras(metaData);
      Sentry.captureException(typeof error == 'string' ? new Error(error) : error);
    });
  }
};
