<template lang="pug">
  v-app
    v-main
      router-view
      support-chat-button(v-if="isLoggedIn")
</template>

<script>
import SupportChatButton from '@/components/buttons/SupportChatButton.vue';
import { mapGetters } from 'vuex';

history.pushState({}, 'Application entry', location.hash.substring(2));
export default {
  name: 'App',
  components: { SupportChatButton },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  }
};
</script>

<style lang="less">
@import '../node_modules/typeface-roboto/index.css';
@import 'tabulator-tables/dist/css/tabulator.min.css';
@import 'tabulator-tables/dist/css/tabulator_materialize.css';

.v-text-field--filled.v-text-field--single-line.v-input--dense input,
.v-text-field--full-width.v-text-field--single-line.v-input--dense input {
  margin-top: 0 !important;
}

.theme--light.v-text-field--filled .v-text-field__prefix,
.theme--light.v-text-field--filled .v-text-field__suffix {
  margin-top: 0 !important;
}

.v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset {
  border-width: 1px !important;
}

.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(0, 0, 0, 0.02) !important;
}

.v-list--dense {
  .v-list-item__title {
    font-weight: 400 !important;
  }
}

.v-breadcrumbs__item {
  cursor: pointer;
}

.v-breadcrumbs__item--disabled {
  cursor: default !important;
}

.bt-1 {
  border: 1px solid transparent;
}

.br-5 {
  border-radius: 5px;
}
</style>
