export default {
  lobWithMeasurements: {
    id: 1,
    key: "schedule:lob_with_measurements",
    i18nKey: "general.pricing.features.lobWithMeasurements"
  },
  dashboardPhysical: {
    id: 2,
    key: "dashboard:physical",
    i18nKey: "general.pricing.features.physicalDashboard"
  },
  workPackageDetailing: {
    id: 3,
    key: "work_package:detailing",
    i18nKey: "general.pricing.features.workPackageDetailing"
  },
  copyProject: {
    id: 4,
    key: "project:copy",
    i18nKey: "general.pricing.features.projectCopy"
  },
  kanban: {
    id: 5,
    key: "kanban:kanban",
    i18nKey: "general.pricing.features.kanban"
  },
  bim: {
    id: 6,
    key: "bim:bim",
    i18nKey: "general.pricing.features.bim"
  },
  integrations: {
    id: 7,
    key: "integration:erp",
    i18nKey: "general.pricing.features.integrationsErp"
  },
  reports: {
    id: 8,
    key: "report:reports",
    i18nKey: "general.pricing.features.reports"
  },
  budgetOrFinancial: {
    id: 9,
    key: "budget:budget_or_financial",
    i18nKey: "general.pricing.features.budgetOrFinancial"
  },
  dashboardConsolidated: {
    id: 10,
    key: "dashboard:consolidated",
    i18nKey: "general.pricing.features.consolidatedDashboard"
  },
  dashboardFinancial: {
    id: 11,
    key: "dashboard:financial",
    i18nKey: "general.pricing.features.financialDashboard"
  },
  dashboardComparative: {
    id: 12,
    key: "dashboard:comparative",
    i18nKey: "general.pricing.features.comparativeDashboard"
  },
  dashboardShortTerm: {
    id: 13,
    key: "dashboard:short_term",
    i18nKey: "general.pricing.features.shortTermDashboard",
  },
  dashboardMidTerm: {
    id: 14,
    key: "dashboard:mid_term",
    i18nKey: "general.pricing.features.midTermDashboard",
  },
  dashboardLongTerm: {
    id: 15,
    key: "dashboard:long_term",
    i18nKey: "general.pricing.features.longTermDashboard",
  },
  workPackageResponsibles: {
    id: 18,
    key: "work_package:responsibles",
    i18nKey: "general.pricing.features.workPackageResponsibles",
  },
  resources: {
    id: 19,
    key: "histogram:material_resources",
    i18nKey: "general.pricing.features.resources"
  },
  automaticReprogramming: {
    id: 20,
    key: "schedule:automatic_reprogramming",
    i18nKey: "general.pricing.features.automaticReprogramming"
  },
  qualityIntegration: {
    id: 21,
    key: "integration:quality",
    i18nKey: "general.pricing.features.qualityIntegration"
  },
  criticalPath: {
    id: 22,
    key: "schedule:critical_path",
    i18nKey: "general.pricing.features.criticalPath"
  },
  dashboardHistogram: {
    id: 23,
    key: "dashboard:histogram",
    i18nKey: "general.pricing.features.histogramDashboard"
  },
  contracts: {
    id: 24,
    key: "contracts:contracts",
    i18nKey: "general.pricing.features.contracts"
  },
  macroflow: {
    id: 25,
    key: "macroflow",
    i18nKey: "general.pricing.features.macroflow"
  },
  dashboardGoal: {
    id: 26,
    key: "dashboard:goal",
    i18nKey: "general.pricing.features.goalDashboard"
  },
  ganttSchedule: {
    id: 27,
    key: "gantt:scheduleAndMeasurements",
    i18nKey: "general.pricing.features.ganttSchedule"
  }
}
