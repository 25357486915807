import Vue from 'vue';

export default {
  index: projectId => Vue.axios.get(`projects/${projectId}/sectors`),
  create: (projectId, sector) => Vue.axios.post(`projects/${projectId}/sectors`, sector),
  bulkCreate: (projectId, sectors) =>
    Vue.axios.post(`projects/${projectId}/sectors/bulk_create`, { sectors }),
  activitiesAmount: (projectId, sector) =>
    Vue.axios.post(`projects/${projectId}/sectors/activities_amount`, sector),
  update: (projectId, service) =>
    Vue.axios.put(`projects/${projectId}/sectors/${service.id}`, sector),
  destroy: (projectId, sector) => Vue.axios.delete(`projects/${projectId}/sectors/${sector.id}`),
};
