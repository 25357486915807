import AxiosGqlService from '@/services/AxiosGqlService';
import UsersService from "@/services/UsersService";
import ReleasedRollouts from '@/graphql/query/company/ReleasedRollouts.gql';
import {debounce, isEqual} from 'lodash';

export default {
  loadReleasedRollouts: debounce(async (context) => {
    const projectId = context.state.selectedProjectId
    const response = await AxiosGqlService.post(ReleasedRollouts, {variables: {projectId}});
    const releasedRollouts = response ? response.data.me.releasedRollouts : [];
    if (!isEqual(context.state.releasedRollouts, releasedRollouts)) {
      context.commit("setReleasedRollouts", releasedRollouts);
    }

    return releasedRollouts
  }, 1000, {'leading': true, 'trailing': false}),
  loadSsoProvider: async (context, {email}) => {
    const ssoConfig = await UsersService.getSsoProvider(email)
    context.commit("setSsoProvider", ssoConfig?.provider)
  },
  setCurrentUser: async (context, user) => {
    context.commit("setCurrentUser", user);
    context.dispatch("loadReleasedRollouts");
    context.dispatch("pricing/loadFeatures", {root: true});
    context.dispatch("dashUserPermissions/loadPermissions", {root: true});
    context.dispatch("loadSsoProvider", user)
  },
  setGeneralPhase: ({getters, commit}, generalPhase) => {
    // Validate if there is a selected project before compare the phase.
    // Sometimes the schedule load this method before load the selected project
    if (getters.selectedProject && getters.selectedProject.phase !== generalPhase) { // Do not change this line
      commit("setSelectedProjectId", undefined)
    }

    commit("setGeneralPhase", generalPhase);
  },
}
