import Vue from 'vue';
import store from '@/store';
import { validatePermission, handleAuthorization, PERMISSION_HELPER } from '@/models/Permissions';

Vue.directive('permission', {
  bind: function(el, binding, vnode) {
    PERMISSION_HELPER.hide(el);
    setTimeout(
      _ => {
        let config = Object.assign({}, PERMISSION_HELPER.DEFAULT_CONFIG, binding.value);
        var permission = store.getters.currentUser.permission;

        let hasAuthorization = validatePermission(permission, config);
        config = { hasAuthorization: hasAuthorization, ...config };

        return handleAuthorization(config, el, vnode);
      },
      store.getters.currentUser ? 0 : 1500,
    );
  },
});
