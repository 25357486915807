export default {
  getProjects: state => state.projects,
  getDashboardFilters: state => state.dashboardFilters,
  getDashboardPage: state => state.dashboardPage,
  getEconomicalDashboardCategory: state => state.economicalDashboardCategory,
  getSelectedProject: state => state.projects[0],
  getIsFilterActive: state => state.isFilterActive,
  getFilteredFloorsSize: state => state.filteredFloorsSize,
  getFilteredServicesSize: state => state.filteredServicesSize,
  getIsSelectedDashboardLob: state => state.projects[0]?.scheduleType === "lob",
  getSelectedCustomFilter: state => state.selectedCustomFilter
}
