class GetHiddenActivities {
  constructor(activities, filterStateByType) {
    this.activities = activities;
    this.filterStateByType = filterStateByType;
  }

  perform () {
    return this.hiddenActivities()
  }

  hiddenActivities() {
    return this.activities.filter(activity => this.isActivityHidden(activity));
  }

  isActivityHidden(activity) {
    return (
      this.isFloorHidden(activity.floorId) ||
      this.isServiceHidden(activity.serviceId) ||
      this.isActivityHiddenByDatesFilter(activity) ||
      this.isActivityHiddenByResponsiblesFilter(activity.id)
    );
  }

  isFloorHidden(floorId) {
    return this.filterStateByType.hiddenFloorsIds.includes(floorId);
  }

  isServiceHidden(serviceId) {
    return this.filterStateByType.hiddenServicesId.includes(serviceId);
  }

  isActivityHiddenByDatesFilter(activity) {
    return (
      activity.endAt < this.filterStateByType.scheduleStart ||
      activity.startAt > this.filterStateByType.scheduleEnd
    );
  }

  isActivityHiddenByResponsiblesFilter(activityId) {
    return this.filterStateByType.hiddenActivitiesIdsByResponsiblesFilter.includes((activityId).toString());
  }
}

export default GetHiddenActivities;
