import store from '@/store';
import {FeatureError} from "@/middlewares/errors/featureError";

export default async function dashboardPermission({from, to, next}) {
  if (!to.meta.featureKey) return next();
  await store.dispatch("dashUserPermissions/loadPermissions")

  const dashPermissions = store.getters['dashUserPermissions/permissions']
  const isGanttPlusPlan = store.getters['pricing/isGanttPlusPlan']
  const isDashboardAccessAuthorized = (!isGanttPlusPlan || to.meta?.isGanttAllowed) && dashPermissions.includes(to.meta.featureKey)

  if (!isDashboardAccessAuthorized) return next(new FeatureError);

  return next();
}
