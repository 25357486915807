export default {
  setStart: (state, start) => (state.start = start),
  setEnd: (state, end) => (state.end = end),
  setSelectedWorkdays: (state, selectedWorkdays) => (state.selectedWorkdays = selectedWorkdays),
  setHolidays: (state, holidays) => (state.holidays = holidays),
  setCustomWorkdays: (state, customWorkdays) => (state.customWorkdays = customWorkdays),
  addCustomWorkday: (state, date) => state.customWorkdays.push(date),
  removeCustomWorkday: (state, date) => {
    const index = state.customWorkdays.indexOf(date);
    state.customWorkdays.splice(index, 1);
  },
  setProjectId: (state, projectId) => (state.projectId = projectId),
  setNationalHolidaysSelection: (state, nationalHolidaysSelection) =>
    (state.nationalHolidaysSelection = nationalHolidaysSelection),
  setCustomHolidays: (state, customHolidays) => (state.customHolidays = customHolidays),
  addCustomHoliday: (state, date) => state.customHolidays.push(date),
  removeCustomHoliday: (state, date) => {
    const index = state.customHolidays.indexOf(date);
    state.customHolidays.splice(index, 1);
  },
  setIsEditing: (state, isEditing) => (state.isEditing = isEditing),
};
