import { maxBy } from 'lodash';

class DependentDateCalculator {
  constructor(calendar, getters, i18n) {
    this.calendar = calendar;
    this.getters = getters;
    this.i18n = i18n;
  }

  getMinStart(dependencies, currentActivityDuration) {
    const afterDependencies = this.filterByTimeOperation(dependencies, 'after');

    if (!afterDependencies.length) return undefined;

    const minAfterDependency = this.latestDependency(
      afterDependencies,
      'after',
      currentActivityDuration,
    );
    const date = this.referenceDate(minAfterDependency);
    const referenceDate = this.startAtByPrecedenceAttribute(
      date,
      minAfterDependency.precedenceAttribute,
      currentActivityDuration,
    );
    if (minAfterDependency.delay == 0) return this.getSameDay(referenceDate, minAfterDependency);

    const baseDate = this.calendar.nextBusinessDay(referenceDate).beginningOfDay();
    return this.calendar.addBusinessDays(baseDate, minAfterDependency.delay - 1);
  }

  getMaxStart(dependencies, currentActivityDuration) {
    const beforeDependencies = this.filterByTimeOperation(dependencies, 'before');
    if (!beforeDependencies.length) return undefined;

    const maxBeforeDependency = this.latestDependency(
      beforeDependencies,
      'before',
      currentActivityDuration,
    );
    const date = this.referenceDate(maxBeforeDependency);
    const referenceDate = this.startAtByPrecedenceAttribute(
      date,
      maxBeforeDependency.precedenceAttribute,
      currentActivityDuration,
    );
    if (maxBeforeDependency.delay == 0) return this.getSameDay(referenceDate, maxBeforeDependency);

    const baseDate = this.calendar.prevBusinessDay(referenceDate).beginningOfDay();
    return this.calendar.subtractBusinessDays(baseDate, maxBeforeDependency.delay - 1);
  }

  latestDependency(dependencies, timeOperation, currentActivityDuration) {
    return maxBy(dependencies, dependency => {
      const dependencyReferenceDate = this.referenceDate(dependency);
      const possibleStartAt =
        timeOperation === 'after'
          ? this.calendar.addBusinessDays(dependencyReferenceDate, dependency.delay)
          : this.calendar.subtractBusinessDays(dependencyReferenceDate, dependency.delay);
      return this.startAtByPrecedenceAttribute(
        possibleStartAt,
        dependency.precedenceAttribute,
        currentActivityDuration,
      );
    });
  }

  referenceDate(dependency) {
    let activity = this.getActivityOf(dependency.referenceActivityId);

    if (!activity) {
      const activityId = dependency.floatingActivityId;
      throw new Error(this.i18n.t('components.schedule.invalidDependencyError', { activityId }));
    }

    return new Date(
      (dependency.baseAttribute === 'end_at' ? activity.endAt : activity.startAt).valueOf(),
    );
  }

  filterByTimeOperation(dependencies, timeOperation) {
    return dependencies.filter(dependency => {
      return dependency.timeOperation == timeOperation && !!dependency.referenceActivityId;
    });
  }

  getActivityOf(id) {
    return this.getters['activities/item'](id);
  }

  getSameDay(referenceDate, dependency) {
    return dependency.baseAttribute == 'end_at' ? referenceDate.beginningOfDay() : referenceDate;
  }

  startAtByPrecedenceAttribute(referenceDate, precedenceAttribute, currentActivityDuration) {
    return precedenceAttribute == 'end'
      ? this.calendar.subtractBusinessDays(referenceDate, currentActivityDuration - 1)
      : referenceDate;
  }
}

export default DependentDateCalculator;
