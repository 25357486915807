const routes = [
  {
    path: 'admin/:tab?',
    name: 'admin',
    component: () => import('@/views/TabbedBase.vue'),
    meta: {
      topNavbarExtension: () => import('@/components/TopNavbarExtension.vue'),
      tabs: [
        {
          id: 'projects',
          i18n: 'general.projects',
          searchable: true,
          icon: 'mdi-domain',
          component: () => import('@/views/admin/ProjectsTab.vue'),
        },
        {
          id: 'companies',
          i18n: 'general.companies',
          searchable: true,
          icon: 'mdi-account-supervisor',
          component: () => import('@/views/admin/CompaniesTab.vue'),
        },
        {
          id: 'users',
          i18n: 'general.users',
          searchable: true,
          icon: 'mdi-account',
          component: () => import('@/views/admin/UsersTab.vue'),
        },
        {
          id: 'rollouts',
          i18n: 'general.features',
          searchable: true,
          icon: 'mdi-account',
          component: () => import('@/views/admin/RolloutsTab.vue'),
        },
        {
          id: 'actions',
          i18n: 'components.admin.manualActions.tabTitle',
          searchable: false,
          icon: 'mdi-account-supervisor',
          rollout: 'admin_internal:manual_actions',
          component: () => import('@/views/admin/ManualActionsTab.vue'),
        },
        {
          id: 'promotions',
          i18n: 'components.admin.promotions.tabTitle',
          searchable: false,
          icon: 'mdi-offer',
          rollout: 'admin_internal:promotions',
          component: () => import('@/views/admin/PromotionsTab.vue'),
        },
      ],
    },
  },
];

export default routes;
