import Vue from 'vue';
import { isElementsColliding } from '@/models/Utils.js'

const getIntercomElement = () =>  document.querySelector('.intercom-launcher');
const getIntercomElementFrame = () => document.querySelector('.intercom-launcher-frame');

const setVisibility = (element, collidingKey, visibility) => {
  if(!element) return;
  element.visibilityControll = element.visibilityControll || {};
  element.visibilityControll[collidingKey] = visibility;
  element.style.visibility = Object.values(element.visibilityControll).includes('hidden') ? 'hidden' : 'visible'
};

Vue.directive('preventIntercomOverflow', {
  bind: (element) => {
    let intercomElement;
    element.collidingKey = Math.floor(Math.random() * 10000000);
    element.collidingInterval = setInterval(_ => {
      intercomElement = getIntercomElementFrame() || intercomElement || getIntercomElement();

      if(isElementsColliding(element, intercomElement)) {
        setVisibility(intercomElement, element.collidingKey, 'hidden');
      } else {
        setVisibility(intercomElement, element.collidingKey, 'visible');
      }
    }, 1000);
  },
  unbind: (element) => {
    clearInterval(element.collidingInterval);
    setVisibility(getIntercomElement() ? getIntercomElement() : getIntercomElementFrame(), element.collidingKey, 'visible');
  }
});
