import state from './state';
import getters from './getters';
import mutations from './mutations';

export { state };
export { getters };
export { mutations };

const namespaced = true;

export default {
  namespaced,
  state,
  getters,
  mutations,
};
