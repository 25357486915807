import Vue from 'vue';

export default {
  index: (template = false) => Vue.axios.get('reports', { params: { template } }),
  show: id => Vue.axios.get(`reports/${id}`),
  create: (name, pagesHTML, pagesImage) =>
    Vue.axios.post('reports', { name, pagesHTML, pagesImage }),
  update: (id, name, pagesHTML, pagesImage) =>
    Vue.axios.put(`reports/${id}`, { name, pagesHTML, pagesImage }),
  destroy: id => Vue.axios.delete(`reports/${id}`),
  share: ({ id, subject, receivers }) =>
    Vue.axios.put(`reports/${id}/share`, { subject, receivers }),
  download: id => Vue.axios.get(`reports/${id}/download`),
};
