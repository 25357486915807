import store from '@/store';
import router from '@/router';
import ErrorResponse from './ErrorResponse';
import I18n from '@/i18n.js';
import {has} from "lodash";

export default {
  success: response => response.data,
  error: error => {
    let response = new ErrorResponse(error.response);

    if (router.history.current.name != 'login' && (response.isUnauthorized() || response.isServiceUnavailable())) {
      store.commit('logout', undefined);
      router.push({ name: 'login', params: { isServiceUnavailable: response.isServiceUnavailable() }});
    }

    if (
      router.history.current.name != 'login' &&
      response.isForbidden() &&
      has(response, "data.error.code") &&
      response.data.error.code === "policy_forbidden"
      ) {
      router.push({ name: 'home', params: { snackbarMessage: I18n.t('general.pricing.unauthorizedFeatureRoute') }}).catch(err => {});
    }

    return Promise.reject(response.getMessage());
  },
};
