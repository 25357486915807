import { find, map } from 'lodash';
import state from './state';

export default {
  period: state => Object.assign({ startAt: state.startAt, endAt: state.endAt }),
  members: state => Object.assign({ members: state.members }),
  labels: state => Object.assign({ labels: state.labels }),
  projects: state => Object.assign({ projects: state.projects }),
  services: state => Object.assign({ services: state.services }),
  floors: state => Object.assign({ floors: state.floors }),
  stepPositions: state => (state.steps ? map(state.steps, 'position') : []),
  firstStep: (state, getters) => {
    return state.steps
      ? find(state.steps, { position: Math.min(...getters.stepPositions) })
      : undefined;
  },
  lastStep: (state, getters) => {
    return state.steps
      ? find(state.steps, { position: Math.max(...getters.stepPositions) })
      : undefined;
  },
  canMoveForward: (state, getters) => {
    return function(stepId) {
      const step = find(state.steps, { id: stepId });
      return getters.lastStep.id != step.id;
    };
  },
  canMoveBackward: (state, getters) => {
    return function(stepId) {
      const step = find(state.steps, { id: stepId });
      return getters.firstStep.id != step.id;
    };
  },
  preparedFilters: state => {
    let projectsId = (state.projects || []).map(({ id }) => ({ id })) || undefined;
    let membersId = (state.members || []).map(({ id }) => ({ id })) || undefined;
    let labelsId = (state.labels || []).map(({ id }) => ({ id })) || undefined;
    let floorsId = (state.floors || []).map(({ id }) => ({ id })) || undefined;
    let servicesId = (state.services || []).map(({ id }) => ({ id })) || undefined;
    let title = state.title || undefined;
    let taskStatus = state.taskStatus || undefined;

    return {
      projects: projectsId,
      members: membersId,
      labels: labelsId,
      floors: floorsId,
      services: servicesId,
      period: state.endAt && state.startAt ? { max: state.endAt, min: state.startAt } : undefined,
      title: title,
      taskStatus: taskStatus,
    };
  },
  massAction: state => {
    return state.massAction;
  },
  selectedSteps: state => {
    return state.selectedSteps
  },
  selectedTasks: state => {
    return state.selectedTasks
  },
  isTaskStatusFilterActive: state => state.taskStatus != undefined,
  isTitleFilterActive: state => state.title != undefined,
  isMemberFilterActive: state => state.members.length != 0,
  isLabelsFilterActive: state => state.labels.length != 0,
  isProjectsFilterActive: state => state.projects.length != 0,
  isFloorsFilterActive: state => state.floors.length != 0,
  isServicesFilterActive: state => state.services.length != 0,
  isFilterActive: (_, getters) =>
    getters.isTaskStatusFilterActive ||
    getters.isTitleFilterActive ||
    getters.isMemberFilterActive ||
    getters.isLabelsFilterActive ||
    getters.isProjectsFilterActive ||
    getters.isFloorsFilterActive ||
    getters.isServicesFilterActive,
};
