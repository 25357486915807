import forge from 'node-forge';
import { Buffer } from 'buffer';

export default {
  encrypt: data => {
    const pubKey = Buffer.from(import.meta.env.VITE_BACKEND_PUBKEY, 'base64').toString('utf8');

    const publicKey = forge.pki.publicKeyFromPem(pubKey);
    const encryptKey = publicKey.encrypt(Buffer.from(data, 'utf8'), 'RSAES-PKCS1-V1_5');
    return Buffer.from(encryptKey, 'binary')
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  },
};
