import Vue from 'vue';

Vue.filter('dateAndTime', function(value) {
  if (!value) return undefined;

  const timeOptions = { hour: '2-digit', minute:'2-digit' }

  const brazilianTime = new Date(Date.parse(value)).toLocaleTimeString('pt-BR', timeOptions);
  const brazilianDate = new Date(Date.parse(value)).toLocaleDateString('pt-BR');
  const formattedDateAndTime = brazilianDate + ' - ' + brazilianTime + 'h';

  return value instanceof Date ? value : formattedDateAndTime;
});
