import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const namespaced = true;

export default {
  namespaced,
  state() {
    return {
      itemParseFunction: undefined,
      item: undefined,
      isLoading: undefined,
      isLoaded: undefined,
    };
  },
  actions,
  getters,
  mutations,
};
