import Vue from 'vue';

let perform = function(el, binding) {
  const config = binding.value || {};
  if (config.disabled) return;

  const clientHeight =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const bottomMargin = config.bottom || 30;
  const bounds = el.getBoundingClientRect();
  el.style.maxHeight = `${config.maxHeight || clientHeight - bounds.y - bottomMargin}px`;
  el.style.overflowY = 'auto';
  el.style.overflowX = 'hidden';
};

function onElementHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight,
    newHeight;
  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight != newHeight) {
      callback();
    }

    lastHeight = newHeight;
    if (elm.onElementHeightChangeTimer) {
      clearTimeout(elm.onElementHeightChangeTimer);
    }

    elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}

Vue.directive('max-height-scroll', {
  bind: function(el, binding) {
    if (el.clientHeight == 0) {
      onElementHeightChange(el, _ => perform(el, binding));
    } else {
      perform(el, binding);
    }
  },
  update: perform,
});
