import i18n from '@/i18n.js';
import store from '@/store.js'

export function setupIntercom(company, user) {
  if (!import.meta.env.PROD) return;

  const extraFeatureNames = store.getters['pricing/extraFeatureNames'].join(', ');

  window.Intercom("boot", {
    app_id: 't5zko0wg',
    user_hash: user.intercomKey,
    name: user.profile.name,
    construction_user_id: user.id,
    job: i18n.t(`profileJobs.${user.profile.job}`, company.locale),
    department: i18n.t(`profileDepartments.${user.profile.department}`, company.locale),
    phone: user.profile.phone,
    email: user.email,
    construction_company_id: user.companyId,
    company_name: company.name,
    company_max_constructions: company.subscriptionPlan.maxConstructions,
    company_max_pre_constructions: company.subscriptionPlan.maxPreConstructions,
    company_max_pre_executions: company.subscriptionPlan.maxPreExecutions,
    company_max_incorporations: company.subscriptionPlan.maxIncorporations,
    company_max_gantt_constructions: company.subscriptionPlan.maxGanttConstructions,
    company_has_active_gantt_construction: company.subscriptionPlan.activeGanttConstruction,
    construction_plan: store.getters['pricing/planName'],
    construction_company_campaign: company.campaign,
    construction_company_extra_features: extraFeatureNames,
    construction_company_subscription_status: company.status,
    created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
    avatar: {
      type: 'avatar',
      image_url: user.profile.pictureUrl
    }
  });
}
