import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as pt from '@/locales/pt-BR.json';
import * as en from '@/locales/en-US.json';

Vue.use(VueI18n);

// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
//   const messages = {};
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = { ...messages[locale], ...locales(key) };
//     }
//   });
//   return messages;
// }

export default new VueI18n({
  locale: localStorage.getItem('locale') || 'pt-BR',
  fallbackLocale: 'pt-BR',
  messages: {
    'pt-BR': pt,
    'en-US': en,
  },
});
