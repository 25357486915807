import CookieStorage from '@/client/CookieStorage.js';
import axios from 'axios';

const cookieStorage = new CookieStorage();

export default {
  post: async (gqlQuery, body = {}) => {
    const TOKEN_ID_STORAGE_KEY = '_ust_';
    const encodedTokenIdString = cookieStorage.getItem(TOKEN_ID_STORAGE_KEY);
    const { token } = encodedTokenIdString ? JSON.parse(atob(encodedTokenIdString)) : {};

    const config = {
      headers: {
        Accept: 'application/json',
        userAuthorization: `token ${token}`,
        'Content-Type': 'application/json',
      },
    };

    if (token === undefined) return;

    const response = await axios.post(
      import.meta.env.VITE_GRAPHQL_HTTP,
      { query: gqlQuery.loc.source.body, ...body },
      config,
    );

    return response.data;
  },
};
