import { thisNextMonths } from '@/models/DateRange.js';
let dateInterval = thisNextMonths(2);

export default {
  projectIds: [],
  startAt: dateInterval.startAt,
  endAt: dateInterval.endAt,
  userIds: [],
  labelIds: [],
  printMode: false,
};
