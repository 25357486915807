import store from '@/store';
import AzureAuthService from "@/services/login/AzureAuthService"
import i18n from '@/i18n.js';

export default async function azureRefreshTokenFlow({ next }) {
  if (!isUsingAzureProvider()) return next();

  const azureAuthService = new AzureAuthService();
  const token = await azureAuthService.silentFlow(userEmail());

  if (!token) {
    store.commit("logout", undefined);
    return next({name: "login", params: { snackbarMessage: snackbarMessage() } });
  }

  return next();
}

function isUsingAzureProvider() {
  return store.state['ssoProvider'] === "azure"
}

function userEmail() {
  return store.getters['currentUser']?.email || store.getters["loginEmail"]
}

function snackbarMessage() {
  return {
    timeout: 6000,
    message: i18n.t("general.ssoLogin.error.azure.unableToRefresh") ,
    color: "error"
  }
}
