import i18n from '@/i18n.js';

function permissions() {
  return [
    {
      value: 'admin',
      label: i18n.t('components.membersDataTable.permissions.admin')
    },
    {
      value: 'normal',
      label: i18n.t('components.membersDataTable.permissions.normal')
    },
    {
      value: 'only_measurement',
      label: i18n.t('components.membersDataTable.permissions.only_measurement'),
    },
    {
      value: 'only_read',
      label: i18n.t('components.membersDataTable.permissions.only_read')
    },
  ];
}
function incorporationPermissions() {
  return [
    {
      label: i18n.t('components.membersDataTable.permissions.admin'),
      value: 'admin'
    },
    {
      label: i18n.t('components.membersDataTable.permissions.normal'),
      value: 'normal'
    },
    {
      label: i18n.t('components.membersDataTable.permissions.guest'),
      value: 'guest'
    },
    {
      label: i18n.t('components.membersDataTable.permissions.no_access'),
      value: 'no_access'
    },
    {
      value: 'only_read',
      label: i18n.t('components.membersDataTable.permissions.only_read')
    },
  ];
}

const PERMISSION_HELPER = {
  DEFAULT_CONFIG: { only: ['admin', 'normal'], except: [], behavior: 'hide' },
  includes: (array, permission) => {
    return (array || []).includes(permission);
  },
  show: el => {
    el.classList.remove('d-none');
  },
  hide: el => {
    el.classList.add('d-none');
  },
  emitUnpermitted: vnode => {
    if (vnode.componentInstance) {
      vnode.componentInstance.$emit('unpermitted');
    }
  },
};

const validatePermission = (userPermission, permissionConfig) => {
  let config = permissionConfig ? permissionConfig : PERMISSION_HELPER.DEFAULT_CONFIG;

  let hasExceptions = (config.except || []).length > 0;

  if (hasExceptions) {
    var userInExceptions = PERMISSION_HELPER.includes(config.except, userPermission);
  }
  let userInUniquesList = PERMISSION_HELPER.includes(config.only, userPermission);

  return (hasExceptions && userInExceptions === false) || userInUniquesList;
};

const handleAuthorization = (config, el, vnode) => {
  if (!config.hasAuthorization) {
    if (config.behavior == 'hide') {
      PERMISSION_HELPER.emitUnpermitted(vnode);
    }
  } else {
    PERMISSION_HELPER.show(el);
  }
};

export { permissions, incorporationPermissions, validatePermission, handleAuthorization, PERMISSION_HELPER };
