export default {
  startAt: undefined,
  endAt: undefined,
  members: [],
  labels: [],
  projects: [],
  steps: [],
  services: [],
  floors: [],
  firstStep: undefined,
  lastStep: undefined,
  title: undefined,
  taskStatus: undefined,
  massAction: undefined,
  selectedSteps: [],
  selectedTasks: [],
};
