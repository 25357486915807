import WebsocketOrchestrator from '@/models/WebsocketOrchestrator.js';

export default {
  websocketOrchestrator: new WebsocketOrchestrator(),
  cable: undefined,
  disconnected: undefined,
  changesChannel: undefined,
  user: undefined,
  token: undefined,
  userId: undefined,
  companyId: undefined,
  projects: undefined,
  selectedProjectId: undefined,
  releasedRollouts: undefined,
  generalPhase: undefined,
  ssoProvider: undefined,
}
