mutation Undo($projectId: ID!) {
  undo(projectId: $projectId) {
    channel
    body {
      typeName
      rejectKeys
      methodName
      changes
    }
  }
}
