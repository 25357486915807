export default function redirectToMobileAppStorePage(from, to, next) {
  let userAgent = navigator.userAgent.toLocaleLowerCase();

  if (userAgent.includes('android'))
    return window.location.replace(
      'https://play.google.com/store/apps/details?id=br.prevision.main',
    );

  if (userAgent.includes('iphone') || userAgent.includes('ipad'))
    return window.location.replace(
      'https://apps.apple.com/us/app/prevision-sua-estrat%C3%A9gia/id1540964423',
    );

  next({ path: '/' });
}
