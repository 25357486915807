import Cookies from 'js-cookie';

export default class CookieStorage {
  constructor() {
    this.client = Cookies;
    this.extraInfo = import.meta.env.PROD ? { domain: '.prevision.com.br', secure: true } : {};
  }

  getItem(key) {
    return this.client.get(key);
  }

  removeItem(key) {
    this.client.remove(key, this.extraInfo);
  }

  setItem(key, value, expiresAt) {
    this.client.set(key, value, { expires: expiresAt, ...this.extraInfo });
  }
}
