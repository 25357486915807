import StartQualitySyncTaskMutation from '@/graphql/mutations/integrations/quality/StartQualitySyncTaskMutation.gql';
import QualityIntegrationConfig from '@/graphql/query/integration/quality/QualityIntegrationConfig.gql';
import PricingFeaturesService from '@/services/PricingFeaturesService';
import PricingFeatures from '@/models/PricingFeatures.js';
import AxiosGqlService from '@/services/AxiosGqlService';
import { get, isEqual } from 'lodash';

async function getQualityConfig({rootGetters, rootState}) {
  if(await isQualityIntegrationUnauthorized(rootGetters)) return undefined;

  const { companyId } = rootState;
  const response = await AxiosGqlService.post(QualityIntegrationConfig, {variables: {companyId}});

  return get(response, "data.me.qualityIntegrationConfig");
}

async function isQualityIntegrationUnauthorized(rootGetters) {
  const featureKeys = !!rootGetters["pricing/allFeaturesKeys"].length
    ? rootGetters["pricing/allFeaturesKeys"]
    : await PricingFeaturesService.getAllFeatureKeys();

  return !featureKeys.includes(PricingFeatures.qualityIntegration.key);
}

export default {
  loadInspections: async ({ rootState, commit, state }, itemsToLoad) => {
    return new Promise(async (resolve, reject) => {
      try {
        const projectId = rootState.selectedProjectId;

        let response = await AxiosGqlService.post(StartQualitySyncTaskMutation, {
          variables: {
            projectId: parseInt(projectId),
            partner: state.qualityConfig.partner,
            itemsToLoad: itemsToLoad,
          },
        });

        commit('setInspections', response?.data?.startQualitySyncTask);

        resolve(state.inspections);
      } catch (error) {
        commit('setInspections', []);

        reject(error);
      }
    });
  },
  loadQualityConfig: context => {
    return new Promise(async (resolve, reject) => {
      try {
        context.commit('setLoading', true);

        const qualityConfig = await getQualityConfig(context);

        context.commit('setHasQualityConfig', !!qualityConfig);

        if (!isEqual(context.state.qualityConfig, qualityConfig)) {
          context.commit('setQualityConfig', qualityConfig);
        }

        resolve(context.state.qualityConfig);
      } catch (error) {
        reject(error);
      } finally {
        context.commit('setLoading', false);
      }
    });
  },
  setupQualityIntegrationConfigChangesChannel(context) {
    let cableSubscriptions = context.rootState.cable.subscriptions;

    if (context.state.qualityIntegrationChangesChannel) {
      cableSubscriptions.remove(context.state.qualityIntegrationChangesChannel);
    }

    let channel = {
      id: `changes_of_company_quality_integration_config_${context.rootState.companyId}`,
      channel: 'QualityIntegrationConfigChangesChannel',
      company_id: context.rootState.companyId,
    };

    context.state.qualityIntegrationChangesChannel = cableSubscriptions.create(channel, {
      received: _ => {
        context.dispatch('loadQualityConfig');
      },
    });
  },
};
