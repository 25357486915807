import i18n from '@/i18n.js';
import store from '@/store.js'

export function setupFreshdesk(company, user) {
  if (!import.meta.env.PROD) return;

  const extraFeatureNames = store.getters['pricing/extraFeatureNames'].join(', ');

  window.fcInitPromise.then(() => {
    window.fcWidget.setExternalId(user.email); // Email is unique within platforms
    window.fcWidget.user.setFirstName(user.profile.name);
    window.fcWidget.user.setEmail(user.email);

    window.fcWidget.user.setProperties({
      mobile_number: user.profile.phone,
      cf_construction_user_id: user.id,
      cf_job: i18n.t(`profileJobs.${user.profile.job}`, company.locale),
      cf_department: i18n.t(`profileDepartments.${user.profile.department}`, company.locale),
      cf_construction_company_id: user.companyId,
      cf_company_name: company.name,
      cf_company_max_constructions: company.subscriptionPlan.maxConstructions,
      cf_construction_plan: store.getters['pricing/planName'],
      cf_construction_company_extra_features: extraFeatureNames,
      cf_construction_company_subscription_status: company.status,
    });
  }).catch((error) => {
    console.error("Filed to load fresh chat: ", error);
  });
}
