export default {
  start: undefined,
  end: undefined,
  selectedWorkdays: [],
  customWorkdays: undefined,
  holidays: [],
  projectId: undefined,
  nationalHolidaysSelection: undefined,
  customHolidays: undefined,
  isEditing: undefined,
};
