export default {
  setProjectId(state, projectId) {
    state.projectId = projectId
  },
  clearProjectRelatedFields(state) {
    state.floorIds = []
    state.workPackageIds = []
    state.responsibleNames = []
    state.withoutResponsible = false
    state.floorFormField = []
  },
  applyForm(state, form) {
    state.startAt = form.datesRange.startAt;
    state.endAt = form.datesRange.endAt;
    state.floorIds = form.floorIds;
    state.workPackageIds = form.workPackageIds;
    state.responsibleNames = form.responsibleNames;
    state.withoutResponsible = form.withoutResponsible;
    state.startOfWeek = form.startOfWeek;
    state.floorFormField = form.floorFormField;
  },
  setPrintMode(state) {
    state.printMode = !state.printMode;
  },
};
