export default {
  setProjects: (state, value) => state.projects = value,
  setDashboardFilters: (state, value) => state.dashboardFilters = value,
  addToDashboardFilters: (state, value) => state.dashboardFilters = {...state.dashboardFilters, ...value},
  setDashboardPage: (state, value) => state.dashboardPage = value,
  setEconomicalDashboardCategory: (state, value) => state.economicalDashboardCategory = value,
  setIsFilterActive: (state, value) => state.isFilterActive = value,
  setFilteredFloorsSize: (state, value) => state.filteredFloorsSize = value,
  setFilteredServicesSize: (state, value) => state.filteredServicesSize = value,
  setCustomFilter: (state, value) => state.selectedCustomFilter = value,
}
