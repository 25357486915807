import Vue from 'vue';
import { apolloClient } from "@/vue-apollo";
import ExportMeasurementsDataMutation from "@/components/measurements/mutations/exportMeasurementsData.gql";

export default {
  index: projectId => Vue.axios.get(`projects/${projectId}/deadline_measurement_tasks`),
  show: (projectId, taskId) =>
    Vue.axios.get(`projects/${projectId}/deadline_measurement_tasks/${taskId}`),
  new: (projectId, measuredIn) => {
    return Vue.axios.get(`projects/${projectId}/deadline_measurement_tasks/new`, {
      params: { measuredIn },
    });
  },
  expected: (projectId, measuredIn, serviceId, source) => {
    const params = `?measured_in=${measuredIn}&service_id=${serviceId}`;
    return Vue.axios.get(`projects/${projectId}/deadline_measurement_tasks/expected${params}`,
      {
        cancelToken: source.token
      });
  },
  createOrUpdate: (projectId, measuredIn, measurements) => {
    return Vue.axios.put(`projects/${projectId}/deadline_measurement_tasks/create_or_save`, {
      measured_in: measuredIn,
      measurements: measurements,
    });
  },
  destroy: (projectId, taskId) =>
    Vue.axios.delete(`projects/${projectId}/deadline_measurement_tasks/${taskId}`),
  export: (projectId, taskId) =>
    Vue.axios.get(`projects/${projectId}/deadline_measurement_tasks/${taskId}/xlsx`),
  exportMeasurementInfographic(projectId, taskId, groupBy) {
    return Vue.axios.get(`projects/${projectId}/deadline_measurement_tasks/${taskId}/pdf`, {
      params: { groupBy },
    });
  },
  exportMeasurementsData: (projectId, measurementTaskId) => {
    return apolloClient.mutate({
      mutation: ExportMeasurementsDataMutation,
      variables: { projectId, measurementTaskId }
    })
  }
};
