import isMobile from '@/models/MobileChecker';

const routes = [
  {
    path: 'budget/:budgetId?',
    name: 'budget',
    component: () => import('@/views/budget/BudgetReport.vue'),
    meta: {
      isProjectScoped: true,
      withoutPadding: true,
      featureKey: 'budget:budget_or_financial',
    },
    beforeEnter(to, from, next) {
      if (isMobile()) {
        next({ name: 'dashboard' });
      } else {
        next();
      }
    },
  },
];

export default routes;
