import Vue from 'vue';

export default {
  index: projectId => Vue.axios.get(`projects/${projectId}/services`),
  create: (projectId, service) => Vue.axios.post(`projects/${projectId}/services`, service),
  bulkCreate: (projectId, services) =>
    Vue.axios.post(`projects/${projectId}/services/bulk_create`, { services }),
  activitiesAmount: (projectId, services) =>
    Vue.axios.post(`projects/${projectId}/services/activities_amount`, services),
  update: (projectId, service) =>
    Vue.axios.put(`projects/${projectId}/services/${service.id}`, service),
  destroy: (projectId, service) => Vue.axios.delete(`projects/${projectId}/services/${service.id}`),
};
