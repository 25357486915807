import i18n from '@/i18n.js';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

const getSessionStorageNamespace = (key) =>{
  const nameSpace =  window.location.pathname.replace('app', 'Prevision').replaceAll('/', ':').substring(1).concat(':');
  return nameSpace + key;
}

export const safeStringfy = object => JSON.stringify(object, getCircularReplacer());

export const removeAccents = string => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const containsOnlySpaces = string => {
  return /^[ ]+$/.test(string);
}

export const removeExtraEmptySpace = string => {
  return string.replace(/\s+/g, ' ').trim()
}

export const toSnakeCase = string => {
  return string.replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
};

export const getNestedObject = (nestedObj, path) => {
  return path
    .split('.')
    .reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
};

export const copyClassObject = object => {
  return Object.assign(Object.create(Object.getPrototypeOf(object)), object);
};

export const getMostVisibleElement = selector => {
  let clientRect = null;
  let clientRectTop = 0;
  let maxVisibleHeight = 0;
  let visibleHeightOfElem = 0;
  let mostVisibleElement = null;
  let skipRest = false;
  let elements = document.querySelectorAll(selector);

  for (let element of elements) {
    if (skipRest === false) {
      clientRect = element.getBoundingClientRect();
      clientRectTop = Math.abs(clientRect.top);

      if (clientRect.top >= 0) {
        visibleHeightOfElem = window.innerHeight - clientRectTop;
      } else {
        visibleHeightOfElem = clientRect.height - clientRectTop;
      }
      if (visibleHeightOfElem >= clientRect.height) {
        mostVisibleElement = element;
        skipRest = true;
      } else if (visibleHeightOfElem > maxVisibleHeight) {
        maxVisibleHeight = visibleHeightOfElem;
        mostVisibleElement = element;
      }
    }
  }
  return mostVisibleElement;
};

export const sortElementsBy = (arg, parentSelector, childrensSelector, order) => {
  let parent = document.querySelector(parentSelector);
  var childrens = parent.querySelectorAll(childrensSelector);
  let sortedChildrens = [].slice.call(childrens).sort(function(a, b) {
    var an = parseInt(a.getAttribute(arg)),
      bn = parseInt(b.getAttribute(arg));
    if (order == 'asc') {
      if (an > bn) {
        return 1;
      } else if (an < bn) {
        return -1;
      }
    } else if (order == 'desc') {
      if (an < bn) {
        return 1;
      } else if (an > bn) {
        return -1;
      }
    }
    return 0;
  });
  sortedChildrens.forEach(function(children) {
    parent.appendChild(children);
  });
};

export const getComputedTranslateXY = obj => {
  const transforms = [];
  if (!window.getComputedStyle) return;
  const style = getComputedStyle(obj),
    transform = style.transform || style.webkitTransform || style.mozTransform;
  let mat = transform.match(/^matrix3d\((.+)\)$/);
  if (mat) return parseFloat(mat[1].split(', ')[13]);
  mat = transform.match(/^matrix\((.+)\)$/);
  mat ? transforms.push(parseFloat(mat[1].split(', ')[4])) : 0;
  mat ? transforms.push(parseFloat(mat[1].split(', ')[5])) : 0;

  return transforms;
};

export const copyToClipboard = str => {
  navigator.clipboard.writeText(str);
};

export const createTmpId = () => {
  return Math.floor(Math.random() * 10000);
};

export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const setI18nAtInnerHtmlById = (elementId, i18nPathKey) => {
  let el = document.getElementById(elementId);
  if (el == null) {
    console.log(`${i18n.t("messages.elementDoesNotExistWithThisKey")} key=${elementId}`);
    return;
  }
  el.innerHTML = i18n.t(i18nPathKey);
}

export const isElementsColliding = (el1, el2) => {
  if(!el1 || !el2) return false;

  var rect1 = el1.getBoundingClientRect();
  var rect2 = el2.getBoundingClientRect();

  return !( rect1.right < rect2.left ||
            rect1.left > rect2.right ||
            rect1.bottom < rect2.top ||
            rect1.top > rect2.bottom)
}

export const setSessionStorage = (key,value) => {
  const storageKey = getSessionStorageNamespace(key);
  const convertedValue = JSON.stringify(value);

  sessionStorage.setItem(storageKey, convertedValue)
}

export const getSessionStorage = (key) => {
  const storageKey = getSessionStorageNamespace(key);

  return JSON.parse(sessionStorage.getItem(storageKey));
}

export const deleteSessionStorage = (key) => {
  const storageKey = getSessionStorageNamespace(key);

  sessionStorage.removeItem(storageKey);
}

export const scrollToPageTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

export const overrideArrayItemsById = (outdatedItems, updatedItems) =>{
  let updatedItemsById = (updatedItems || []).reduce((hash, item) => {
    hash[item.id] = item;
    return hash;
  }, {});
  return outdatedItems.map(outdatedItem =>
    Object.assign(outdatedItem, updatedItemsById[outdatedItem.id] || {}),
  );
}

export const extractId = (array) => {
  return array.map(value => {
    if (!value) return;
    return value.id;
  });
}

export const sanitizeHtml = (text) => {
  try {
    var div = document.createElement("div");
    div.innerHTML = text;
    return div.textContent || div.innerText || "";
  } catch(error) {
    console.error(error);
    return text;
  }
}

export const searchByTerm = (array, attribute, searchTerm) => {
  const term = prepareStringToSearch(searchTerm);
  return array.filter(array => prepareStringToSearch(array[attribute]).includes(term));
}

export const prepareStringToSearch = (string) => {
  return string ? removeAccents(String(string)).toLowerCase().trim() : "";
}

export const uniqArray = array => Array.from(new Set(array))

export default {
  safeStringfy,
  removeAccents,
  containsOnlySpaces,
  removeExtraEmptySpace,
  getNestedObject,
  copyClassObject,
  getMostVisibleElement,
  sortElementsBy,
  getComputedTranslateXY,
  copyToClipboard,
  createTmpId,
  blobToBase64,
  setI18nAtInnerHtmlById,
  toSnakeCase,
  isElementsColliding,
  setSessionStorage,
  getSessionStorage,
  deleteSessionStorage,
  overrideArrayItemsById,
  scrollToPageTop,
  extractId,
  sanitizeHtml,
  uniqArray
};
