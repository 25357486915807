import { thisNextMonths } from '@/models/DateRange.js';
let dateInterval = thisNextMonths(2);

export default {
  projectId: null,
  startAt: dateInterval.startAt,
  endAt: dateInterval.endAt,
  floorIds: [],
  workPackageIds: [],
  printMode: false,
};
