import Vue from 'vue';
import './models/prototypes/Object';
import './models/prototypes/Number';
import './models/prototypes/Date';
import './models/prototypes/Math';
import './models/prototypes/Array';
import './models/prototypes/String';
import './plugins/axios/axios';
import './plugins/libs';
import './directives/index';
import './filters/index';

import './plugins/sentry';
import './plugins/amplitude';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { createProvider } from './vue-apollo';

Vue.config.productionTip = false;

export default new Vue({
  vuetify,
  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app');
