'use strict';
const colors = [
  '#000000',
  '#3D3D3D',
  '#32373A',
  '#28353D',
  '#1C303E',
  '#07293F',
  '#01263F',
  '#40505B',
  '#29475A',
  '#1B425C',
  '#02375A',
  '#285777',
  '#0A4C77',
  '#055589',
  '#206D9F',
  '#0867A4',
  '#0673BA',
  '#2C65FB',
  '#2A5FEC',
  '#224DBF',
  '#193A92',
  '#0E204F',
  '#A45D5C',
  '#AB4646',
  '#B12726',
  '#BE0503',
  '#E70302',
  '#9D673F',
  '#52331B',
  '#4F2301',
  '#7A4E2D',
  '#A76330',
  '#BD5200',
  '#E66503',
  '#E85F08',
  '#FF6C0F',
  '#B18706',
  '#73772E',
  '#6E730E',
  '#727800',
  '#587832',
  '#52801D',
  '#4A810B',
  '#4F9002',
  '#274702',
  '#243A0B',
  '#348248',
  '#23843B',
  '#12862F',
  '#038723',
  '#03A12A',
  '#4A796F',
  '#35806F',
  '#20826C',
  '#018367',
  '#025241',
  '#0A322A',
  '#457986',
  '#387D8E',
  '#1D7A92',
  '#017E9E',
  '#5A4B66',
  '#574467',
  '#513567',
  '#4C2768',
  '#521E7B',
  '#540B8E',
  '#6806B5',
  '#8C0BF1',
  '#A338F8',
  '#AF46AF',
  '#C22CC2',
  '#CD11CD',
  '#910291',
  '#6A026A',
  '#702C47',
  '#841D47',
  '#961046',
  '#C50553',
  '#E4045F',
  '#973841',
  '#A21F2B',
  '#C30A1B',
  '#E4061B',
];

const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

const hexToRGB = (hex, alpha) => {
  if (!hex) return '';
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export { colors };

export { shadeColor };

export { hexToRGB };

export default {
  colors,
  shadeColor,
  hexToRGB,
};
