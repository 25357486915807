import Vue from 'vue';
import store from './store';
import Router from 'vue-router';
import budgetRoutes from './routes/BudgetReportRoutes';
import adminRoutes from './routes/AdminRoutes';
import reportsRoutes from './routes/ReportsRoutes';
import qualityIntegrationRoutes from './routes/QualityIntegrationRoutes';
import contractsRoutes from '@/routes/ContractsRoutes';
import UsersService from './services/UsersService';
import i18n from '@/i18n.js';
import validateRouteAuthorization from '@/middlewares/routeAuthorization';
import {azureRefreshTokenFlow, dashboardPermission, generalPhase, pricing, trialExpired, trialPreload,} from '@/middlewares/modules';
import PricingFeatures from '@/models/PricingFeatures';
import redirectToMobileAppStorePage from '@/models/trial/MobileRedirection';
import {FeatureError} from '@/middlewares/errors/featureError';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return setTimeout(() => {
        const el = document.querySelector(to.hash);
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }, 1000);
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      component: () => import('./views/outside_app/OutsideBase.vue'),
      redirect: '/login',
      beforeEnter(to, from, next) {
        if (to.query.locale) {
          i18n.locale = to.query.locale;
        }
        next();
      },
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('./views/outside_app/Login.vue'),
        },
        {
          path: '/logout',
          name: 'logout',
          component: () => import('./views/outside_app/Logout.vue'),
        },
        {
          path: '/login-callback',
          component: () => import('./views/outside_app/LoginCallback.vue'),
        },
        {
          path: '/create-token',
          name: 'createRegisterToken',
          component: () => import('./views/outside_app/CreateRegisterToken.vue'),
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('./views/outside_app/Register.vue'),
        },
        {
          path: '/register-trial',
          name: 'registerTrial',
          component: () => import('./views/outside_app/RegisterTrial.vue'),
        },
        {
          path: '/request-password-change',
          name: 'requestPasswordChange',
          component: () => import('./views/outside_app/RequestPasswordChange.vue'),
        },
        {
          path: '/change-password',
          name: 'changePassword',
          component: () => import('./views/outside_app/ChangePassword.vue'),
        },
        {
          path: '/mobile-app',
          name: 'mobileApp',
          beforeEnter(from, to, next) {
            redirectToMobileAppStorePage(from, to, next);
          },
        },
        {
          path: 'trial-preload',
          name: 'trialPreload',
          component: () => import('@/views/outside_app/trial/TrialPreload.vue'),
          meta: {
            middleware: [trialPreload],
          },
        },
      ],
    },
    {
      path: '/onboarding',
      name: 'trialOnboarding',
      component: () => import('@/views/outside_app/trial/onboarding/Onboarding.vue'),
      beforeEnter(to, from, next) {
        if (!store.state.userId) return next({ name: 'login' });

        UsersService.validateSession(store.state.userId).then(user => {
          store.dispatch('setCurrentUser', user);
          next();
        });
      },
    },
    {
      path: '/app',
      component: () => import('./views/Home.vue'),
      beforeEnter(to, from, next) {
        if (!store.state.userId) return next({ name: 'login' });

        UsersService.validateSession(store.state.userId).then(user => {
          store.dispatch('setCurrentUser', user);
          if (to.meta.isProjectScoped) {
            store.state.selectedProjectId ? next() : next({ name: 'projects' });
          } else {
            next();
          }
        });
      },
      meta: {
        middleware: [pricing, trialExpired, generalPhase, azureRefreshTokenFlow],
      },
      children: [
        ...adminRoutes,
        ...budgetRoutes,
        ...reportsRoutes,
        ...qualityIntegrationRoutes,
        ...contractsRoutes,
        {
          path: 'home/:phase?',
          name: 'home',
          component: () => import('@/views/home/HomePage.vue'),
          meta: {
            withoutPadding: true,
            availableWithoutGeneralPhase: true,
          },
          beforeEnter(to, from, next) {
            const possiblePhases = ['pre_construction', 'pre_execution', 'construction'];
            const phase = to.params.phase;

            if (phase && possiblePhases.includes(phase)) {
              store.dispatch('setGeneralPhase', phase);
              return next({ name: 'projects' });
            }
            next();
          },
        },
        {
          path: 'settings/:tab?',
          name: 'settings',
          component: () => import('./views/TabbedBase.vue'),
          meta: {
            topNavbarExtension: () => import('./components/TopNavbarExtension.vue'),
            tabs: [
              {
                id: 'general',
                i18n: 'general.general',
                icon: 'mdi-cog-outline',
                component: () => import('./views/settings/GeneralTab.vue'),
              },
              {
                id: 'integration',
                i18n: 'general.integrations',
                icon: 'mdi-puzzle-outline',
                allowedPermission: 'normal',
                featureKey: [
                  PricingFeatures.integrations.key,
                  PricingFeatures.qualityIntegration.key,
                ],
                component: () => import('./views/settings/IntegrationTab.vue'),
              },
              {
                id: 'notification',
                i18n: 'general.notificationSubjectConfigurations',
                icon: 'mdi-bell-outline',
                component: () =>
                  import('./views/settings/NotificationSubjectConfigurationsTab.vue'),
              }
            ],
          },
        },
        {
          path: 'projects',
          name: 'projects',
          component: () => import('./views/projects/Projects.vue'),
          meta: {
            topNavbarExtension: () => import('./components/toolbars/ProjectFilterableToolbar.vue'),
          },
          beforeEnter(to, from, next) {
            to.meta.previousRoutePath = from.path;
            next();
          },
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('./views/dashboard/Dashboard.vue'),
          meta: {
            topNavbarExtension: () => import('./views/dashboard/DashboardTopNavbarExtension'),
          },
          beforeEnter(to, from, next) {
            to.meta.previousRoutePath = from.path;
            next();
          },
        },
        {
          path: 'new-default-dashboard',
          name: 'dashboard',
          component: () => import('./features/default_dashboard/DefaultDashboard.vue'),
          meta: {
            isProjectScoped: false,
            withoutPadding: true,
            dashboardType: 'goal',
            dashboardToUpdate: 'short_term',
            isGanttAllowed: true,
          },
        },
        {
          path: 'new-dashboard',
          name: 'new-dashboard',
          component: () => import('./views/new_dashboard/NewDashboard.vue'),
        },
        {
          path: 'measurements',
          name: 'measurements',
          icon: 'mdi-card-bulleted-outline',
          component: _ => import('@/views/measurements/Measurements.vue'),
          redirect: { name: 'measurement' },
          meta: {
            isProjectScoped: true,
            withoutPadding: true,
          },
          children: [
            {
              path: 'measurement',
              name: 'measurement',
              component: () => import('@/views/measurements/tabs/MeasurementsTab.vue'),
              meta: {
                isProjectScoped: true,
                withoutPadding: true,
                sidebar: () =>
                  import('@/components/cards/measurements/sidebar/MeasurementsSideBar'),
              },
            },
            {
              path: 'infographic',
              name: 'infographic',
              component: () => import('@/views/measurements/tabs/MeasurementInfographicTab'),
              meta: {
                isProjectScoped: true,
                withoutPadding: true,
                sidebar: () =>
                  import(
                    '@/components/cards/measurement_infographic/MeasurementInfographicSideBar'
                  ),
              },
            },
          ],
        },
        {
          path: 'short-term-dashboard',
          name: 'short-term-dashboard',
          component: _ => import('@/features/short_term_dashboard/ShortTermDashboard.vue'),
          meta: {
            isProjectScoped: false,
            withoutPadding: true,
            featureKey: PricingFeatures.dashboardShortTerm.key,
            middleware: [dashboardPermission],
          },
        },
        {
          path: 'mid-term-dashboard',
          name: 'mid-term-dashboard',
          component: _ => import('@/views/dashboard/MidTermDashboard.vue'),
          meta: {
            isProjectScoped: false,
            withoutPadding: true,
            featureKey: PricingFeatures.dashboardMidTerm.key,
            middleware: [dashboardPermission],
            dashboardType: 'mid_term',
          },
        },
        {
          path: 'long-term-dashboard',
          name: 'long-term-dashboard',
          component: _ => import('@/features/long_term_dashboard/components/RealCostDashboard.vue'),
          meta: {
            isProjectScoped: false,
            withoutPadding: true,
            featureKey: PricingFeatures.dashboardLongTerm.key,
            middleware: [dashboardPermission],
            dashboardType: 'long_term',
          },
        },
        {
          path: 'goal-dashboard',
          name: 'goal-dashboard',
          component: _ => import('@/features/goal_dashboard/components/GoalDashboard.vue'),
          meta: {
            isProjectScoped: false,
            withoutPadding: true,
            dashboardType: 'goal',
            middleware: [dashboardPermission],
            dashboardToUpdate: 'short_term',
          },
        },
        {
          path: 'histogram-dashboard',
          name: 'histogram-dashboard',
          component: _ => import('@/views/dashboard/HistogramDashboard.vue'),
          meta: {
            isProjectScoped: false,
            withoutPadding: true,
            featureKey: PricingFeatures.dashboardHistogram.key,
          },
        },
        {
          path: 'calendar',
          name: 'calendar',
          component: () => import('./views/calendar/Calendar.vue'),
          meta: {
            topNavbarExtension: () => import('./views/calendar/CalendarTopNavbarExtension'),
            isProjectScoped: true,
          },
        },
        {
          path: 'schedule',
          name: 'schedule',
          component: () => import('./views/schedule/Schedule'),
          meta: {
            topNavbarExtension: () => import('./views/schedule/ScheduleTopNavbarExtension'),
            isProjectScoped: true,
          },
        },
        {
          path: 'gantt-schedule',
          name: 'gantt-schedule',
          component: () => import('./features/gantt/GanttSchedule.vue'),
          meta: {
            topNavbarExtension: () =>
              import('./features/gantt/GanttScheduleTopNavbarExtension.vue'),
            isProjectScoped: true,
          },
        },
        {
          path: 'macroflow',
          name: 'macroflow',
          component: () => import('./views/macroflow/Macroflow.vue'),
          meta: {
            topNavbarExtension: () => import('./views/macroflow/top_nav_bar/components/Body'),
            featureKey: PricingFeatures.macroflow.key,
          },
        },
        {
          path: 'constraints/:taskId?',
          name: 'kanban',
          icon: 'mdi-card-bulleted-outline',
          props: true,
          component: _ => import('@/views/kanban/Kanban.vue'),
          meta: {
            withoutPadding: true,
            featureKey: PricingFeatures.kanban.key,
            availableWithoutGeneralPhase: true,
          },
        },
        {
          path: 'trial-expired',
          name: 'trial-expired',
          component: () => import('@/views/trial/TrialExpired.vue'),
          meta: {
            availableWithoutGeneralPhase: true,
          },
        },
        {
          path: '/app*',
          redirect: { name: 'home' },
        },
      ],
    },
    {
      path: '*',
      component: () => import('./views/NotFound.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next, router) => {
  sessionStorage.setItem("autoPageReloadUrl", to.fullPath)

  if (to.matched.some(route => route.meta.middleware)) {
    await validateRouteAuthorization({ to, from, next, router });
  }

  if (store?.state?.releasedRollouts?.some(rollout => rollout === 'log:custom_profile_view')) {
    const permission = store.state.user.permission;

    if (permission !== 'admin' && to.fullPath.startsWith('/app/settings')) {
      return next(new FeatureError());
    }
  }

  return next();
});

router.onError(error => {
  console.log(error)
  // Caso seja um erro de load de chunk, recarrega a página para o cliente exatamente no erro.
  if(
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Unable to preload")
  ) {
    // Pega a url para aonde o cliente estava navegando antes do erro
    const to = sessionStorage.getItem("autoPageReloadUrl")
    if(!to) return window.location.reload(true);

    const url = new URL(window.location.origin + to)
    if (url.searchParams.has("vx")) window.location.reload(true);
    url.searchParams.append("vx", Date.now().toString().slice(-6))

    sessionStorage.removeItem("autoPageReloadUrl")
    window.location.href = url.toString()
  }else {
    router.push({ name: 'home', params: { snackbarMessage: error.message } });
  }
});

export default router;
