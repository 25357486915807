export default {
  setSelectedMacroflow: (state, macroflow) => {
    state.selectedMacroflow = macroflow
  },
  setNets: (state, nets) => {
    state.nets = nets
  },
  setSelectedNetId: (state, netId) => {
    state.selectedNetId = netId
  }
};
