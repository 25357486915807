import { differenceBy, map } from 'lodash';
import PricingFeatures from '@/models/PricingFeatures';
import PricingPlans from '@/models/PricingPlans';
import { removeAccents } from '@/models/Utils';
import i18n from '@/i18n.js';

export default {
  planName(state) {
    return state.plan?.name;
  },
  planId(state) {
    return state.plan?.id;
  },
  isGanttPlusPlan(state, getters) {
    const {ganttPlus} = PricingPlans;

    return getters.planId == ganttPlus.id;
  },
  allFeaturesKeys(state) {
    const planFeatureKeys = state.plan ? map(state.plan.features, 'key') : [];
    const extraFeatureKeys = map(state.extraFeatures, 'key') || [];

    return planFeatureKeys.concat(extraFeatureKeys);
  },
  extraFeatureKeys(state) {
    return map(state.extraFeatures, 'key') || [];
  },
  extraFeatureNames(state) {
    return map(state.extraFeatures, 'name') || [];
  },
  featuresOnPlanKeys(state) {
    return map(state.plan?.features, 'key') || [];
  },
  dashboardExtraFeatures(state) {
    return (state.extraFeatures || []).filter(extraFeature => {
      return extraFeature.key.includes('dashboard:');
    });
  },
  availableExtraFeatures(state) {
    const planFeatures = state.plan?.features || [];
    const allFeatures = Object.values(PricingFeatures);
    const availableFeatures = differenceBy(allFeatures, planFeatures, 'key');

    return availableFeatures.filter(({ key }) => !key.includes('term'));
  }
};
