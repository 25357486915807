import AxiosGqlService from '@/services/AxiosGqlService'
import FeaturesQuery from '@/graphql/query/company/FeaturesQuery.gql'
import {get} from 'lodash'
import Vue from "vue";

export default {
  getPlans: async function(params = {}) {
    return await Vue.axios.get("/plans", { params })
  },
  getCompanyFeatures: async function() {
    const response = await AxiosGqlService.post(FeaturesQuery)

    return {
      plan: get(response, "data.me.subscriptionPlan.plan", {}),
      extraFeatures: get(response, "data.me.subscriptionPlan.extraFeatures", [])
    };
  },
  getAllFeatureKeys: async function() {
    const {plan, extraFeatures} = await this.getCompanyFeatures()

    return (plan?.features || []).concat(extraFeatures).map(({key}) => key)
  }
}
