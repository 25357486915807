import isMobile from '@/models/MobileChecker';
import store from '@/store';

const routes = [
  {
    path: 'reports',
    name: 'reports',
    component: () => import('@/views/reports/Reports.vue'),
    meta: {
      topNavbarExtension: () => import('@/components/TopNavbarExtension.vue'),
      searchable: true,
      featureKey: 'report:reports',
      rollout: 'report:visibility'
    },
  },
  {
    path: 'report/:id?',
    name: 'report',
    component: () => import('@/views/reports/Report.vue'),
    meta: {
      topNavbarExtension: () => import('@/views/reports/ReportTopNavbar.vue'),
      searchable: false,
    },
    beforeEnter(to, from, next) {
      const disallowedPermissions = ['only_read', 'only_measurement']
      let userPermission = store.getters.currentUser.permission

      if (disallowedPermissions.includes(userPermission) || isMobile()) {
        next({ name: 'reports' });
      } else {
        next();
      }
    },
  },
];

export default routes;
