import i18n from '@/i18n.js';

class FeatureError extends Error {
  constructor(message = i18n.t('general.pricing.unauthorizedFeatureRoute')) {
    super(message);
    this.name = "FeatureError";
  }
}

export {
  FeatureError
}
