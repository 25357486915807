export class SessionStorageController {
  static get _defaultKeys() {
    return {
      PROJECT_ID: "prevision:selectedProjectId",
      COMPANY_ID: "prevision:companyId",
      GENERAL_PHASE: "prevision:generalPhase"
    }
  }

  static setValue({ key, value }) {
    if(!key) return;

    value
    ? sessionStorage.setItem(this._selectedKey(key), JSON.stringify(value))
    : sessionStorage.removeItem(this._selectedKey(key))
  }

  static getValue({ key }) {
    let value = sessionStorage.getItem(this._selectedKey(key));

    return JSON.parse(value);
  }

  static clearItem({ key }) {
    sessionStorage.removeItem(this._selectedKey(key))
  }

  static _selectedKey(key) {
    return this._defaultKeys[key] || key;
  }
}

export default {
  SessionStorageController,
}
