import Vue from 'vue';
import MugenScroll from 'vue-mugen-scroll';
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import highchartsNoData from 'highcharts/modules/no-data-to-display';
import highchartsMore from 'highcharts/highcharts-more';
import loadExporting from 'highcharts/modules/exporting';
import loadOffileExporting from 'highcharts/modules/offline-exporting';
import loadBoost from 'highcharts/modules/boost';

import loadExportData from 'highcharts/modules/export-data';
import VueStickyKit from 'vue-stickykit';
import VueObserveVisibility from 'vue-observe-visibility';
import observer from 'vue-mutation-observer';

import * as XLSX from 'xlsx';

import i18n from '@/i18n.js';

const locale = localStorage.getItem('locale') || 'pt-BR';

highchartsNoData(Highcharts);
highchartsMore(Highcharts);
loadExporting(Highcharts);
loadOffileExporting(Highcharts);
loadExportData(Highcharts);
loadBoost(Highcharts);

Highcharts.setOptions({
  global: {
    useUTC: true,
  },
  lang: {
    months: [
      i18n.t('dates.months.january', locale),
      i18n.t('dates.months.february', locale),
      i18n.t('dates.months.march', locale),
      i18n.t('dates.months.april', locale),
      i18n.t('dates.months.may', locale),
      i18n.t('dates.months.june', locale),
      i18n.t('dates.months.july', locale),
      i18n.t('dates.months.august', locale),
      i18n.t('dates.months.september', locale),
      i18n.t('dates.months.october', locale),
      i18n.t('dates.months.november', locale),
      i18n.t('dates.months.december', locale),
    ],
    shortMonths: [
      i18n.t('dates.months.jan', locale),
      i18n.t('dates.months.feb', locale),
      i18n.t('dates.months.mar', locale),
      i18n.t('dates.months.apr', locale),
      i18n.t('dates.months.mayMMM', locale),
      i18n.t('dates.months.juneMMM', locale),
      i18n.t('dates.months.julyMMM', locale),
      i18n.t('dates.months.aug', locale),
      i18n.t('dates.months.sept', locale),
      i18n.t('dates.months.oct', locale),
      i18n.t('dates.months.nov', locale),
      i18n.t('dates.months.dec', locale),
    ],
    weekdays: [
      i18n.t('dates.days.sun', locale),
      i18n.t('dates.days.mon', locale),
      i18n.t('dates.days.tue', locale),
      i18n.t('dates.days.wed', locale),
      i18n.t('dates.days.thur', locale),
      i18n.t('dates.days.fri', locale),
      i18n.t('dates.days.sat', locale),
    ],
    loading: [i18n.t('components.highCharts.loading', locale)],
    contextButtonTitle: i18n.t('components.highCharts.contextButtonTitle', locale),
    decimalPoint: i18n.t('components.highCharts.decimalPoint', locale),
    thousandsSep: i18n.t('components.highCharts.thousandsSep', locale),
    downloadJPEG: i18n.t('components.highCharts.downloadJPEG', locale),
    downloadPDF: i18n.t('components.highCharts.downloadPDF', locale),
    downloadPNG: i18n.t('components.highCharts.downloadPNG', locale),
    downloadSVG: i18n.t('components.highCharts.downloadSVG', locale),
    downloadXLS: i18n.t('components.highCharts.downloadXLS', locale),
    printChart: i18n.t('components.highCharts.printChart', locale),
    rangeSelectorFrom: i18n.t('components.highCharts.rangeSelectorFrom', locale),
    rangeSelectorTo: i18n.t('components.highCharts.rangeSelectorTo', locale),
    rangeSelectorZoom: i18n.t('components.highCharts.rangeSelectorZoom', locale),
    resetZoom: i18n.t('components.highCharts.resetZoom', locale),
    resetZoomTitle: i18n.t('components.highCharts.resetZoomTitle', locale),
    noData: i18n.t('components.highCharts.noData', locale),
  },
});

Vue.use(VueStickyKit);
Vue.use(VueHighcharts);
Vue.use(VueObserveVisibility);
Vue.use(observer);
Vue.component('infinite-scroll', MugenScroll);
window.XLSX = XLSX;
