import CookieStorage from '@/client/CookieStorage.js';

import camelcaseKeysDeep from 'camelcase-keys-deep';
import camelCase from 'lodash/camelCase';

import arrayWebsocketModule from '@/store/websocket/array/index';
import objectWebsocketModule from '@/store/websocket/object/index';

import { websockets } from '@/models/Websocket';
import { setupChannel } from '@/WSHandler';
import { SessionStorageController } from '@/models/SessionStorageController';
import DateHelper from '../client/helpers/DateHelper';

const TOKEN_ID_STORAGE_KEY = '_ust_';
const cookieStorage = new CookieStorage();
const dateHelper = new DateHelper();

const setLastProjectsIds = (projectId, companyId) => {
  if (!projectId) return;

  const lastProjectsByCompany = JSON.parse(localStorage.getItem("lastProjectsByCompany")) || {};
  const lastProjects = lastProjectsByCompany[companyId] || [];
  const indexProjectId = lastProjects.indexOf(projectId);

  if (indexProjectId >= 0) lastProjects.splice(indexProjectId, 1);

  if (lastProjects.length > 2) lastProjects.pop();

  lastProjects.unshift(projectId);
  lastProjectsByCompany[companyId] = lastProjects;
  localStorage.setItem("lastProjectsByCompany", JSON.stringify(lastProjectsByCompany));
}

export default {
  initialize: (state, _) => {
    const encocedTokenIdString = cookieStorage.getItem(TOKEN_ID_STORAGE_KEY);

    if (encocedTokenIdString) {
      const tokenId = JSON.parse(atob(encocedTokenIdString));
      state.userId = tokenId.id;
      state.token = tokenId.token;
      state.companyId = tokenId.companyId;
    }
  },
  logout: (state, _) => {
    state.userId = undefined;
    state.token = undefined;
    state.companyId = undefined;

    cookieStorage.removeItem(TOKEN_ID_STORAGE_KEY);
    sessionStorage.clear();
  },
  setReleasedRollouts: (state, releasedRollouts) => {
    state.releasedRollouts = [...releasedRollouts];
  },
  setCurrentUser: (state, user) => {
    let token = user.authToken;
    let id = user.id;
    let companyId = user.companyId;
    state.user = { ...user };
    state.token = token;
    state.userId = id;
    state.companyId = companyId;

    let tokenIdjsonString = JSON.stringify({
      id: id,
      token: token,
      companyId: companyId,
    });

    let encodedTokenId = btoa(tokenIdjsonString);

    cookieStorage.setItem(TOKEN_ID_STORAGE_KEY, encodedTokenId, dateHelper.getNextSaturdayMidnight());
  },
  setSsoProvider: (state, ssoProvider) => {
    state.ssoProvider = ssoProvider;
  },
  setLoginEmail: (state, loginEmail) => {
    localStorage.setItem("previsionSsoLoginEmail", loginEmail);
  },
  setSelectedProjectId: (state, projectId) => {
    state.selectedProjectId = projectId;

    SessionStorageController.setValue({ key: "PROJECT_ID", value: projectId });

    const isViewingCustomer = state.user?.companyId !== state.companyId;
    if (!isViewingCustomer) setLastProjectsIds(projectId, state.companyId);
  },
  setCompanyId: (state, companyId) => {
    state.companyId = companyId;
  },
  setCable: (state, cable) => {
    state.websocketOrchestrator.setConsumer(cable);
    state.cable = cable;
  },
  setupDefaultWebsocketChannel: (state, { store, subscription }) => {
    let receivedCallback = data => {
      let body = data.body;
      let changes, suffix;
      if (Array.isArray(body.changes)) {
        changes = body.changes.map(change => camelcaseKeysDeep(JSON.parse(change)));
        suffix = 'Items';
      } else {
        changes = camelcaseKeysDeep(JSON.parse(body.changes));
        suffix = 'Item';
      }
      let storeMethod = body.method === 'save' ? `set${suffix}` : `remove${suffix}`;
      store.commit(`${camelCase(body.type)}/${storeMethod}`, changes);
    };
    setupChannel(state, subscription, receivedCallback);
  },
  prepareWebsocketModules: (state, { store, context }) => {
    let resource;
    for (let key in websockets) {
      resource = websockets[key];
      if (resource.context == context) {
        if (!store.state[resource.name]) {
          store.registerModule(
            resource.name,
            resource.type == Object ? objectWebsocketModule : arrayWebsocketModule,
          );
        }
        store.commit(`${resource.name}/setItemParseFunction`, resource.itemParseFunction);
        store.commit(`${resource.name}/clear${resource.type == Object ? 'Item' : 'Items'}`);
        store.commit(`${resource.name}/setLoaded`, false);
      }
    }
  },
  setGeneralPhase: (state, generalPhase) => {
    state.generalPhase = generalPhase;

    SessionStorageController.setValue({ key: "GENERAL_PHASE", value: generalPhase });
  },
};
