export default {
  setInspections: (state, inspections) => {
    state.inspections = inspections;
  },
  setLoading: (state, status) => {
    state.loading = status;
  },
  setQualityConfig: (state, qualityConfig) => {
    state.qualityConfig = qualityConfig;
  },
  setGeneralTableExhibitionMode: (state, generalTableExhibitionMode) => {
    state.generalTableExhibitionMode = generalTableExhibitionMode;
  },
  setHasQualityConfig: (state, hasQualityConfig) => {
    state.hasQualityConfig = hasQualityConfig;
  },
  setQualityLinkingStepAnySelectorChecked: (state, { stepName, value }) => {
    const targetStep = state.qualityLinkingSteps.find((step) => step.value === stepName)

    if(!targetStep) return

    targetStep.isAnySelectorChecked = value
  },
};
