import ActionCable from 'actioncable';
import BackendEncryptionService from './services/BackendEncryptionService';

const setupChannel = (state, subscription, receivedCallback) => {
  if (state[subscription.channel]) state.cable.subscriptions.remove(state[subscription.channel]);
  state[subscription.channel] = state.cable.subscriptions.create(subscription, {
    connected: () => (state.disconnected = false),
    received: body => {
      state.disconnected = false;
      return receivedCallback(body);
    },
    disconnected: () => {
      setTimeout(() => {
        state.disconnected = true;
      }, 10000);
    },
  });
};

const createConsumer = token => {
  const encryptedToken = BackendEncryptionService.encrypt(token);

  return encryptedToken
    ? ActionCable.createConsumer(
        `${import.meta.env.VITE_API_WEBSOCKET_URL}?token=${encryptedToken}`,
      )
    : undefined;
};

export { setupChannel, createConsumer };
