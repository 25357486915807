import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/core';
import { ApolloLink } from 'apollo-link';
import { setContext } from '@apollo/client/link/context';
import { has } from 'lodash';
import router from '@/router';
import store from '@/store';
import I18n from '@/i18n.js';
import CookieStorage from '@/client/CookieStorage.js';

const cookieStorage = new CookieStorage();


const SERVICE_UNAVAILABLE = 503;
const FORBIDDEN = 403;

const httpLink = new createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_HTTP || 'https://localhost:3000/graphql',
  headers: {
    accept: 'application/json',
  },
});

const authLink = setContext((_, { headers }) => {
  const TOKEN_ID_STORAGE_KEY = '_ust_';
  const encocedTokenIdString = cookieStorage.getItem(TOKEN_ID_STORAGE_KEY);
  const tokenId = encocedTokenIdString ? JSON.parse(atob(encocedTokenIdString)) : {};

  return {
    headers: {
      ...headers,
      authorization: `token ${tokenId.apiToken}`,
      userAuthorization: `token ${tokenId.token}`,
    },
  };
});

const forwardLink = new ApolloLink((operation, forward) => {
  let response = forward(operation);
  return response.map(checkWebsocketAndParseJsonFields);
});

function checkWebsocketAndParseJsonFields(obj) {
  checkWebsocket(obj);
  return parseJsonFields(obj);
}

function parseJsonFields(obj) {
  let value = undefined;
  if (!obj) return obj;
  Object.keys(obj).forEach(key => {
    value = obj[key];
    if (key.toLowerCase().includes('json')) {
      obj[key] = JSON.parse(obj[key]);
    } else if (typeof value === 'object') {
      parseJsonFields(obj[key]);
    }
  });
  return obj;
}

function checkWebsocket(obj) {
  try {
    const { channel, body, __typename } = obj.data[Object.keys(obj.data)[0]];
    if (__typename == 'Message') {
      let subscription = store.state.cable.subscriptions.subscriptions.find(
        ({ identifier }) => JSON.parse(identifier).id == channel,
      );
      subscription.received({
        body: {
          ...body,
          changes: JSON.parse(body.changes || []),
          method: body.methodName,
          type: body.typeName,
        },
      });
    }
  } catch { }
}

const errorLink = onError(({ graphQLErrors, operation, response, networkError }) => {
  if (networkError && networkError.statusCode == SERVICE_UNAVAILABLE) {
    router.push({ name: 'login', params: { isServiceUnavailable: true } });
  } else if (
    networkError &&
    networkError.statusCode == FORBIDDEN &&
    has(networkError, 'result.error.code') &&
    networkError.result.error.code === 'policy_forbidden'
  ) {
    router
      .push({
        name: 'home',
        params: { snackbarMessage: I18n.t('general.pricing.unauthorizedFeatureRoute') },
      })
      .catch(err => {});
  } else if (graphQLErrors) {
    //graphQLErrors.forEach(error => {
    //let gqlError = new GqlError(error);
    //Sentry.withScope(scope => {
    //scope.setExtras({ apiResponse: safeStringfy(response), operation: safeStringfy(operation), ...gqlError.extras });
    //Sentry.captureException(gqlError);
    //});
    //});
  }
});

export const link = ApolloLink.from([authLink, forwardLink, errorLink, httpLink]);
