import state from './state';
import mutations from './mutations';
import getters from './getters';

export { state };
export { mutations };
export { getters };

const namespaced = true;

export default {
  namespaced,
  state,
  mutations,
  getters
};
