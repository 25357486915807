query QualityIntegrationConfigs($companyId: ID) {
  me(id: $companyId) {
    id
    qualityIntegrationConfig {
      id
      partner
      token
      username
      password
      projects {
        id
        name
      }
    }
  }
}
