import store from '@/store';

export default async function trialExpired({ from, to, next }) {
  const isTrialExpired = await getTrialStatus();

  if (isTrialExpired) return next({ name: 'trial-expired' });

  if (!isTrialExpired && to.name == 'trial-expired') return next({ name: 'home' });

  return next();
}

async function getTrialStatus() {
  return await new Promise(resolve => {
    const interval = setInterval(() => {
      if (store.getters['isTrialExpired'] !== undefined) {
        resolve(store.getters['isTrialExpired']);
        clearInterval(interval);
      }
    }, 150);
  });
}
