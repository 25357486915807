import Vue from 'vue';

export default {
  exportCff: (projectId, budgetReportId, perspective, monthlyProgressionType) => {
    return Vue.axios.get(`projects/${projectId}/budget_report/${budgetReportId}/export_cff`, {
      params: { perspective: perspective, monthlyProgressionType: monthlyProgressionType },
    });
  },
  exportReport: (projectId, budgetReportId) =>
    Vue.axios.get(`/projects/${projectId}/budget_report/${budgetReportId}/xlsx`),
  exportWeights: (projectId, budgetReportId) =>
    Vue.axios.get(`/projects/${projectId}/budget_report/${budgetReportId}/xlsx_budget_weights`),
  exportInvalidWeights: projectId =>
    Vue.axios.get(`/projects/${projectId}/budget_report/xlsx_invalid_budget_items`),
};
