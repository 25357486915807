export default {
  qualityConfig: undefined,
  hasQualityConfig: undefined,
  inspections: [],
  loading: false,
  generalTableExhibitionMode: "all",
  qualityLinkingSteps: [
    { step: 1, value: 'linkLocals', nameI18nPath: 'components.qualityStepper.linkFloors', isAnySelectorChecked: false },
    { step: 2, value: 'linkModels', nameI18nPath: 'components.qualityStepper.linkPackagesAndServices', isAnySelectorChecked: false },
  ]
};
