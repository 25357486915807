import Vue from 'vue';
import Vuex from 'vuex';
import * as Sentry from '@sentry/browser';

import ActivitiesManager from '@/features/schedules/models/ActivitiesManager';

import ScheduleModule from '@/store/schedule/index';
import MacroflowModule from '@/store/macroflow/index.js';
import CalendarModule from '@/store/calendar_form/index';
import ReportsModule from '@/store/reports/index';
import KanbanModule from '@/store/kanban/index';
import BudgetModule from '@/store/budget_report/index';
import MeasurementInfographicModule from '@/store/measurement_infographic/index';
import PricingModule from '@/store/pricing/index';
import TrialCampaignModule from '@/store/trial_campaign/index';
import PromotionsModule from '@/store/promotions/index';
import TotvsIntegrationBySheetsModule from '@/store/totvs_integration_by_sheets/index';
import DashUserPermissionsModule from '@/store/dash_user_permissions/index';
import QualityIntegrationModule from '@/store/quality_integration/index';
import MeasurementModule from '@/store/measurement/index';
import ShortTermDashboardModule from '@/store/short_term_dashboard/index';
import HistogramDashboardModule from '@/store/histogram_dashboard/index';
import ContractsDashboardModule from '@/store/contracts_dashboard/index';
import MidTermDashboardModule from '@/store/mid_term_dashboard/index';
import LongTermDashboard from '@/features/long_term_dashboard/store/index';
import GoalDashboardModule from '@/features/goal_dashboard/store/index';
import ContractsModule from '@/store/contracts/index';
import DefaultDashboardModule from '@/features/default_dashboard/store/index';

import { actions, getters, mutations, state } from './store/index';
import { createConsumer } from '@/WSHandler';
import { SessionStorageController } from '@/models/SessionStorageController';

import { setupIntercom } from "@/plugins/intercom"
import { setupFreshdesk } from "@/plugins/freshdesk"


import i18n from '@/i18n.js';
import vue from '@/main.js';
import I18n from "@/i18n.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    schedule: ScheduleModule,
    macroflow: MacroflowModule,
    calendarForm: CalendarModule,
    reports: ReportsModule,
    kanban: KanbanModule,
    budget: BudgetModule,
    measurementInfographic: MeasurementInfographicModule,
    pricing: PricingModule,
    trialCampaign: TrialCampaignModule,
    promotions: PromotionsModule,
    totvs: TotvsIntegrationBySheetsModule,
    dashUserPermissions: DashUserPermissionsModule,
    qualityIntegration: QualityIntegrationModule,
    measurement: MeasurementModule,
    shortTermDashboard: ShortTermDashboardModule,
    histogramDashboard: HistogramDashboardModule,
    midTermDashboard: MidTermDashboardModule,
    longTermDashboard: LongTermDashboard,
    contractsDashboard: ContractsDashboardModule,
    goalDashboard: GoalDashboardModule,
    contracts: ContractsModule,
    defaultDashboard: DefaultDashboardModule,
  },
  state,
  getters,
  mutations,
  actions,
});

store.watch(
  state => state.token,
  token => store.commit('setCable', createConsumer(token)),
);

store.watch(
  state => state.companyId,
  companyId => {
    if (companyId) {
      store.commit('setupDefaultWebsocketChannel', {
        store: store,
        subscription: {
          id: `changes_of_company_${companyId}`,
          channel: 'CompanyChangesChannel',
          company_id: companyId,
        },
      });

      store.commit('prepareWebsocketModules', { store: store, context: 'company' });
      store.dispatch('pricing/setupSubscriptionPlanChangesChannel', store);
      store.dispatch('qualityIntegration/setupQualityIntegrationConfigChangesChannel', store);
      store.dispatch('qualityIntegration/loadQualityConfig', store);
      handleSessionStorage();
    }
  },
);

store.watch(
  state => state.user,
  user => {
    if (user && user.id) {
      store.dispatch('dashUserPermissions/setupDashPermissionsChangesChannel', store);
    }
  },
);

store.watch(
  state => state.selectedProjectId,
  (selectedProjectId, oldSelectedProjectId) => {
    if (
      state.token &&
      selectedProjectId &&
      (!oldSelectedProjectId || selectedProjectId != oldSelectedProjectId)
    ) {
      store.commit('setupDefaultWebsocketChannel', {
        store: store,
        subscription: {
          id: `changes_of_project_${state.selectedProjectId}`,
          channel: 'ProjectChangesChannel',
          project_id: state.selectedProjectId,
        },
      });
      store.commit('prepareWebsocketModules', { store: store, context: 'project' });
      store.commit('schedule/setActivitiesManager', new ActivitiesManager(store, I18n));
      store.commit('schedule/setHiddenFloorsId', []);
      store.commit('schedule/setHiddenServicesId', []);
      store.commit('schedule/disableShowCriticalActivities');
      store.dispatch('schedule/setupBulkActivitiesChangesChannel', store);
    }
  },
);

store.watch(
  (_, getters) => getters['companies/item'],
  company => {
    if (company) {
      let user = store.getters['currentUser'];

      if (isValidCompany(company)) prepareWootric(user);
      setupIntercom(company, user);
      setupFreshdesk(company, user)
      prepareSentry(user);
      prepareMouseflow(user);
      prepareClarity(user, company);
      prepareProfitWell(company);
      prepareLocale(company);
    }
  },
);

function isValidCompany(company) {
  return (
    company.campaign != 'certificate' &&
    !company.internal &&
    company.subscriptionPlan.status != 'employee' &&
    company.subscriptionPlan.status != 'trial'
  );
}

function prepareMouseflow(user) {
  window._mfq.push(['identify', user.email]);
  window._mfq.push(['setVariable', 'userId', user.id]);
  window._mfq.push(['setVariable', 'companyId', user.companyId]);
}

function prepareClarity(user, company) {
  if (!import.meta.env.PROD) return;

  clarity('set', 'user_id', user.id);
  clarity('set', 'user_email', user.email);
  clarity('set', 'company_id', user.companyId);
  clarity('set', 'company_subscription_campaign', company.subscriptionPlan.campaign);
  clarity('set', 'company_subscription_status', company.subscriptionPlan.status);
}

function prepareWootric(user) {
  window.wootricSettings = {
    email: user.email,
    created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
    account_token: 'NPS-e476e3dc',
    scale_color: 'gradient',
    modal_theme: 'dark',
  };
  window.wootric('run');
}

function prepareSentry(user) {
  Sentry.configureScope(scope => {
    scope.setUser(user);
  });
}

function prepareProfitWell(company) {
  if (!import.meta.env.PROD) return;

  try {
    profitwell('start', { user_email: company.name, user_id: company.id });
  } catch (error) {
    console.log('Error connecting');
  }
}

function prepareLocale(company) {
  i18n.locale = company.language;
  localStorage.setItem('locale', company.language);
  Date.reloadI18n();
  vue.$vuetify.lang.current = company.language ? company.language.slice(0, 2) : 'pt';
}

function handleSessionStorage() {
  setCompanyId();
  setSelectedProjectId();
  setGeneralPhase();
}

function setCompanyId() {
  let companyId = SessionStorageController.getValue({ key: 'COMPANY_ID' });

  if (companyId) store.commit('setCompanyId', companyId);
}

function setSelectedProjectId() {
  let selectedProjectId = SessionStorageController.getValue({ key: 'PROJECT_ID' });

  if (selectedProjectId) store.commit('setSelectedProjectId', selectedProjectId);
}

function setGeneralPhase() {
  let generalPhase = SessionStorageController.getValue({ key: 'GENERAL_PHASE' });

  if (generalPhase) store.commit('setGeneralPhase', generalPhase);
}

store.commit('initialize');
export default store;
