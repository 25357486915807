export default {
  setTitle: (state, title) => (state.title = title),

  setStartAt: (state, startAt) => (state.startAt = startAt),
  setEndAt: (state, endAt) => (state.endAt = endAt),

  setMembers: (state, members) => (state.members = members),
  unsetMember: (state, member) => {
    if (state.members) {
      state.members.splice(state.members.indexOf(member), 1);
    }
  },
  setLabels: (state, labels) => (state.labels = labels),
  unsetLabel: (state, index) => state.labels.splice(index, 1),

  setProjects: (state, projects) => (state.projects = projects),
  unsetProject: (state, project) => {
    if (state.projects) {
      state.projects.splice(state.projects.indexOf(project), 1);
    }
  },

  setServices: (state,services) => (state.services = services),
  unsetServices: (state, service) => {
    if(state.services){
      state.services.splice(state.services.indexOf(service), 1);
    }
  },

  setFloors: (state, floors) => (state.floors = floors),
  unsetFloors: (state, floor) => {
    if(state.floors){
      state.floors.splice(state.floors.indexOf(floor), 1);
    }
  },

  setTaskStatus: (state, taskStatus) => (state.taskStatus = taskStatus),

  setSteps: (state, steps) => (state.steps = steps),

  setMassAction: (state, action) => (state.massAction = action),

  setStep: (state, stepId) => (state.selectedSteps.push(stepId)),

  unsetStep: (state, stepId) => state.selectedSteps.splice(state.selectedSteps.indexOf(stepId), 1),

  unsetAllSteps: (state) => state.selectedSteps = [],

  setTask: (state, taskId) => (state.selectedTasks.push(taskId)),

  unsetTask: (state, taskId) => state.selectedTasks.splice(state.selectedTasks.indexOf(taskId), 1),

  unsetAllTasks: (state) => state.selectedTasks = []

};
