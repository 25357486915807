import AxiosGqlService from '@/services/AxiosGqlService';
import DashUserPermissionsQuery from '@/graphql/query/users/DashUserPermissionsQuery.gql';

export default {
  loadPermissions(context) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AxiosGqlService.post(DashUserPermissionsQuery);
        const dashPermissions = response.data.currentUser.dashUserPermissions

        context.commit("setDashPermissions", dashPermissions);

        resolve(context.getters.permissions);
      } catch (error) {
        reject(error);
      }
    })
  },
  setupDashPermissionsChangesChannel(context) {
    let cableSubscriptions = context.rootState.cable.subscriptions;
    if (context.state.dashUserPermissionsChangesChannel) {
      cableSubscriptions.remove(context.state.dashUserPermissionsChangesChannel);
    }

    let channel = {
      id: `changes_of_dash_user_permissions_${context.rootState.user.id}`,
      channel: 'DashUserPermissionsChangesChannel',
      user_id: context.rootState.user.id,
    }

    context.state.dashUserPermissionsChangesChannel = cableSubscriptions.create(channel, {
      received: _ => {
        context.dispatch("loadPermissions").then(_)
      }
    })
  }
}
