import { removeExtraEmptySpace } from '@/models/Utils';

function isFileTabValid({ budgetForm }) {
  return (
    !isFormNameInvalid(budgetForm.name) &&
    budgetForm.code &&
    budgetForm.start &&
    budgetForm.description &&
    (budgetForm.materialCost || budgetForm.totalCost || budgetForm.laborCost)
  );
}

function isFormNameInvalid(name) {
  if(!name) return true;

  let formName = removeExtraEmptySpace(name);
  return formName == '' || formName == ' ';
}

function isSiengeTabValid({ siengeForm }) {
  return (
    siengeForm.name &&
    (siengeForm.siengeProject && siengeForm.siengeProject.id) &&
    (siengeForm.constructionUnit && siengeForm.constructionUnit.id)
  );
}

function isMegaTabValid({ megaForm }) {
  return megaForm.name && megaForm.externalProjectId && megaForm.externalBudgetId;
}

function isTotvsTabValid({ totvsForm }) {
  let project = totvsForm.totvsProject;
  return  totvsForm.name && project && project.externalProjectId && project.externalCompanyId;
}


export default {
  isFormValid: state => {
    if(state.activeTab == 'file') {
      return isFileTabValid(state)
    }

    return isMegaTabValid(state) || isSiengeTabValid(state) || isTotvsTabValid(state)
  },
};
