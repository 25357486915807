import Vue from 'vue';

import i18n from '@/i18n.js';

Vue.filter('currency', function(value, withSymbol = true) {
  let money = value == undefined || value == null ? 0 : value;
  if (withSymbol) return money.toLocaleString((i18n.t('labels.currencyLocale')), { style: 'currency', currency: (i18n.t('labels.currencyType')) });

  return money.toLocaleString((i18n.t('labels.currencyLocale')), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
});
