import Vue from 'vue';

Vue.filter('number', function(
  value,
  minFractionDigits = 2,
  maxFractionDigits = 2,
  format = 'pt-BR',
) {
  if(value == undefined || value == null) return '-';
  return value.toLocaleString(format, {
    style: 'decimal',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  });
});
