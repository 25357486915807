import Vue from 'vue';

export function truncate(text, length = 30, clamp = '...') {
  if (!text || text.length <= length) return text;

  const maxStringLength = length - clamp.length
  const tcText = text.slice(0, maxStringLength);
  const characterBeforeSpaceIndex = tcText.lastIndexOf(' ') - 1
  let charBeforeTruncateIndex = characterBeforeSpaceIndex > 0 ? characterBeforeSpaceIndex : tcText.length - 1

  while (charBeforeTruncateIndex > 0 && tcText[charBeforeTruncateIndex] === clamp[0]) {
    charBeforeTruncateIndex--
  }

  return tcText.slice(0, charBeforeTruncateIndex + 1) + clamp;
}

Vue.filter('truncate', truncate);
