'use strict';
import { clone } from 'lodash';

class Calendar {
  WEEK_DAYS = [
    { day: 1, name: 'mondayWorkday' },
    { day: 2, name: 'tuesdayWorkday' },
    { day: 3, name: 'wednesdayWorkday' },
    { day: 4, name: 'thursdayWorkday' },
    { day: 5, name: 'fridayWorkday' },
    { day: 6, name: 'saturdayWorkday' },
    { day: 0, name: 'sundayWorkday' },
  ];

  constructor(calendarData) {
    this.calendarData = calendarData;
    this.minDate = new Date(
      Date.UTC(this.calendarData.startYear, this.calendarData.startMonth - 1, 1),
    );
    this.maxDate = new Date(
      Date.UTC(this.calendarData.endYear, this.calendarData.endMonth, 0, 23, 59, 59, 999),
    );
    this.customWorkdays = calendarData.customWorkdays || [];
    this.prepareAttributes();
  }

  prepareAttributes() {
    if (this.calendarData) {
      this.refreshDisabledWeekDays();
      this.refreshCustomWorkdaysTime();
      this.refreshCustomHolidaysTime();
      this.refreshNationalHolidaysTime();
      this.setupIsBusinessDayByDates();
    }
  }

  updatedAt() {
    return this.calendarData.updatedAt;
  }

  refreshDisabledWeekDays() {
    this.disabledWeekDays = [];
    let weekDay;
    for (weekDay of this.WEEK_DAYS) {
      if (!this.calendarData[weekDay.name]) {
        this.disabledWeekDays.push(weekDay.day);
      }
    }
  }

  refreshCustomWorkdaysTime() {
    this.customWorkdaysTime = this.mapTime(this.calendarData.customWorkdays);
  }

  refreshCustomHolidaysTime() {
    this.customHolidaysTime = this.mapTime(this.calendarData.customHolidays);
  }

  refreshNationalHolidaysTime() {
    this.nationalHolidaysTime = this.mapTime(this.calendarData.nationalHolidays);
  }

  setupIsBusinessDayByDates() {
    this.isBusinessDayByDates = {};
    let controlDate = Date.withoutTime(this.minDate);
    let time;
    let isWorkday, isCustomWorkday, isHoliday, isCustomHoliday;

    while (controlDate <= this.maxDate) {
      time = controlDate.valueOf();
      isWorkday = !this.disabledWeekDays.includes(controlDate.getUTCDay());
      isCustomWorkday = this.customWorkdaysTime.includes(time);
      isHoliday = this.nationalHolidaysTime.includes(time);
      isCustomHoliday = this.customHolidaysTime.includes(time);
      this.isBusinessDayByDates[new Date(time).toISOString()] = this.isBusinessDayExpression(isHoliday, isCustomHoliday, isWorkday, isCustomWorkday);
      controlDate = controlDate.addDays(1);
    }
  }

  addBusinessDays(date, delta, endOfDay = false) {
    let resultDate = new Date(date);
    if (!delta || delta == 0) return resultDate;
    if (!this.isBusinessDay(resultDate)) resultDate = date.beginningOfDay();

    const daysToSkip = Math.floor(delta);
    const partOfDay = delta % 1;

    for (let i = 0; i < daysToSkip; i++) {
      resultDate = this.rollForward(resultDate.addDays(1));
    }

    if (partOfDay != 0) return this.rollForward(resultDate.addDays(partOfDay));
    if (endOfDay) return this.prevBusinessDay(resultDate).endOfDay();

    return resultDate;
  }

  subtractBusinessDays(date, delta, beginningOfDay) {
    let resultDate = clone(date);
    if (!delta || delta == 0) return resultDate;
    if (!this.isBusinessDay(resultDate)) resultDate = date.beginningOfDay();

    const daysToSkip = Math.floor(delta);
    const partOfDay = delta % 1;

    for (let i = 0; i < daysToSkip; i++) {
      resultDate = this.rollBackward(resultDate.subDays(1));
    }

    if (partOfDay != 0) return this.rollBackward(resultDate.subDays(partOfDay));
    if (beginningOfDay) return this.nextBusinessDay(resultDate).beginningOfDay();

    return resultDate;
  }

  isBusinessDay(date) {
    let businessDayCheck = this.isBusinessDayByDates[date.beginningOfDay().toISOString()];

    if (businessDayCheck == undefined) return this.isBusinessDayOutsideCalendar(date);

    return businessDayCheck;
  }

  isBusinessDayOutsideCalendar(date) {
    const time = date.withoutTime().valueOf();
    const isWorkday = !this.disabledWeekDays.includes(date.getUTCDay());
    const isCustomWorkday = this.customWorkdaysTime.includes(time);
    const isHoliday = this.nationalHolidaysTime.includes(time);
    const isCustomHoliday = this.customHolidaysTime.includes(time);

    return this.isBusinessDayExpression(isHoliday, isCustomHoliday, isWorkday, isCustomWorkday);
  }

  isBusinessDayExpression(isHoliday, isCustomHoliday, isWorkday, isCustomWorkday) {
    return !(isHoliday || isCustomHoliday || !(isWorkday || isCustomWorkday));
  }

  rollForward(date) {
    if (this.isBusinessDay(date)) {
      return date;
    }

    return this.nextBusinessDay(date);
  }

  rollBackward(date) {
    if (this.isBusinessDay(date)) {
      return date;
    }

    return this.prevBusinessDay(date);
  }

  nextBusinessDay(date) {
    let d = date.addDays(1);
    while (!this.isBusinessDay(d)) {
      d = d.addDays(1);
    }
    return d;
  }

  prevBusinessDay(date) {
    let d = date.subDays(1);
    while (!this.isBusinessDay(d)) {
      d = d.subDays(1);
    }
    return d;
  }

  businessDaysBetween(dateOne, dateTwo) {
    let businessDays = 0;
    let d1 = new Date(dateOne);
    let d2 = new Date(dateTwo);

    if (this.isSameDate(d1, d2)) {
      return this.isBusinessDay(d1)
        ? this.secondsToDay(this.toSeconds(d2) - this.toSeconds(d1))
        : 0;
    }

    d1 = dateOne.addDays(1);
    while (d1.withoutTime() < d2.withoutTime()) {
      if (this.isBusinessDay(d1)) {
        businessDays++;
      }
      d1 = d1.addDays(1);
    }

    businessDays += this.isBusinessDay(dateOne) ? this.percentageOfTheDay(dateOne, true) : 0;
    businessDays += this.isBusinessDay(dateTwo) ? this.percentageOfTheDay(dateTwo, false) : 0;
    return businessDays;
  }

  percentageOfTheDay(date, onStart) {
    const seconds = onStart ? 86400 - this.toSeconds(date) : this.toSeconds(date) - 0;
    return this.secondsToDay(seconds);
  }

  toSeconds(date) {
    let seconds = date.getUTCHours() * 3600 + date.getUTCMinutes() * 60 + date.getUTCSeconds();
    return seconds == 86399 ? seconds + 1 : seconds;
  }

  secondsToDay(seconds) {
    return Number((seconds / 86400));
  }

  isSameDate(d1, d2) {
    return d1.withoutTime().valueOf() == d2.withoutTime().valueOf();
  }

  mapTime(array) {
    return (array || []).map(date => Date.withoutTime(date).valueOf());
  }
}

export default Calendar;
