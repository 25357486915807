import store from '@/store';

export default {
  success: config => {
    const token = store.state.token;
    if (token) {
      config.headers.Authorization = `Token token=${token}`;
    }

    if (
      config.headers.Accept != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      config.headers.Accept = 'application/json';
    }
    return config;
  },
  error: error => Promise.reject(error),
};
