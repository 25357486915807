import i18n from '@/i18n.js';
import '@/models/prototypes/Date';

export const datesRange = () => [
  {
    id: 'LAST_FIFTEEN',
    title: i18n.t('labels.lastFifteenDays'),
    dates: lastFifteenDays(),
  },
  {
    id: 'LAST_WEEK',
    title: i18n.t('labels.lastWeek'),
    dates: lastWeek(),
  },
  {
    id: 'THIS_WEEK',
    title: i18n.t('labels.thisWeek'),
    dates: thisWeek(),
  },
  {
    id: 'NEXT_WEEK',
    title: i18n.t('labels.nextWeek'),
    dates: nextWeek(),
  },
  {
    id: 'NEXT_FIFTEEN',
    title: i18n.t('labels.nextFifteenDays'),
    dates: nextFifteenDays(),
  },
  {
    id: 'THIS_MONTH',
    title: i18n.t('labels.thisMonth'),
    dates: thisMonth(),
  },
  {
    id: 'THIS_SEMESTER',
    title: i18n.t('labels.thisSemester'),
    dates: thisSemester(),
  },
  {
    id: 'THIS_YEAR',
    title: i18n.t('labels.thisYear'),
    dates: thisYear(),
  },
];

function today() {
  const startAt = new Date().beginningOfDay();
  const endAt = new Date().endOfDay();
  return {
    startAt,
    endAt,
  };
}

function yesterday() {
  return subDays(today(), 1);
}

function thisWeek() {
  const startAt = getSunday(new Date());
  const endAt = startAt.endOfDay().addDays(6);
  return {
    startAt,
    endAt,
  };
}

function lastWeek() {
  return subDays(thisWeek(), 7);
}

function nextWeek() {
  return addDays(thisWeek(), 7);
}

function nextFifteenDays() {
  return {
    startAt: new Date().beginningOfDay(),
    endAt: new Date().endOfDay().addDays(15),
  };
}

function lastFifteenDays() {
  return {
    startAt: new Date().subDays(15),
    endAt: new Date().endOfDay(),
  };
}

function thisMonth() {
  const today = new Date();
  const startAt = today.startOfMonth();
  const endAt = today.endOfMonth();
  return {
    startAt,
    endAt,
  };
}

function thisNextMonths(skipMonths) {
  const today = new Date();
  const startAt = today.startOfMonth();
  const endAt = today.addMonths(skipMonths).endOfMonth();
  return {
    startAt,
    endAt,
  };
}

function thisSemester() {
  const today = new Date();
  const startAt = today.subMonths(3).startOfMonth();
  const endAt = today.addMonths(3).endOfMonth();

  return {
    startAt,
    endAt,
  };
}
function lastMonths(months) {
  const today = new Date();
  const startAt = today.subMonths(months).startOfMonth();
  const endAt = today.endOfMonth();

  return {
    startAt,
    endAt,
  };
}

function thisYear() {
  const startAt = new Date(new Date().getUTCFullYear(), 0, 1);
  const endAt = new Date(new Date().getUTCFullYear(), 11, 31);
  return {
    startAt,
    endAt,
  };
}

function subDays(dataRange, days) {
  return addDays(dataRange, days * -1);
}

function addDays(dataRange, days) {
  const { startAt, endAt } = dataRange;
  return {
    startAt: startAt.addDays(days),
    endAt: endAt.addDays(days),
  };
}

function getSunday(date) {
  return firstDayOfWeek(date, 0);
}

function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
  const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff = dayOfWeek >= firstDayOfWeekIndex ? dayOfWeek - firstDayOfWeekIndex : 6 - dayOfWeek;

  firstDayOfWeek.setDate(dateObject.getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek;
}

export { thisWeek, thisMonth, nextWeek, thisNextMonths, thisSemester, thisYear, lastMonths };
