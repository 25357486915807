import i18n from '@/i18n.js';

export default {
  startLegacy: {
    id: 1,
    name: i18n.t('general.pricing.plans.startLegacy'),
    position: 4,
    currentlyForSale: false,
  },
  lean: {
    id: 2,
    name: i18n.t('general.pricing.plans.lean'),
    position: 2,
    currentlyForSale: true,
  },
  leanPlus: {
    id: 3,
    name: i18n.t('general.pricing.plans.leanPlus'),
    position: 3,
    currentlyForSale: true,
  },
  premiumLegacy: {
    id: 5,
    name: i18n.t('general.pricing.plans.premiumLegacy'),
    position: 6,
    currentlyForSale: false,
  },
  startBudget: {
    id: 6,
    name: i18n.t('general.pricing.plans.startBudget'),
    position: 5,
    currentlyForSale: false,
  },
  ganttPlus: {
    id: 7,
    name: i18n.t('general.pricing.plans.ganttPlus'),
    position: 1,
    currentlyForSale: true,
  }
}
