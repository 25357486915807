import Dependency from "./Dependency";
import Parts from "./Parts";

class Activity {
  constructor(activityData) {
    this.id = activityData.id;
    this.startAt = new Date(activityData.startAt);
    this.endAt = new Date(activityData.endAt);
    this.floorId = activityData.floorId;
    this.serviceId = activityData.serviceId;
    this.line = activityData.line;
    this.dependencies = this._initializeDepencies(activityData.dependenciesJson);
    this.workDuration = activityData.workDuration;
    this.percentageCompleted = activityData.percentageCompleted;
    this.expectedPercentageCompleted = activityData.expectedPercentageCompleted;
    this.baseExpectedPercentageCompleted = activityData.baseExpectedPercentageCompleted;
    this.amount = parseFloat(activityData.amount);
    this.doneAmount = parseFloat(activityData.doneAmount);
    this.part = activityData.part;
    this.parts =  activityData.parts instanceof Parts ? activityData.parts : new Parts(activityData.parts);
  }

  update(attributes = {}) {
    this.serviceId = attributes.serviceId || this.serviceId;
    this.line = attributes.line || this.line;
    this.dependencies = attributes.dependencies || this.dependencies;
    this.jobs = attributes.jobs || undefined;
    this.jobsDependencies = attributes.jobsDependencies || undefined;
    this.basicTeam = attributes.basicTeam || undefined;
    this.expectedPercentageCompleted = attributes.expectedPercentageCompleted || 0;

    if (this.hasParts()) {
      const lastPartIndex = this.getPartsLength() - 1;
      const lastPart = this.getParts()[lastPartIndex];

      lastPart.startAt = new Date(attributes.startAt || lastPart.startAt);
      lastPart.endAt = new Date(attributes.endAt || lastPart.endAt);
      lastPart.duration = attributes.workDuration || attributes.duration || lastPart.duration;
      this.workDuration = this.getParts().reduce((acc, part) => acc + part.duration, 0);
    } else {
      this.startAt = new Date(attributes.startAt || this.startAt);
      this.workDuration = attributes.workDuration || attributes.duration || this.workDuration;
    }

    this.endAt = new Date(attributes.endAt || this.endAt);
  }

  isBetween(min, max) {
    return (
      (this.startAt >= min && this.startAt <= max) ||
      (this.endAt >= min && this.endAt <= max) ||
      (this.startAt < min && this.endAt > max)
    );
  }

  isDelayed() {
    return this.percentageCompleted.round(2) < this.expectedPercentageCompleted.round(2);
  }

  isAhead() {
    return this.percentageCompleted.round(2) > this.expectedPercentageCompleted.round(2);
  }

  isCompleted() {
    return this.percentageCompleted && this.percentageCompleted == 100;
  }

  isNotCompleted() {
    return !this.isCompleted();
  }

  isUnstarted() {
    return !this.percentageCompleted;
  }

  toApiHash(selected = true, dragOnly = false) {
    const hash =  {
      id: this.id,
      startAt: this.startAt,
      endAt: this.endAt,
      line: this.line,
      floor_id: this.floorId,
      workDuration: this.workDuration,
      parts: this.getParts(),
    };

    if (selected) {
      hash.jobs = this.jobsToApiHash()
      hash.jobsDependencies = this.jobsDependencies
      hash.basicTeam = this.basicTeam
    }

    if (!dragOnly) hash.dependenciesJson = this.dependenciesToApiHash()

    return hash
  }

  dependenciesToApiHash() {
    return this.dependencies.map(d => ({
      delay: parseInt(d.delay),
      baseAttribute: d.baseAttribute,
      precedenceAttribute: d.precedenceAttribute,
      timeOperation: d.timeOperation,
      referenceActivityId: d.referenceActivityId,
      floatingActivityId: d.floatingActivityId,
    }))
  }

  jobsToApiHash() {
    return this.jobs?.map(j => ( {
      id: parseInt(j.id),
      name: j.name,
      duration: j.duration,
      position: j.position,
      start_at: j.startAt,
      end_at: j.endAt,
      part: j.part,
      basic_team: j.basicTeam
    }))
  }

  key() {
    return `${this.id}-${this.startAt.valueOf() + this.endAt.valueOf()}`;
  }

  hasParts() {
    return !this.parts.isEmpty();
  }

  getParts() {
    return this.parts.value;
  }

  getPartsLength() {
    return this.getParts().length;
  }

  getLastPart() {
    return this.getParts()[this.getPartsLength() -1];
  }

  getPreviousPartsDuration() {
    if(!this.hasParts()) return 0;

    return this.workDuration - this.getLastPart().duration
  }

  getPartPercentageCompletedByIndex(index) {
    if (!this.hasParts()) return 0;

    const currentPart = this.getParts()[index];
    const previousPartsDuration = this.calculateDurationUntilPart(index);
    const completedDuration = (this.percentageCompleted / 100) * this.workDuration;

    if (completedDuration >= previousPartsDuration + currentPart.duration) return 100;
    if (completedDuration > previousPartsDuration) {
      const percentageCompleted = ((completedDuration - previousPartsDuration) / currentPart.duration) * 100;
      return parseFloat(percentageCompleted.toFixed(2))
    };

    return 0;
  }

  calculateDurationUntilPart(index) {
    const parts = this.getParts();
    return parts.slice(0, index).reduce((sum, part) => sum + part.duration, 0);
  }

  addPart(part) {
    this.parts.add(part);
  }

  popParts(number) {
    return this.parts.pop(number);
  }

  _initializeDepencies(dependenciesJson) {
    return (dependenciesJson || []).map(d => {
      return new Dependency({
        referenceActivityId: d.referenceActivityId,
        floatingActivityId: d.floatingActivityId,
        precedenceAttribute: d.precedenceAttribute,
        baseAttribute: d.baseAttribute,
        timeOperation: d.timeOperation,
        delay: d.delay,
      });
    });
  }
}

export default Activity;
