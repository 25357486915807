import Vue from 'vue';
import i18n from '@/i18n.js';

Vue.filter('notificationStringDate', function(value, dateFormat = (i18n.t('dates.dateFormat')), utc = true) {
  if (!value) return undefined;

  const time = new Date(value).toTimeString().slice(0, 5);
  const date = Date.withoutTime(value).format(dateFormat, utc);
  return i18n.t('components.notifications.date', { time, date });
});
