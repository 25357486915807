import Vue from 'vue';
import _ from 'lodash';
import EventBus from '@/EventBus';

const getAppliedStylesFrom = element => {
  const { cssText } = element.style;
  const propsArray = _.compact(cssText.split(';').map(prop => _.trim(prop)));
  const propsObject = {};

  propsArray.map(prop => {
    prop = prop.split(': ');
    const propName = prop[0];
    const propValue = prop[1];
    propsObject[propName] = propValue;
  });

  return propsObject;
};

Vue.directive('observe-mutations', {
  bind(el, binding) {
    let { attributeFilter, styleFilter, delay, onMutation } = binding.value;
    let oldStyleProps = null;
    let newStyleProps = null;

    const emitMutation = mutation => {
      EventBus.$emit('onMutationObserved', mutation);
      if (onMutation) onMutation(mutation);
    };

    const observer = new MutationObserver(mutations => {
      mutations.map(mutation => {
        const mutationInfo = {};
        const { target, attributeName } = mutation;
        oldStyleProps = newStyleProps;
        if (attributeName === 'style' && styleFilter) {
          newStyleProps = getAppliedStylesFrom(target);
          if (oldStyleProps && newStyleProps) {
            const mutatedStyleProps = styleFilter.filter(
              prop => newStyleProps[prop] !== oldStyleProps[prop],
            );
            if (mutatedStyleProps.length) {
              mutationInfo.type = 'styleAttribute';
              mutationInfo.record = {
                target,
                props: mutatedStyleProps,
              };
              emitMutation(mutationInfo);
            }
          }
        } else {
          mutationInfo.type = 'default';
          mutationInfo.record = mutation;
          emitMutation(mutationInfo);
        }
      });
    });

    setTimeout(() => {
      observer.observe(el, {
        attributeFilter,
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
      });
    }, delay || 0);

    EventBus.$on('disconnectMutationObserver', () => {
      observer.takeRecords();
      observer.disconnect();
    });
  },
});
