import state from './state';
import router from '../router';

export default {
  isAdmin: (state, getters) => {
    return getters.currentUser && getters.currentUser.permission == 'admin';
  },
  isConstructionNoAccess: (state, getters) => {
    return getters.currentUser && getters.currentUser.permission == 'no_access';
  },
  isIncorporationAdmin: (state, getters) => {
    return getters.currentUser && getters.currentUser.incorporationPermission == 'admin';
  },
  isIncorporationNoAccess: (state, getters) => {
    return getters.currentUser && getters.currentUser.incorporationPermission == 'no_access';
  },
  isTesting: (state, getters, rootState, rootGetters) => {
    const company = rootGetters['companies/item'];
    // "testing" is deprecated, checking the status trial to include the companies with the new status
    return company && (company.status === 'testing' || company.status === 'trial');
  },
  isTrial: (state, getters, rootState, rootGetters) => {
    const company = rootGetters['companies/item'];
    return company && company.status === 'trial' && company.campaign === 'trialprevision';
  },
  remainingTrialDays: (state, getters, rootState, rootGetters) => {
    const company = rootGetters['companies/item'];
    const trialExpirationDate = company ? company.trialExpireAt : undefined;

    if (!trialExpirationDate) return undefined;

    let daysLeft = new Date(trialExpirationDate).dayDiff(new Date());

    return Math.max(0, daysLeft);
  },
  isTrialExpired: (state, getters, rootState, rootGetters) => {
    const company = rootGetters['companies/item'];
    return company && company.status === 'expired_trial';
  },
  campaign: (state, getters, rootState, rootGetters) => {
    const company = rootGetters['companies/item'];
    return company && company.campaign;
  },
  isExperiencingCertificate: (_, getters) => {
    if (!getters.isTesting) return false;

    return getters.isTesting && getters.campaign === 'certificate';
  },
  selectedProject: (state, getters, rootState, rootGetters) => {
    return state.selectedProjectId && rootGetters['projects/isLoaded']
      ? rootGetters['projects/item'](state.selectedProjectId)
      : undefined;
  },
  validCampaignProjectAvailable: (state, getters, rootState, rootGetters) => {
    const company = rootGetters['companies/item'];
    return company && company.validCampaignProjectAvailable;
  },
  selectedCompany: (state, getters, rootState, rootGetters) => {
    const company = rootGetters['companies/item'];
    if (company && company.internal) {
      return getters['selectedProject'] ? getters['selectedProject'].companyId : undefined;
    }
    return undefined;
  },
  delayedActivities: (state, getters, rootState, rootGetters) => {
    return (rootGetters['activities/items'] || []).filter(activity => activity.isDelayed());
  },
  aheadActivities: (state, getters, rootState, rootGetters) => {
    return (rootGetters['activities/items'] || []).filter(activity => activity.isAhead());
  },
  isSelectedProjectArchived: (state, getters) => {
    return getters['selectedProject'] ? !!getters['selectedProject'].archivedAt : true;
  },
  isSelectedProjectGantt: (state, getters) => {
    return getters['selectedProject']?.scheduleType == 'gantt';
  },
  isLoggedIn: state => Boolean(state.token),
  currentUser: state => {
    return state.user;
  },
  loginEmail: _ => {
    return localStorage.getItem('previsionSsoLoginEmail');
  },
  isUserViewOnly: state => {
    const permission = state.user && state.user.permission;
    return permission === 'only_read' || permission === 'only_measurement';
  },
  isMeasurementOnly: state => {
    const permission = state.user && state.user.permission;

    return permission === 'only_measurement';
  },
  releasedRollouts: () => {
    return state.releasedRollouts || [];
  },
  projectsWithChartRequirements: (state, getters) => {
    return getters['projects/items'] || [];
  },
  currentRoute: () => {
    return router.currentRoute;
  },
  maxConstructionProjectsQuantityReached: (state, getters) => {
    return (
      getters['projects/items'].filter(
        project => !project.archivedAt && project.phase === 'construction',
      ).length >= state.companies['item'].subscriptionPlan.maxConstructions
    );
  },
  maxPreExecutionProjectsQuantityReached: (state, getters) => {
    return (
      getters['projects/items'].filter(
        project => !project.archivedAt && project.phase === 'pre_execution',
      ).length >= state.companies['item'].subscriptionPlan.maxPreExecutions
    );
  },
  maxPreConstructionProjectsQuantityReached: (state, getters) => {
    return (
      getters['projects/items'].filter(
        project => !project.archivedAt && project.phase === 'pre_construction',
      ).length >= state.companies['item'].subscriptionPlan.maxPreConstruction
    );
  },
};
