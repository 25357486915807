import Vue from 'vue';

export default {
  show: id => Vue.axios.get(`projects/${id}/calendar`),
  update: (id, calendar) => {
    return Vue.axios.put(`projects/${id}/calendar`, calendar);
  },
  holidays: (id, startMonth, startYear, endMonth, endYear) => {
    return Vue.axios.get(`projects/${id}/calendar/holidays`, {
      params: {
        startMonth: startMonth,
        startYear: startYear,
        endMonth: endMonth,
        endYear: endYear,
      },
    });
  },
};
