import camelcaseKeysDeep from 'camelcase-keys-deep';

import ActivitiesService from '@/services/ActivitiesService';
import ProjectsService from "@/services/ProjectsService";
import Activity from '@/features/schedules/models/Activity';
import SaveStatusEnum from '@/models/SaveStatusEnum';
import EventBus from '@/EventBus';
import { debounce } from 'lodash';

export default {
  async addSaveRequest(context, {dragOnly = false, activitiesIds = [], opId = undefined} = {}) {
    const activitiesManager = context.state.activitiesManager;
    const calendar = context.rootState.calendars.item;
    const activities = activitiesManager.clearChangedActivities();
    const projectId = context.rootState.selectedProjectId;
    if (!activities.length) return true;

    try {
      activitiesManager.validateAllDependencies();
      context.commit('setSaveStatus', SaveStatusEnum.IN_SAVING_PROGRESS);
      await ActivitiesService.automaticSave(projectId, activities, calendar.updatedAt(), dragOnly, context.state.sessionId, activitiesIds, opId)

      context.state.activitiesManager.prepareLastPartSplittedActivities();
      context.dispatch('debouncedCriticalPath', context)
      context.commit('setSaveStatus', SaveStatusEnum.SUCCESS);
    } catch(error) {
      const errMsg = typeof error == 'string' ? error : error.message;
      context.commit('setSaveStatus', SaveStatusEnum.ERROR);
      context.commit('setErrorStatusMessage', errMsg);
    }
  },
  async updateActivityLine(context, activity) {
    const selectedProjectId = context.rootState.selectedProjectId;
    try {
      context.commit('setSaveStatus', SaveStatusEnum.IN_SAVING_PROGRESS);
      await ActivitiesService.updateLine(selectedProjectId, activity.id, activity.line)
      context.commit('setSaveStatus', SaveStatusEnum.SUCCESS);
    } catch (error) {
      console.log(error);
      context.commit('setSaveStatus', SaveStatusEnum.ERROR);
      EventBus.$emit('showSnackbar', {message: error, color: 'error', position: { bottom: true }, timeout: 6000});
    }
  },
  setupBulkActivitiesChangesChannel(context) {
    let cableSubscriptions = context.rootState.cable.subscriptions;
    if (context.state.bulkActivitiesChangesChannel) {
      cableSubscriptions.remove(context.state.bulkActivitiesChangesChannel);
    }
    let channel = {
      id: `bulk_activities_changes_of_${context.rootState.selectedProjectId}`,
      channel: 'BulkActivitiesChangesChannel',
      project_id: context.rootState.selectedProjectId,
    };
    context.state.bulkActivitiesChangesChannel = cableSubscriptions.create(channel, {
      received: data => {
        const body = data.body;
        if (context.rootGetters.isSelectedProjectGantt) return;

        if (body.user_session_id === context.state.sessionId) {
          body.changes.forEach(change => {
            context.dispatch('updateExpectedPercentageCompleted', camelcaseKeysDeep(JSON.parse(change)));
          });
          return
        }

        context.commit('setSaveStatus', SaveStatusEnum.IN_SAVING_PROGRESS);
        const action = body.method == 'save' ? 'setItems' : 'removeItems';
        const activities = body.changes.map(change => new Activity(camelcaseKeysDeep(JSON.parse(change))));
        context.commit(`activities/${action}`, activities, { root: true });
        context.state.activitiesManager[action](activities);
        context.state.activitiesManager.prepareLastPartSplittedActivities();
        context.dispatch('debouncedCriticalPath', context)
        setTimeout(_ => context.commit('setSaveStatus', undefined), 500);
      },
    });
  },
  async disableCriticalPath(context) {
    context.commit('disableShowCriticalActivities');
    context.commit('setCriticalActivitiesIds', []);
  },
  async enableCriticalPath(context) {
    context.commit('enableShowCriticalActivities');
    await context.dispatch('updateCriticalPath');
  },
  async updateCriticalPath(context){
    if (!context.getters.showCriticalActivitiesEnabled) return;
    if (context.state.saveStatus === SaveStatusEnum.ERROR) return;
    const activitiesIdsToignore = context.getters.isCriticalPathUsingCurrentFilter ? context.state.activitiesManager.getHiddenActivitiesIds() : [];

    context.commit('setSaveStatus', SaveStatusEnum.LOADING);
    const criticalActivitiesIds = await ProjectsService.criticalPath(context.rootState.selectedProjectId, activitiesIdsToignore)
    context.commit('setCriticalActivitiesIds', criticalActivitiesIds)
    context.commit('setSaveStatus', SaveStatusEnum.SUCCESS);
  },
  updateExpectedPercentageCompleted(context, change) {
    const expectedPercentageCompleted = change.expectedPercentageCompleted;
    const activity = context.state.activitiesManager.getActivityOf(change.id);
    activity.update({ expectedPercentageCompleted });
  },
  debouncedCriticalPath: debounce((context)=> {
    context.dispatch('updateCriticalPath')
  }, 3000, {leading: true})
};
