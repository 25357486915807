export default class Parts {
  constructor(partsAttrs) {
    this.value = this._serializeParts(partsAttrs);
  }

  isEmpty() {
    return this.value.length === 0;
  }

  add(part) {
    this.value.push(part);
  }

  pop(number) {
    return this.value.splice(-number);
  }

  _serializeParts(partsAttrs) {
    return (partsAttrs || []).map(part => ({
      startAt: new Date(part.startAt),
      endAt: new Date(part.endAt),
      duration: part.duration,
    }));
  }
}
