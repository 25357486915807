export default {
  initialize: ({ dispatch }, calendar) => {
    dispatch('initializeDates', { minDate: calendar.minDate, maxDate: calendar.maxDate });
    dispatch('initializeSelectedWorkays', calendar.calendarData);
    dispatch('initializeCustomWorkdays', calendar.calendarData.customWorkdays);
    dispatch('initializeHolidays', calendar.calendarData.nationalHolidays);
    dispatch('initializeProjectId', calendar.calendarData.projectId);
    dispatch(
      'initializeNationalHolidaysSelection',
      calendar.calendarData.nationalHolidaysSelection,
    );
    dispatch('initializeCustomHolidays', calendar.calendarData.customHolidays);
    dispatch('initializeIsEditing', false);
  },
  initializeDates: ({ commit }, startEndDates) => {
    commit('setStart', startEndDates.minDate.fixDateInputLocale().addDays(1));
    commit('setEnd', startEndDates.maxDate);
  },
  initializeSelectedWorkays: ({ commit }, calendarData) => {
    let selectedWorkdays = [];
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    weekDays.forEach((day, index) => {
      day = day.toLocaleLowerCase();
      if (calendarData[`${day}Workday`]) {
        selectedWorkdays.push(index);
      }
    });
    commit('setSelectedWorkdays', selectedWorkdays);
  },
  initializeCustomWorkdays: ({ commit }, customWorkdays) => {
    commit('setCustomWorkdays', customWorkdays);
  },
  initializeHolidays: ({ commit }, holidays) => {
    commit('setHolidays', holidays);
  },
  initializeProjectId: ({ commit }, projectId) => {
    commit('setProjectId', projectId);
  },
  initializeNationalHolidaysSelection: ({ commit }, nationalHolidaysSelection) => {
    commit('setNationalHolidaysSelection', nationalHolidaysSelection);
  },
  initializeCustomHolidays: ({ commit }, customHolidays) => {
    commit('setCustomHolidays', customHolidays);
  },
  initializeIsEditing: ({ commit }, isEditing) => {
    commit('setIsEditing', isEditing);
  },
};
