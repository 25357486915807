export default {
  selectStep: ({ commit, rootState }, {stepId, stepTasksIds}) => {
    stepTasksIds.forEach(taskId => {
      if(rootState['kanban'].selectedTasks.includes(taskId)) commit('unsetTask', taskId);
    });

    commit('setStep', stepId);
  },

  unselectStep({ commit }, stepId){
    commit('unsetStep', stepId);
  },

  selectTask({ commit }, taskId){
    commit('setTask', taskId);
  },

  unselectTask: ({ commit }, {taskId, stepSelected, currentStepId, stepTasksIds}) => {
    if(stepSelected){
      commit('unsetStep', currentStepId)

      stepTasksIds.forEach(otherTaskId => {
        if(taskId != otherTaskId) commit('setTask', otherTaskId);
      });

      return
    }

    commit('unsetTask', taskId)
  },

  unselectAllTasks({ commit }){
    commit('unsetAllSteps');
    commit('unsetAllTasks');
  },
};
