import Vue from 'vue';

export default {
  index: projectId => Vue.axios.get(`projects/${projectId}/lob_versions`),
  create: (projectId, lobVersion) =>
    Vue.axios.post(`projects/${projectId}/lob_versions`, lobVersion),
  update: (projectId, lobVersion) =>
    Vue.axios.put(`projects/${projectId}/lob_versions/${lobVersion.id}`, lobVersion),
  restore: (projectId, id) => Vue.axios.put(`projects/${projectId}/lob_versions/${id}/restore`),
  destroy: (projectId, id) => Vue.axios.delete(`projects/${projectId}/lob_versions/${id}`),
  compareWithCurrentSchedule: (projectId, id) => Vue.axios.post(`projects/${projectId}/lob_versions/${id}/compare_with_current_schedule`),
};
