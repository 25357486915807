import PricingFeaturesService from '@/services/PricingFeaturesService';
import {isEqual} from 'lodash';

export default {
  loadFeatures(context) {
    return new Promise(async (resolve, reject) => {
      try {
        const {plan, extraFeatures} = await PricingFeaturesService.getCompanyFeatures();

        if (!isEqual(context.state.plan, plan)) {
          context.commit("setPlan", plan);
        }
        if (!isEqual(context.state.extraFeatures, extraFeatures)) {
          context.commit("setExtraFeatures", extraFeatures);
        }

        resolve(context.getters.allFeaturesKeys);
      } catch (error) {
        reject(error);
      }
    })
  },
  setupSubscriptionPlanChangesChannel(context) {
    let cableSubscriptions = context.rootState.cable.subscriptions;
    if (context.state.subscriptionPlanChangesChannel) {
      cableSubscriptions.remove(context.state.subscriptionPlanChangesChannel);
    }

    let channel = {
      id: `changes_of_company_plan_features_${context.rootState.companyId}`,
      channel: 'SubscriptionPlanFeaturesChangesChannel',
      company_id: context.rootState.companyId,
    }

    context.state.subscriptionPlanChangesChannel = cableSubscriptions.create(channel, {
      received: _ => {
        context.dispatch("loadFeatures")
      }
    })
  }
}
