export default class DateHelper {
  constructor() { }

  getNextSaturdayMidnight() {
    const saturdayInTheWeek = 6;

    const now = new Date();
    const currentDay = now.getDay();

    const daysUntilSaturday = (saturdayInTheWeek - currentDay);

    const nextSaturday = new Date(now);

    if (daysUntilSaturday == 0) nextSaturday.setDate(now.getDate() + 7);
    else nextSaturday.setDate(now.getDate() + daysUntilSaturday);

    nextSaturday.setHours(0, 0, 0, 0);

    return nextSaturday;
  }
}
