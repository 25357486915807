mutation StartQualitySyncTaskMutation(
  $projectId: Int!
  $partner: QualityPartner!
  $itemsToLoad: [QualityItemsToLoadInput!]!
) {
  startQualitySyncTask(
    projectId: $projectId
    partner: $partner
    itemsToLoad: $itemsToLoad
  ) {
    id
    eapType
    itemId
    checklistId
    partnerLocalId
    partnerModelId
    qualityVerificationForms {
      id
      partnerReferenceId
      name
      link
      formStatus
      totalConformities
      totalNonconformities
      qualityNonconformities {
        id
        status
      }
    }
  }
}
