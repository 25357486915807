export default {
  setProjectId(state, projectId) {
    state.projectId = projectId
  },
  applyFilterForm(state, filterForm) {
    state.filters = {
      projectId: filterForm.projectId,
      suppliers: filterForm.suppliers,
      servicesIds: filterForm.servicesIds
    }
  }
};
