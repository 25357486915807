import Vue from 'vue';

export default {
  index: projectId => Vue.axios.get(`projects/${projectId}/budget_report/budget_items`),
  create: projectId => {
    Vue.axios.get(`projects/${projectId}/budget_report/budget_items`);
  },
  createFromJson: (projectId, budgetItems) => {
    return Vue.axios.post(`projects/${projectId}/budget_report/budget_items/create_api`, {
      budgetItems: budgetItems,
    });
  },
};
