import PromiseQueue from 'easy-promise-queue';
import ActivitiesDom from '@/features/schedules/models/ActivitiesDom';

export default {
  activitiesManager: undefined,
  activitiesDom: new ActivitiesDom(),
  printConfig: undefined,
  linesPerFloor: undefined,
  hiddenFloorsId: [],
  hiddenServicesId: [],
  hiddenActivitiesIdsByResponsiblesFilter: [],
  hiddenContractors: undefined,
  showCriticalActivities: false,
  isCriticalPathUsingCurrentFilter: false,
  criticalActivitiesIds: [],
  start: undefined,
  end: undefined,
  saveRequests: new PromiseQueue({ concurrency: 1 }),
  saveStatus: undefined,
  errorStatusMessage: undefined,
  dayWidth: undefined,
  isPrinting: false,
  dependencyCycle: undefined,
  isGapsAllowed: undefined,
  isLocked: false,
  sessionId: undefined,
};
