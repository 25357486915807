import PricingFeatures from "@/models/PricingFeatures";

const routes = [
  {
    path: 'contracts',
    name: 'contracts',
    component: () => import('@/views/contracts/Contracts.vue'),
    meta: {
      isProjectScoped: true,
      withoutPadding: true,
      featureKey: PricingFeatures.contracts.key
    },
  },
  {
    path: 'contracts/:id',
    name: 'contract-items',
    component: () => import('@/components/contract/ContractItems.vue'),
    meta: {
      isProjectScoped: true,
      withoutPadding: true,
      featureKey: PricingFeatures.contracts.key
    },
  }
];

export default routes;
