import Vue from 'vue';
import { compact } from 'lodash';
import { apolloClient } from "@/vue-apollo";
import UpdateLineMutation from "@/graphql/mutations/activities/updateLine.gql";
import SplitActivityMutation from '@/graphql/mutations/activities/splitActivity.gql';
import UndoMutation from '@/graphql/mutations/planning/Undo.gql';
import RemoveGapsMutation from "@/graphql/mutations/activities/RemoveGaps.gql";

export default {
  index: projectId => Vue.axios.get(`projects/${projectId}/activities`),
  export: (projectId, filters, columns, referenceDate) => {
    const params = {
      floorsId: filters.floorsId,
      servicesId: filters.servicesId,
      minDate: filters.dates.start,
      maxDate: filters.dates.end,
      columns: columns,
      referenceDate: referenceDate,
    };
    return Vue.axios.post(`projects/${projectId}/activities/xlsx`, params);
  },
  exportWithinPeriod: (projectId, groupsYaml, minDate, maxDate) => {
    const params = { groupsYaml: groupsYaml, minDate: minDate, maxDate: maxDate };
    return Vue.axios.post(`projects/${projectId}/activities/period_xlsx`, params);
  },
  partsWeight: (projectId, serviceId, floorId) => {
    const params = { serviceId: serviceId, floorId: floorId };
    return Vue.axios.post(`projects/${projectId}/activities/parts_weight`, params);
  },
  exportMeasurements: (projectId, activityId) => {
    return Vue.axios.post(`projects/${projectId}/activities/export_activity_measurements`, { activityId });
  },
  exportActivityChanges: (projectId, activityId) => {
    return Vue.axios.post(`projects/${projectId}/activities/export_activity_changes_history`, {activityId});
  },
  table: (projectId, additionalInfo, referenceDate) =>
    Vue.axios.post(`projects/${projectId}/activities/table`, { additionalInfo, referenceDate }),
  create: (projectId, activity) => Vue.axios.post(`projects/${projectId}/activities`, activity),
  automaticSave: (projectId, activities, calendarUpdatedAt, dragOnly, userSessionId, selectedActivitiesIds, opId) => {
    const selectedIds = new Set(selectedActivitiesIds)
    let activitiesParams = compact(activities.map(a => (a ? a.toApiHash(selectedIds.has(a.id), dragOnly) : undefined)));
    const endpoint = `projects/${projectId}/activities/automatic_save`;
    return Vue.axios.put(endpoint, { activities: activitiesParams, calendarUpdatedAt, userSessionId, selectedActivitiesIds, opId });
  },
  updateLine: async (projectId, activityId, line) => {
    return await apolloClient.mutate({
      mutation: UpdateLineMutation,
      variables: { projectId, activityId, line }
    })
  },
  destroy: (projectId, id) => Vue.axios.delete(`projects/${projectId}/activities/${id}`),
  bulkDestroy: (projectId, ids) =>
    Vue.axios.post(`projects/${projectId}/activities/bulk_destroy`, { ids: ids }),
  split: async (projectId, activityId) => {
    return await apolloClient.mutate({
      mutation: SplitActivityMutation,
      variables: { projectId, activityId },
      fetchPolicy: 'no-cache',
    })
  },
  project: projectId => Vue.axios.get(`projects/${projectId}/activities/eap_xml`),
  expected: (projectId, referenceDate, activityId) => {
    return Vue.axios.get(
      `projects/${projectId}/activities/${activityId}/expected?reference_date=${referenceDate}`,
    );
  },
  sendUserActionsOnModal: (projectId, userActionsStack) => {
    Vue.axios.put(`projects/${projectId}/activities/register_user_actions`, userActionsStack);
  },
  undo: async (projectId) => {
    return await apolloClient.mutate({
      mutation: UndoMutation,
      variables: { projectId },
    });
  },
  removeGaps: async (projectId) => {
    return await apolloClient.mutate({
      mutation: RemoveGapsMutation,
      variables: { projectId }
    })
  },
};
