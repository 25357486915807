import Vue from 'vue';

export default {
  index: projectId => Vue.axios.get(`projects/${projectId}/replication_groups`),
  create: (projectId, replicationGroup) =>
    Vue.axios.post(`projects/${projectId}/replication_groups`, replicationGroup),
  update: (projectId, replicationGroup) =>
    Vue.axios.put(
      `projects/${projectId}/replication_groups/${replicationGroup.id}`,
      replicationGroup,
    ),
  destroy: (projectId, replicationGroup) =>
    Vue.axios.delete(`projects/${projectId}/replication_groups/${replicationGroup.id}`),
};
