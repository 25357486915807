export default {
  setActivitiesManager: (state, manager) => {
    state.activitiesManager = manager;
  },
  setPrintConfig: (state, printConfig) => {
    state.printConfig = printConfig;
    state.isPrinting = true;
  },
  setLinesPerFloor: (state, linesPerFloor) => {
    state.linesPerFloor = linesPerFloor;
  },
  setHiddenFloorsId: (state, hiddenFloorsId) => {
    state.hiddenFloorsId = hiddenFloorsId;
  },
  setHiddenServicesId: (state, hiddenServicesId) => {
    state.hiddenServicesId = hiddenServicesId;
  },
  sethiddenActivitiesIdsByResponsiblesFilter: (state, hiddenActivitiesIdsByResponsiblesFilter) => {
    state.hiddenActivitiesIdsByResponsiblesFilter = hiddenActivitiesIdsByResponsiblesFilter;
  },
  setHiddenContractors: (state, hiddenContractors) => {
    state.hiddenContractors = hiddenContractors
  },
  setCriticalActivitiesIds: (state, criticalActivitiesIds) => {
    state.criticalActivitiesIds = state.showCriticalActivities ? criticalActivitiesIds : [];
  },
  disableShowCriticalActivities: (state) => {
    state.showCriticalActivities = false;
    state.isCriticalPathUsingCurrentFilter = false;
    state.criticalActivitiesIds = [];
  },
  enableShowCriticalActivities: (state) => {
    state.showCriticalActivities = true;
  },
  setCriticalPathUsingCurrentFilter: (state, isCriticalPathUsingCurrentFilter) => {
    state.isCriticalPathUsingCurrentFilter = isCriticalPathUsingCurrentFilter;
  },
  clearPrintingConfiguration: state => {
    state.printConfig = undefined;
    state.isPrinting = false;
  },
  setStart: (state, start) => (state.start = start),
  setEnd: (state, end) => (state.end = end),
  setSaveStatus: (state, status) => (state.saveStatus = status),
  addSaveRequest: (state, promise) => state.saveRequests.add(_ => promise),
  setDependencyCycle: (state, dependencyCycle) => (state.dependencyCycle = dependencyCycle),
  setAllowDependentsGap: (state, allowDependentsGap) => (state.isGapsAllowed = allowDependentsGap),
  setScheduleLocked: (state, isLocked) => (state.isLocked = isLocked),
  setSessionId: (state, sessionId) => (state.sessionId = sessionId),
  toggleAllowGaps: (state) => (state.isGapsAllowed = !state.isGapsAllowed),
  setErrorStatusMessage: (state, message) => (state.errorStatusMessage = message),
};
