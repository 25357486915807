import Vue from 'vue';
import { apolloClient } from "@/vue-apollo";
import DeleteFloorMutation from '@/views/schedule/floors/graphql/deleteFloor.gql';
import CreateSequentialFloorsMutation from '@/graphql/mutations/floors/CreateSequentialFloors.gql';

export default {
  index: projectId => Vue.axios.get(`projects/${projectId}/floors`),
  create: (projectId, floor) => Vue.axios.post(`projects/${projectId}/floors`, floor),
  bulkCreate: (projectId, floors) =>
    Vue.axios.post(`projects/${projectId}/floors/bulk_create`, { floors }),
  update: (projectId, floor) => Vue.axios.put(`projects/${projectId}/floors/${floor.id}`, floor),
  updatePositions: (projectId, floors) =>
    Vue.axios.put(`projects/${projectId}/floors/update_positions`, { floors }),
  delete: async (projectId, floorId) => {
    await apolloClient.mutate({
      mutation: DeleteFloorMutation,
      variables: { projectId, floorId }
    });
  },
  createSequential: async (projectId, quantity, floorParameters) => {
    await apolloClient.mutate({
      mutation: CreateSequentialFloorsMutation,
      variables: { projectId, quantity, floorParameters }
    });
  }
};
