query FeaturesQuery {
  me {
    subscriptionPlan {
      plan {
        id
        name
        features {
          name
          key
        }
      }
      extraFeatures {
        name
        key
        expireAt
      }
    }
  }
}
