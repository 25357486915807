import isMobile from '@/models/MobileChecker';
import PricingFeatures from '@/models/PricingFeatures';

const routes = [
  {
    path: 'quality',
    name: 'quality',
    component: () => import('@/views/quality/Quality'),
    redirect: { name: 'quality-home' },
    beforeEnter(to, from, next) {
      if (isMobile()) {
        next({ name: 'home' });
      } else {
        next();
      }
    },
    children: [
      {
        path: 'home',
        name: 'quality-home',
        component: () => import('@/components/integration/quality/QualityHome'),
        meta: {
          isProjectScoped: true,
          withoutPadding: true,
          featureKey: PricingFeatures.qualityIntegration.key,
        },
      },
      {
        path: 'linking',
        name: 'quality-linking',
        component: () => import('@/components/integration/quality/QualityLinking'),
        meta: {
          isProjectScoped: true,
          withoutPadding: true,
          featureKey: PricingFeatures.qualityIntegration.key,
        },
      },
    ],
  },
];

export default routes;
