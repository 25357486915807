export default {
  filterForm: state => {
    return {
      projectId: state.projectId,
      datesRange: {
        startAt: state.startAt,
        endAt: state.endAt,
      },
      floorIds: state.floorIds,
      workPackageIds: state.workPackageIds,
      responsibleNames: state.responsibleNames,
      withoutResponsible: state.withoutResponsible,
      startOfWeek: state.startOfWeek,
      floorFormField: state.floorFormField
    }
  },
  printMode: state => state.printMode,
};
