String.prototype.compare = function(str, options = {}) {
  const optionsHandler = {
    caseInsensitive: str => {
      return str.toLowerCase();
    },
    removeEmptySpaces: str => {
      return str.replace(/\s+/g, ' ').trim();
    },
  };

  let str1 = this;
  let str2 = str;

  Object.keys(options).forEach(key => {
    if (!options[key]) return;

    const optionFunction = optionsHandler[key];
    str1 = optionFunction(str1);
    str2 = optionFunction(str2);
  });

  return str1 === str2;
};
