import { mapGetters } from 'vuex';
import { upperFirst } from 'lodash';

import CompaniesService from '@/services/CompaniesService'; //
import UsersService from '@/services/UsersService';
import ProjectsService from '@/services/ProjectsService';
import CalendarService from '@/services/CalendarService';
import FloorsService from '@/services/FloorsService';
import ServicesService from '@/services/ServicesService';
import SectorsService from '@/services/SectorsService'; //
import ActivitiesService from '@/services/ActivitiesService';
import ReplicationGroupsService from '@/services/ReplicationGroupsService'; //
import MeasurementsService from '@/services/MeasurementsService';
import BudgetItemsService from '@/services/BudgetItemsService'; //
import BudgetReportService from '@/services/BudgetReportService';
import LobVersionsService from '@/services/LobVersionsService';
import TasksService from '@/services/TasksService';
import SettingsService from '@/services/SettingsService';
import Calendar from '@/models/Calendar';
import Activity from '@/features/schedules/models/Activity';

let mapWebsockets = resources => {
  let getters = {};
  resources.forEach(resource => {
    if (resource.type == Array) {
      getters[resource.name] = `${resource.name}/items`;
      getters[`get${upperFirst(resource.singular)}`] = `${resource.name}/item`;
      getters[`isLoading${upperFirst(resource.name)}`] = `${resource.name}/isLoading`;
    } else {
      getters[resource.singular] = `${resource.name}/item`;
      getters[`isLoading${upperFirst(resource.singular)}`] = `${resource.name}/isLoading`;
    }
  });
  return mapGetters(getters);
};

let websockets = {
  COMPANIES: {
    context: 'company',
    service: CompaniesService,
    type: Object,
    name: 'companies',
    singular: 'company',
  },
  GENERAL_SETTINGS: {
    context: 'company',
    service: SettingsService,
    type: Object,
    name: 'generalSettings',
    singular: 'generalSetting',
  },
  USERS: {
    context: 'company',
    service: UsersService,
    type: Array,
    name: 'users',
    singular: 'user',
  },
  PROJECTS: {
    context: 'company',
    service: ProjectsService,
    type: Array,
    name: 'projects',
    singular: 'project',
    itemParseFunction: item => {
      return Object.assign(item, {
        isRecoveringVersion: item.updateProcessStatus == 'started',
        createdAt: new Date(item.createdAt),
      });
    },
  },
  PROJECT_SECTIONS: {
    context: 'company',
    service: undefined,
    type: Array,
    name: 'projectSections',
    singular: 'projectSection',
  },
  FLOORS: {
    context: 'project',
    service: FloorsService,
    type: Array,
    name: 'floors',
    singular: 'floor',
  },
  SERVICES: {
    context: 'project',
    service: ServicesService,
    type: Array,
    name: 'services',
    singular: 'service',
  },
  SECTORS: {
    context: 'project',
    service: SectorsService,
    type: Array,
    name: 'groups',
    singular: 'group',
  },
  CALENDARS: {
    context: 'project',
    service: CalendarService,
    type: Object,
    name: 'calendars',
    singular: 'calendar',
    itemParseFunction: item => new Calendar(item),
  },
  ACTIVITIES: {
    context: 'project',
    service: ActivitiesService,
    type: Array,
    name: 'activities',
    singular: 'activity',
    itemParseFunction: item => (item && item instanceof Activity ? item : new Activity(item)),
  },
  REPLICATION_GROUPS: {
    context: 'project',
    service: ReplicationGroupsService,
    type: Array,
    name: 'replicationGroups',
    singular: 'replicationGroup',
  },
  DEADLINE_MEASUREMENT_TASKS: {
    context: 'project',
    service: MeasurementsService,
    type: Array,
    name: 'deadlineMeasurementTasks',
    singular: 'deadlineMeasurementTask',
    itemParseFunction: item => {
      return Object.assign(item, { measuredIn: Date.withoutTime(item.measuredIn) });
    },
  },
  BUDGET_REPORTS: {
    context: 'project',
    service: BudgetReportService,
    type: Object,
    name: 'budgetReports',
    singular: 'budgetReport',
  },
  BUDGET_ITEMS: {
    context: 'project',
    service: BudgetItemsService,
    type: Array,
    name: 'budgetItems',
    singular: 'budgetItem',
  },
  LOB_VERSIONS: {
    context: 'project',
    service: LobVersionsService,
    type: Array,
    name: 'lobVersions',
    singular: 'lobVersion',
    itemParseFunction: item => Object.assign(item, { createdAt: new Date(item.createdAt) }),
  },
  TASKS: {
    context: 'project',
    service: TasksService,
    type: Array,
    name: 'tasks',
    singular: 'task',
    itemParseFunction: item => {
      item.doneAt = item.doneAt ? Date.withoutTime(item.doneAt) : undefined;
      item.dueAt = Date.withoutTime(item.dueAt);
      item.isDone = !!item.doneAt;
      return item;
    },
  },
};

export { mapWebsockets };
export { websockets };

export default {
  mapWebsockets,
  websockets,
};
