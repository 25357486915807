import { map, maxBy } from 'lodash';
import SaveStatusEnum from '@/models/SaveStatusEnum';

export default {
  start: state => state.start,
  end: state => state.end,
  minDate: (state, getters) => (getters.start || Date.withoutTime()).getMonday(),
  maxDate: (state, getters) => (getters.end || Date.withoutTime()).getSunday(),
  nbWeeks: (state, getters) => Math.round(getters.maxDate.weekDiff(getters.minDate)),
  isSaving: state => state.saveStatus == SaveStatusEnum.IN_SAVING_PROGRESS,
  isUndoing: state => state.saveStatus == SaveStatusEnum.IN_UNDOING_PROGRESS,
  isOnError: state => state.saveStatus == SaveStatusEnum.ERROR,
  isLoadingRequest: state => state.saveStatus == SaveStatusEnum.LOADING,
  isDateFilterActive: (state, getters, rootState, rootGetters) => {
    let calendar = rootGetters['calendars/item'];
    if (!state.start || !state.end) return false;
    return calendar && (state.start != calendar.minDate || state.end != calendar.maxDate);
  },
  hiddenFloorsId: state => state.hiddenFloorsId,
  hiddenServicesId: state => state.hiddenServicesId,
  hiddenActivitiesIdsByResponsiblesFilter: state => state.hiddenActivitiesIdsByResponsiblesFilter,
  isFloorsFilterActive: state => state.hiddenFloorsId.length != 0,
  isServicesFilterActive: state => state.hiddenServicesId.length != 0,
  showCriticalActivitiesEnabled: state => state.showCriticalActivities,
  isCriticalPathUsingCurrentFilter: state => state.isCriticalPathUsingCurrentFilter,
  isResponsiblesFilterActive: state => state.hiddenActivitiesIdsByResponsiblesFilter.length != 0,
  isFilterActive: (_, getters) =>
    getters.isDateFilterActive ||
    getters.isFloorsFilterActive ||
    getters.isServicesFilterActive ||
    getters.isResponsiblesFilterActive,
  isGapsAllowed: state => state.isGapsAllowed,
  hasDependencyCycle: state => !!state.dependencyCycle,
  cycleActivities: (state, getters, rootState, rootGetters) => {
    if (!getters.hasDependencyCycle) return undefined;
    let vertexActivity = rootGetters['activities/item'](state.dependencyCycle.vertex);
    let edgeActivity = rootGetters['activities/item'](state.dependencyCycle.edge);
    let vertexIsDirectReferenceOfEdge = map(
      vertexActivity.dependencies,
      'referenceActivityId',
    ).includes(edgeActivity.id);

    if (vertexIsDirectReferenceOfEdge) {
      return { predecessor: vertexActivity, successor: edgeActivity };
    } else {
      return { predecessor: edgeActivity, successor: vertexActivity };
    }
  },
  isScheduleUpdatesBlocked: (state, getters, rootState, rootGetters) => {
    return rootGetters['schedule/isLocked'] || rootGetters['isUserViewOnly'];
  },
  isLocked: state => state.isLocked,
  endOfLastPlannedActivity: (state, getters, rootState, rootGetters) => {
    const activities = rootGetters['activities/items'];
    const lastActivity = maxBy(activities, 'endAt');
    return lastActivity.endAt;
  }
};
