export default {
  projects: [],
  dashboardFilters: {view_type: "percentage", dashboard_type: "economic", primary: true, filtered_floor_ids: [], filtered_service_ids: [], filtered_total_cost: true},
  dashboardPage: 'detailed',
  economicalDashboardCategory: "cost",
  isFilterActive: false,
  selectedCustomFilter: undefined,
  filteredFloorsSize: undefined,
  filteredServicesSize: undefined,
}
