import ReportsService from '@/services/ReportsService';

export default {
  async loadBasedReport({ commit }, report = {}) {
    commit('setLoadingBasedReport', true);
    let base = report.id ? await ReportsService.show(report.id) : report;
    commit('setBasedReport', {
      pagesHtml: [],
      pagesImage: [],
      ...base,
    });
    commit('setLoadingBasedReport', false);
  },
};
