import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { link } from './graphql/config/buildSchema';
import { InMemoryCache, ApolloClient } from '@apollo/client/core';

Vue.use(VueApollo);

const AUTH_TOKEN = 'apollo-token';
const httpEndpoint = import.meta.env.VITE_GRAPHQL_HTTP || 'http://localhost:3000/graphql';
export const filesRoot =
  import.meta.env.VITE_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

const defaultOptions = {
  httpEndpoint,
  persisting: false,
  ssr: false,
  link,
  connectToDevTools: true,
  // overriding default auth
  getAuth: tokenName => {
    return;
  },
  cache: new InMemoryCache(),
};

export const apolloClient = new ApolloClient({
  ...defaultOptions,
});

export function createProvider() {
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message,
      );
    },
  });
}

export async function onLogin(apolloClient, token) {
  console.log('onLogin');
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  console.log('onLogout');
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
