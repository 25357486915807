export default {
  getProject: state => state.project,
  filterForm: state => {
    return {
      projectId: state.project.id,
      datesRange: {
        startAt: state.startAt,
        endAt: state.endAt,
      }
    }
  },
}
